<script setup lang="ts">
/**
 * 搜索页
 */
import { onMounted, watch, ref, Ref } from "vue";
import Data from "@/data/software/index.ts";
import { PageData, PageDataTitleArray } from "@/data/page/summary";
import { useVueFuse } from "vue-fuse";
import ListPc from "@/components/block/data/table.vue";
import ListMobile from "@/components/block/data/block.vue";
import { CategoryType } from "@/store/interface";
import { useRoute, useRouter } from "vue-router";
import { SeeTitleArray } from "@/data/page/see/summary.ts";

//组合软件和站点数据，构成数组，忽略站点菜单名
const SearchData = [...Data, ...PageData];
//清空按钮初始状态
const clearState = ref(true);

//取出软件名组合成数组
const extractType = (data: any[], arr: string[]) => {
  for (let obj of data) {
    if (Array.isArray(obj.data)) {
      extractType(obj.data, arr);
    } else if (obj.hasOwnProperty("name")) {
      arr.push(obj.name, obj.msg);
    }
  }
};

//存储软件名和描述
const myList = [""];

extractType(SearchData, myList); //网站名数组
const { search, results, noResults } = useVueFuse(myList); //模糊搜索核心方法

// 输入框中的文本
const searchText = ref(search);

//存储筛选后的结果
const filterData = ref();

//定位输入框
// 假设 inputRef 是一个 Ref 对象
const inputRef: Ref<HTMLElement | null> = ref(null);

//清空搜索框
const showRec = () => {
  searchText.value = ""; //重置输入框
  filterData.value = []; //清空数据
  // 检查 inputRef.value 是否为 null

  inputRef.value?.focus(); // 如果不为 null，则调用 focus() 方法
};

//搜索一级和二级分类名称
//准备存储信息
const searchData = ref(""); //搜索词
const categoryData = ref(""); //一级分类
const sonData = ref(""); //二级分类

//撰写方法，获取选择后的内容，反向查出路径
// 寻找对应的 CategoryType 的 title 和 SonType 的 type

let categoryTitle: string | undefined;
let sonTypeType: string | undefined;

//查找一级分类和二级分类
const outerLoop = (categoryTypes: CategoryType[], targetName: string) => {
  // 重置变量
  categoryTitle = undefined;
  sonTypeType = undefined;
  // 查找匹配的 DataType
  for (const category of categoryTypes) {
    for (const sonType of category.data) {
      const foundDataType = sonType.data.find(
        (dataType) => dataType.name === targetName
      );
      if (foundDataType) {
        categoryTitle = category.title;
        sonTypeType = sonType.type;
        break; // 找到匹配后跳出循环
      }
    }
    if (categoryTitle && sonTypeType) {
      break; // 找到匹配后跳出循环
    }
  }

  if (categoryTitle && sonTypeType) {
    searchData.value = targetName; //搜索词

    const result = PageDataTitleArray.includes(categoryTitle); //一级分类是否是站点下的数组名称
    if (result) {
      //是否是媒体站点下的数组名称之一
      const seeType = SeeTitleArray.includes(categoryTitle);
      if (seeType) {
        categoryData.value = "媒体 → " + categoryTitle; //一级分类
      } else {
        categoryData.value = "站点 → " + categoryTitle; //一级分类
      }
    } else {
      categoryData.value = categoryTitle; //一级分类
    }

    sonData.value = sonTypeType; //二级分类

    //console.log(categoryData.value, sonData.value);
    //console.log(`找到 DataType 名为 "${targetName}" 的数据。`);
    //console.log(`对应的 CategoryType 的 title 是 "${categoryTitle}"。`);
    //console.log(`对应的 SonType 的 type 是 "${sonTypeType}"。`);
  } else {
    // console.log(`未找到 DataType 名为 "${targetName}" 的数据。`);
  }
};

//筛选
const handleItemClick = (name: string) => {
  //确定搜索词
  searchText.value = name;

  //筛选数据
  filterData.value = SearchData.flatMap((obj) => obj.data)
    .flatMap((nestedObj) => nestedObj.data)
    .filter((item) => item.name === name || item.msg === name);

  const arrayProxy = filterData.value; // 获取 Proxy 对象
  const arrayValue = arrayProxy.__v_raw; // 获取 Proxy 对象的原始值

  //console.log(arrayValue[0].name); // 输出数组的值

  //搜索出的内容为空，则不执行
  if (arrayValue.length === 0) {
    console.log("搜索结果为空");
    console.log(noResults.value);
    return;
  }

  //找到一级、二级分类名称
  outerLoop(SearchData, arrayValue[0].name);

  //修改路由
  modifyUrl(name);

  //展示清理按钮
  clearState.value = true;
};
/**
 * @description: 处理焦点
 *
 */
//打开页面，焦点到输入框
onMounted(() => {
  inputRef.value?.focus();
});

//监听，当输入框内容为空，焦点到输入框
watch(searchText, (newValue) => {
  if (!newValue) {
    showRec();
    clearState.value = false; //隐藏清理按钮
  }
});

//改写URL

//准备路由
const router = useRouter();

//当选中搜索词时，
const modifyUrl = (searchData: string) => {
  //修改URL链接
  router.push({
    name: "searchdata", //子路由名称
    params: {
      searchdata: searchData, //设置路由参数为搜索词
    },
  });
};

//检查网址，search后面有内容，则执行
const checkSearchTerm = () => {
  const route = useRoute();
  const searchData = route.params.searchdata; // 获取搜索词
  // 如果存在搜索词，则执行
  if (searchData) {
    search.value = searchData; //填充输入框内容
    handleItemClick(search.value); //搜索出结果
  }
};

checkSearchTerm();
</script>
<template>
  <div class="container md:px-4 mx-auto">
    <div class="py-8">
      <div class="flex flex-row justify-start w-full mb-1 md:mb-0">
        <div class="text-end contents">
          <div
            class="flex flex-col justify-start max-w-sm space-y-3 md:flex-row w-full md:w-3/4 md:space-x-3 md:space-y-0 px-4 md:px-0"
          >
            <div class="relative">
              <input
                type="text"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent dark:bg-gray-200"
                placeholder="搜索"
                v-model="searchText"
                ref="inputRef"
              />
            </div>
            <button
              class="flex-shrink-0 px-4 py-2 text-base font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-BgButton dark:bg-darkBgButton text-ColorButton dark:text-darkColorButton hover:opacity-90"
              @click="showRec"
              v-if="clearState"
            >
              清空
            </button>
          </div>
        </div>
      </div>

      <!--展示结果列表-->
      <div v-if="filterData && filterData.length > 0" class="mt-4">
        <div class="hidden md:block">
          <ListPc :data="filterData" />
          <div class="md:px-4 mt-4">
            <span class="font-bold">路径：</span>
            {{ categoryData }} → {{ sonData }} → {{ searchData }}
          </div>
        </div>
        <div class="block md:hidden px-4">
          <ListMobile :data="filterData" />
          <div class="mt-8">
            <span class="font-bold">路径：</span>
            {{ categoryData }} → {{ sonData }} → {{ searchData }}
          </div>
        </div>
      </div>

      <!--搜索推荐内容-->
      <div
        class="flex flex-row w-full mb-1 md:mb-0 px-4"
        v-show="results.length > 1"
      >
        <div class="mt-4" v-if="!noResults">
          <span class="block font-bold"> 相关推荐：</span>
          <ul class="w-full">
            <li
              class="cursor-pointer pt-1 hover:font-bold"
              v-for="(r, i) in results.slice(0, 6)"
              :key="i"
              @click="handleItemClick(r)"
            >
              {{ r }}
            </li>
          </ul>
        </div>

        <p v-else="noResults" class="pt-4">
          抱歉，没有找到关于 <b>{{ search }}</b> 的更多结果
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
