<script setup lang="ts">
//左侧导航
import { inject, Ref } from "vue";
import {
  clickRouter,
  handleButtonClick,
  IconData,
  ColorSetting,
  showMenuType,
  isPath,
} from "@/store/tool";
import menu from "@/router/data";
import ScreenSystem from "@/components/block/function/screenSystem.vue";
import LeftPc from "@/components/block/function/left_pc.vue";
import SvgIcon from "@/components/icon/SvgIcon.vue";
//打印 - 调试用
//拿到当前路由名
const RouterName = inject("RouterName") as Ref<string>;
//拿到最新路径名
const RouterPath = inject("RouterPath") as Ref<any>;
const pt = () => {
  console.log("name:" + RouterName.value);
  console.log("path:" + JSON.stringify(RouterPath.value));
};

//文字效果
const getNavItemStyle = (_path: string) => {
  return {
    //color: isPath(path) ? ColorSetting.value.pitch : "",
    //fontWeight: isPath(path) ? "bold" : "inherit",
  };
};
// 使用工具函数
const showMenuComputed = showMenuType(RouterName);
</script>
<template>
  <div class="w-[22%] h-auto hidden md:block sticky top-4">
    <div
      class="text-TwoTitle dark:text-darkTwoTitle h-full shadow-lg overflow-hidden rounded-lg"
    >
      <!--标题-->
      <div
        class="bg-BgCardHeader dark:bg-darkBgCardHeader border-b dark:border-darkBgCardSeparate flex justify-between items-center h-14 lg:px-6 px-4"
      >
        <h1 class="text-lg font-semibold">导航</h1>
        <button @click="pt()" v-if="false">打印</button>
        <!--系统筛选-->
        <component v-if="showMenuComputed" :is="ScreenSystem" />
      </div>
      <!--引导-->
      <div class="overflow-y-auto tree-content bg-BgCard dark:bg-darkBgCard">
        <div class="cursor-pointer">
          <ul>
            <li
              class="h-12 lg:px-6 px-4 flex items-center justify-between text-base border-b dark:border-darkBgCardSeparate last:border-none opacity-50 hover:opacity-100 hover:font-bold"
              v-for="(item, index) in menu.router"
              :key="index"
              :style="getNavItemStyle(item.path)"
              @click="clickRouter(item.name, item.path), handleButtonClick()"
            >
              {{ item.title }}

              <!--菜单图标-->
              <div
                class="flex items-center justify-center w-4"
                v-show="isPath(item.path)"
              >
                <SvgIcon
                  class="w-6 h-6 rounded-3xl"
                  :name="IconData.right"
                  :color="ColorSetting.TwoTitle"
                  title="菜单图标"
                  alt="菜单图标"
                />
              </div>
              <!--标记-->
              <div
                v-show="false"
                class="red-tag flex items-center justify-center"
              >
                <span class="tag-text">推荐</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--功能区-->
    <div
      class="rounded-lg mt-4 lg:px-6 px-4 bg-BgCard dark:bg-darkBgCard shadow-lg"
    >
      <LeftPc />
    </div>
  </div>
</template>
<style scoped></style>
