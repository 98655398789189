//图片

import Edit from "@/data/software/picture/edit";
import Screenshot from "@/data/software/picture/screenshot";
import Compress from "@/data/software/picture/compress";
import About from "@/data/software/picture/about";

const Tool = [
  Screenshot, //截图
  Edit, //编辑
  Compress, //压缩
  About, //其他
];
export default Tool;
