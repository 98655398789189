//摄影
const data = [
  {
    name: "Pexels",
    
    download: "https://www.pexels.com/zh-cn/",
    icon: "svg",
    state: "free",
    msg: "精彩的素材图片和视频",
  },
  {
    name: "Magdeleine",
    
    download: "https://magdeleine.co/",
    icon: "png",
    state: "free",
    msg: "手工挑选的免费库存照片的灵感",
  },
  {
    name: "Pixabay",
    
    download: "https://pixabay.com/zh/",
    icon: "svg",
    state: "free",
    msg: "高清免费无版权图库",
  },
  {
    name: "Unsplash",
    
    download: "https://unsplash.com/",
    icon: "svg",
    state: "free",
    msg: "互联网图片",
  },
  {
    name: "iStock",
    
    download: "https://www.istockphoto.com/en",
    icon: "svg",
    state: "free",
    msg: "免费高分辨率图片集，无版权",
  },
  {
    name: "Gratisography",
    
    download: "https://gratisography.com/",
    icon: "png",
    state: "free",
    msg: "免费，无版权",
  },
  {
    name: "1X",
    
    download: "https://1x.com/",
    mark: "#ffffff",
    icon: "png",
    msg: "与众不同的照片社区",
  },
  {
    name: "图虫",
    
    download: "https://tuchong.com/",
    icon: "png",
    msg: "摄影图片分享社区",
  },
  {
    name: "StockSnap",
    
    download: "https://stocksnap.io/",
    mark: "#ffffff",
    icon: "webp",
    state: "free",
    msg: "美丽的免费无版权相片",
  },
];

const newArr = { type: "摄影", path: "shoot", data: data };

export default newArr;
