//page 路由
import Bios from "@/page/page/bios.vue";
import Related from "@/page/page/related.vue";
import About from "@/page/page/about.vue";
import Book from "@/page/page/book.vue";
import Music from "@/page/page/music.vue";
import Movie from "@/page/page/movie.vue";
const pageData = [
  { title: "Bios", path: "bios", describe: "BIOS 快速查询", component: Bios },
  {
    title: "文章",
    path: "single",
    describe: "常用的文章",
    component: Related,
  },
  { title: "书籍", path: "book", name: "book", component: Book },
  { title: "音乐", path: "music", name: "music", component: Music },
  { title: "电影", path: "movie", name: "movie", component: Movie },
  {
    title: "相关",
    path: "about",
    describe: "分类统计",
    component: About,
  },
];

export default pageData;
