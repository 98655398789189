//哔哩哔哩
const data = [
  {
    name: "黄家守卫军",
    username: "黄家守卫军", //用户名
    icon: "png",
    download: "https://space.bilibili.com/393865074",
    msg: "专业、深度军事分析",
  },
];

const newArr = { type: "B站", path: "bilibili", data: data };

export default newArr;
