//压缩
const data = [
  {
    name: "TinyPng",
    download: "https://tinypng.com/",
    
    icon: "png",
    msg: "超完美PNG压缩工具",
  },
  {
    name: "Compress",
    download: "https://compressjpeg.com/zh/",
    
    icon: "svg",
    msg: "在线压缩JPG、JPEG、PNG、GIT图片",
    state: "free",
  },
  {
    name: "docsmall",
    download: "https://docsmall.com/",
    
    icon: "png",
    state: "free",
    msg: "在线 GIF 动图压缩",
  },
  {
    name: "Optimizilla",
    download: "https://imagecompressor.com/zh/",
    
    icon: "png",
    state: "free",
    msg: "支持JPEG和PNG格式在线压缩工具",
  },
  
  

 
];

const newArr = { type: "压缩", path: "compress", data: data };

export default newArr;
