//优秀页面
const data = [
  {
    name: "Dunderville",

    download: "https://www.dunderville.se",
    icon: "png",
    msg: "故事创作专家",
  },
  {
    name: "WKUN",

    download: "https://www.wkun.com/",
    icon: "svg",
    msg: "王小困设计",
  },
  {
    name: "张洪Heo",

    download: "https://zhheo.com/",
    icon: "webp",
    msg: "让设计师来主导产品",
  },
  {
    name: "Shelter",

    download: "https://sheltertheanimation.com/",
    icon: "png",
    mark: "#000",
    msg: "《避难所》音乐和映像传达的感动的故事！",
  },
  {
    name: "Noone",
    download: "https://treefish.gitee.io/#/",
    icon: "svg",
  },
  {
    name: "Su7",
    download: "https://gamemcu.com/su7/",
    icon: "ico",
    msg: "小米 Su7 模型在线展示",
  },
  {
    name: "Apple",
    download: "https://www.apple.com.cn/",
    icon: "svg",
    msg: "苹果官网",
  },
  {
    name: "Linktree",
    download: "https://linktr.ee/",
    icon: "png",
    msg: "简历链接生成",
    text: "科学",
  },
];

const newArr = {
  type: "页面",
  path: "case",
  data: data,
  describe: "优秀设计的页面",
};

export default newArr;
