//驱动 相关工具
const data = [
  {
    name: "Intel",
    system: ["Win"],
    download:
      "https://www.intel.cn/content/www/cn/zh/download-center/home.html",
    mark: "#4290cb",
    icon: "svg",
    msg: "驱动下载",
  },
  {
    name: "AMD",
    system: ["Win"],
    download:
      "https://www.amd.com/zh-cn/support/download/drivers.html#windows-linux-drivers",
    mark: "#000",
    icon: "svg",
    msg: "驱动下载",
  },
  {
    name: "联想驱动管理",
    system: ["Win"],
    download: "https://think.lenovo.com.cn/webdrivercd/One.aspx",
    icon: "about",
  },
  {
    name: "华为电脑管家",
    system: ["Win"],
    download: "https://consumer.huawei.com/cn/support/pc-manager/",
    icon: "png",
    msg: "华为电脑驱动下载",
  },
  {
    name: "DriverTools",
    system: ["Win"],
    download: "https://consumer.huawei.com/cn/support/content/zh-cn15926691/",
    icon: "about",
    msg: "华为电脑驱动一键下载工具",
  },
];

const newArr = {
  type: "驱动",
  path: "drive",
  data: data,
  describe: "驱动 相关工具",
};

export default newArr;
