<script setup lang="ts">
import { computed, inject, Ref } from "vue";
import RightIcon from "@/components/block/function/right_pc.vue";
import Data from "@/router/data";

//拿到最新路由名
const RouterName = inject("RouterName") as Ref<string>;
//拿到当前路由path
const RouterPath = inject("RouterPath") as Ref<{
  pagename?: string;
  screen: string;
  page?: string;
}>;

//找到当前路由的页面名称
const routeTitles = {
  home: "首页",
  search: "搜索",
  searchdata: "搜索",
  record: "历史记录",
};

//页面标题
const result = computed(() => {
  //存在路由
  const pageName = RouterPath.value.pagename;
  const page = RouterPath.value.page;
  //是链接导航

  if (pageName) {
    return Data.router.find((item) => item.path === pageName);
  }
  //是页面

  if (page) {
    return Data.title.find((item) => item?.path === page);
  }

  //是单页
  const title = routeTitles[RouterName.value as keyof typeof routeTitles]; // 如果找到了对应的标题
  if (title) {
    return { title };
  }
});
</script>
<template>
  <div
    class="md:w-[76.2%] md:h-auto w-full overflow-hidden md:rounded-lg md:shadow-lg text-TwoTitle dark:text-darkTwoTitle"
  >
    <!--顶部-->
    <div
      class="md:border-b dark:border-darkBgCardSeparate hidden md:flex justify-between items-center h-14 lg:px-6 px-4 bg-BgCardHeader dark:bg-darkBgCardHeader"
    >
      <h1 class="text-lg font-semibold flex items-center">
        <span class="inline-block"> {{ result?.title || "404 NotFound" }}</span>
      </h1>

      <!--标志-->
      <RightIcon />
    </div>
    <!--内容-->
    <div
      class="relative lg:px-6 p-4 min-h-[33.6rem] mb-4 md:mb-0 bg-BgCard dark:bg-darkBgCard"
    >
      <!--展示列表数据-->
      <router-view></router-view>
    </div>
  </div>
</template>
