//变现
const data = [
  {
    name: "Softonic",
    msg: "发现最佳应用",
    icon: "svg",
    download: "https://en.softonic.com/",
  },
  {
    name: "Product Hunt",
    download: "https://www.producthunt.com/",
    icon: "svg",
    msg: "发布和发现新技术产品的地方",
  },
  {
    name: "数码荔枝",
    msg: "分享最新鲜优秀的正版软件",
    icon: "png",
    download: "https://lizhi.shop/",
  },
  {
    name: "Appstorrent",
    msg: "适用于 macOS 的游戏和应用程序",
    icon: "png",
    download: "https://appstorrent.ru/",
  },
  {
    name: "SourceForge",
    msg: "比较、下载和开发开源和商业软件",
    icon: "svg",
    download: "https://sourceforge.net/",
  },
  {
    name: "FOSSHUB",
    msg: "安全、快速、免费的",
    icon: "svg",
    download: "https://www.fosshub.com/",
    state: "free",
  },
  {
    name: "MacWk",
    msg: "精品Mac软件下载",
    icon: "png",
    download: "https://macwk.cn/",
  },
  {
    name: "Mac玩儿法",
    msg: "软件评测和推荐",
    icon: "png",
    download: "https://www.waerfa.com/",
  },
  {
    name: "MacApp分享频道",
    icon: "webp",
    download: "https://macapp.org.cn/",
  },

  {
    name: "Digit77",
    msg: "海量精品Mac应用免费下载",
    icon: "png",
    download: "https://www.digit77.com/",
  },

  {
    name: "Better365",
    msg: "Mac 系列精品软件",
    icon: "png",
    download: "https://www.better365.cn/apps.html",
  },
];

const newArr = { type: "软件", path: "software", data: data };

export default newArr;
