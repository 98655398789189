const data = [
  {
    title: "寻乌调查",
    author: ["毛泽东"],
    time: "1931-02",
    country: "中国",
    type: ["调查"],
    number: "",
    link: "https://www.marxistphilosophy.org/maozedong/mx1/027.htm",
    introduce: "调查报告范例",
  },
  {
    title: "百年孤独",
    author: ["加西亚·马尔克斯"],
    time: "2011-6",
    country: "哥伦比亚",
    type: ["小说"],
    number: "9787544253994",
    link: "https://book.douban.com/subject/6082808/",
    introduce:
      "魔幻现实主义文学的代表作，描写了布恩迪亚家族七代人的传奇故事，以及加勒比海沿岸小镇马孔多的百年兴衰，反映了拉丁美洲一个世纪以来风云变幻的历史",
  },
  {
    title: "活着",
    author: ["余华"],
    time: "2012-08",
    country: "中国",
    type: ["小说"],
    number: "9787506365437",
    link: "https://book.douban.com/subject/4913064/",
    introduce: "活着，像牲口一样活着",
  },
  {
    title: "亮剑",
    author: ["都梁"],
    time: "2005-03",
    country: "中国",
    type: ["小说"],
    number: "9787503311864",
    link: "https://book.douban.com/subject/1088022/",
    introduce:
      "李云龙是一个叱咤风云、百战沙场的职业军人，是一个一生都在血与火中搏斗的名将。他的人生信条是：面对强大的对手，明知不敌，也要毅然亮剑，即使倒下，也要成为一座山、一道岭。在战争与和平的时空转换中，他的命运注定要充满悲欢离合—无论是政治生涯还是婚姻、爱情。",
  },
  {
    title: "三体",
    author: ["刘慈欣"],
    time: "2008-01",
    country: "中国",
    type: ["小说"],
    number: "9787540471248",
    link: "https://book.douban.com/subject/30306441/",
    introduce: "弱小和无知不是生存的障碍，傲慢才是",
  },
  {
    title: "飘",
    author: ["玛格丽特·米切尔"],
    time: "2000-09",
    country: "美国",
    type: ["小说"],
    number: "9787806570920",
    link: "https://book.douban.com/subject/1068920/",
    introduce: "先爱自己，才能爱其他人",
  },
];
export default data;
