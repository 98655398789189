//其他
const data = [
  {
    name: "邮政",
    
    download: "https://www.ems.com.cn/queryList",
    icon: "svg",
  },
  {
    name: "顺丰",
    
    download: "https://www.sf-express.com/chn/sc/waybill/list",
    icon: "svg",
  },
  {
    name: "京东物流",
    
    download: "https://www.jdl.com/orderSearch/?waybillCodes",
    icon: "svg",
  },
  {
    name: "极兔速递",
    
    download: "https://www.jtjms-br.com/service.html?indexIpt=",
    icon: "svg",
  },
  {
    name: "圆通速递",
    
    download: "https://www.yto.net.cn/tracesimple.html/",
    icon: "svg",
  },
  {
    name: "申通快递",
    
    download: "https://www.sto.cn/pc/service-page/iframe_2_21?index=0",
    icon: "svg",
  },
  {
    name: "中通快递",
    
    download: "https://www.zto.com/express/expressCheck.html",
    icon: "svg",
  },
  {
    name: "韵达速递",
    
    download: "http://ydgw.yundasys.com:31620/view/zxfuwu/sfastgood.html?na_id=136",
    icon: "svg",
  },
  {
    name: "德邦快递",
    
    download: "https://www.deppon.com/track/ordertrack",
    icon: "svg",
    msg:"大件快递",
  },
  {
    name: "百世快运",
    
    download: "https://www.800best.com/freight/track",
    icon: "svg",
  },
  {
    name: "宅急送",
    
    download: "http://www.zjs.com.cn/hwcx/index.jhtml",
    icon: "svg",
  },
  {
    name: "快递100",
    
    download: "https://www.kuaidi100.com/",
    icon: "svg",
    msg:"便捷查快递",
  },
  {
    name: "爱查快递",
    
    download: "https://www.ickd.cn/",
    icon: "svg",
    msg:"便捷查快递",
  },
];

const newArr = { type: "快递", path: "express", data: data };

export default newArr;
