<script setup lang="ts">
//站点分享按钮
import { ref } from "vue";
import { siteUrl } from "@/store/tool";
import { IconData, ColorSetting } from "@/store/tool";
import SvgIcon from "@/components/icon/SvgIcon.vue";

//分享的支持状态
const type = ref(
  typeof navigator !== "undefined" && typeof navigator.share !== "undefined"
);

//系统级分享
const sharePromise = () => {
  if (navigator.share) {
    navigator
      .share({
        title: document.title,
        text: "专业且便捷的软件和站点导航站",
        url: siteUrl.value,
      })
      .then(() => {
        // 分享成功时的处理
      })
      .catch((error) => {
        console.error("分享失败", error);
      });
  } else {
    alert(`当前浏览器版本不支持分享此功能`);
  }
};

//复制级分享
const openShare = async () => {
  try {
    await navigator.clipboard.writeText(siteUrl.value);
    alert("链接成功复制到剪贴板，快去分享吧");
    //TODO:使用 Message 组件 优化下
  } catch (err) {
    console.error("链接复制到剪贴板失败，请重试", err);
  }
};
</script>
<template>
  <!--原生分享-->
  <span class="cursor-pointer" @click="sharePromise()" v-if="type">
    <SvgIcon
      class="w-10 h-10 p-2 hover:opacity-50"
      :name="IconData.share"
      :color="ColorSetting.default"
      title="分享"
      alt="分享"
    />
  </span>

  <!--复制分享-->
  <span class="cursor-pointer" @click="openShare()" v-else="type">
    <SvgIcon
      class="w-10 h-10 p-2"
      :name="IconData.share"
      :color="ColorSetting.default"
      title="分享"
      alt="分享"
    />
  </span>
</template>
<style scoped></style>
