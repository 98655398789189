//生活服务
import Express from "@/data/page/site/life/express";
import Bank from "@/data/page/site/life/bank";
import Phone from "@/data/page/site/life/phone";
import Shopping from "@/data/page/site/life/shopping";
import About from "@/data/page/site/life/about";
import Realization from "@/data/page/site/life/realization";
import Software from "@/data/page/site/life/software";

import { SonType } from "@/store/interface";
const Data = [
  Express, //快递
  Bank, //银行
  Phone, //手机网盘
  Software,//软件商店
  Realization,//变现
  Shopping,//购物
  About,//其他
];
export default Data as SonType[];
