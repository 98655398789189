//输入法
const data = [
  {
    name: "中国银行",
    
    download: "https://www.boc.cn/",
    icon: "svg",
  },
  {
    name: "农业银行",
    
    download: "https://www.abchina.com/cn/",
    icon: "svg",
  },
  {
    name: "工商银行",
    
    download: "https://www.icbc.com.cn/icbc/",
    icon: "svg",
  },
  {
    name: "建设银行",
    
    download: "http://www.ccb.com/cn/home/indexv3.html",
    icon: "svg",
  },
  {
    name: "邮政",
    
    download: "https://www.psbc.com/cn/",
    icon: "svg",
  },
  {
    name: "交通银行",
    
    download: "https://www.bankcomm.com/BankCommSite/default.shtml",
    icon: "svg",
  },
  {
    name: "招商银行",
    
    download: "https://cmbchina.com/",
    icon: "svg",
  },
  {
    name: "浦发银行",
    
    download: "https://www.spdb.com.cn/",
    icon: "svg",
  },
  {
    name: "兴业银行",
    
    download: "https://www.cib.com.cn/cn/",
    icon: "svg",
  },
  {
    name: "民生银行",
    
    download: "https://www.cmbc.com.cn/",
    icon: "svg",
  },
  {
    name: "中信银行",
    
    download: "https://www.citicbank.com/",
    icon: "svg",
  },
];

const newArr = { type: "银行", path: "bank", data: data };

export default newArr;
