//截图
const data = [
  {
    name: "Snipaste",
    system: ["Win", "Mac"],
    download: "https://zh.snipaste.com/",
    icon: "svg",
    msg: "截图+ 贴图",
  },
  {
    name: "ShareX",
    system: ["Win"],
    download: "https://getsharex.com/downloads",
    icon: "png",
    state: "gnu",
    msg: "屏幕截图、文件共享和生产力工具",
  },
  {
    name: "iShot",
    system: ["Mac"],
    download: "https://www.better365.cn/ishot.html",
    icon: "webp",
    msg: "截图、标注、OCR、翻译",
  },
  {
    name: "ScreenToGif",
    system: ["Win"],
    download: "https://www.screentogif.com/",
    icon: "png",
    state: "free",
    msg: "GIF录制和编辑",
  },
  {
    name: "PixPin",
    system: ["Win"],
    download: "https://pixpinapp.com/",
    icon: "png",
    state: "free",
    msg: "长截图并标注",
  },
];

const newArr = { type: "截图", path: "screenshot", data: data };

export default newArr;
