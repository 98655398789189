//公共函数
import { ref, computed, inject, Ref } from "vue";
import { PathType } from "@/store/interface";
import router from "@/router";

//颜色设定

//白天
const dayColor = {
  default: "#bfbfbf", //默认
  active: "#000", //提示色

  white: "#ffffff", //白色
  logo: "#ff6347", //logo色
  pitch: "#000", //选中颜色

  ColorButton: "#fff", //按钮中的文字颜色
  //二级标题
  TwoTitle: "#334155",

  //三级标题
  ThreeTitle: "#94a3b8",
};
//晚上
const darkColor = {
  default: "#dee4ea", //默认
  active: "#ff6347", //提示色

  white: "#ffffff", //白色
  logo: "#ff6347", //logo色
  pitch: "#fff", //选中颜色
  ColorButton: "#fff", //按钮中的文字颜色
  TwoTitle: "#dee4ea",
  ThreeTitle: "#aaaeb3",
};

//准备图标
// 定义 IconData 类型
type IconDataType = {
  [key: string]: string;
};

// 定义 IconData 对象
export const IconData: IconDataType = {
  home: "menu_home", //首页
  share: "menu_share", //分享
  sun: "menu_sun", //日
  moon: "menu_moon", //日月
  record: "menu_record", //记录
  top: "menu_top", //回到顶部
  search: "menu_search", //搜索
  right: "menu_right", //右
  email: "menu_email", //邮箱
  free: "menu_free", //免费
  gnu: "menu_gnu", //开源
  Win: "menu_win",
  Mac: "menu_mac",
  Linux: "menu_linux",
  Web: "menu_web",
  book: "menu_book", //书
  movie: "menu_movie", //影
  music: "menu_music", //音
};

//判断传入的路由类型是否是以下几个，是的话，显示系统切换菜单
export const showMenuType = (RouterName: Ref<string>) => {
  return computed(() => {
    // "page", "pagetype", "pagescreen"
    if (["cat", "screen"].includes(RouterName.value)) {
      // 执行相应的逻辑
      return true;
    } else {
      return false;
    }
  });
};

//拿到当前动态路由的最新的一级路径
const sitePate = computed(() => {
  const RouterPath = inject("RouterPath") as Ref<PathType>;
  if (RouterPath.value.pagename) {
    return RouterPath.value.pagename;
  }
  if (RouterPath.value.page) {
    //console.log(RouterPath.value.page);
    return RouterPath.value.page;
  }
});

//判断当前路由名称是否在指定名称中
export const isPath = (path: string) => {
  //判断传入的路径与当前菜单的路径是否相同
  //限定当前路由名类型
  return sitePate.value === path;
};

//菜单中，点击跳转路由
export const clickRouter = (name: string, path: string) => {
  if (name === "cat") {
    router.push({
      name: name,
      params: {
        pagename: path,
      },
    });
  }
  if (name === "page") {
    //判断传入的路径是否为site，是的话，跳转到pagescreen页面
    if (path === "site") {
      router.push({
        name: "pagescreen", // 目标路由的名称
        params: {
          page: "site",
          type: "picture", // 固定的类型
          screen: "all", // 你可以选择一个默认的筛选值
        },
      });
      return;
    }
    if (path === "page") {
      router.push({
        name: "pagescreen", // 目标路由的名称
        params: {
          page: "page",
          type: "single", // 固定的类型
          screen: "default", // 你可以选择一个默认的筛选值
        },
      });
      return;
    }
    router.push({
      name: name,
      params: {
        page: path,
      },
    });
  }
  if (name === "pagescreen") {
    router.push({
      name: name,
      params: {
        page: "site",
        type: "picture",
      },
    });
  }
};

//获取页面宽度，大于768px，为true
export const screenWidth = window.innerWidth > 768;

//跳转404 页面
export const goNotFound = () => {
  router.push({
    name: "notFound",
  });
};

//准备当前链接

export const siteUrl = computed(() => {
  //获取本地系统信息
  const locaData = ref(localStorage.getItem("screenSystem"));
  // 如果没有获取到系统信息，则设置默认值
  const systemInfo = locaData.value ? JSON.parse(locaData.value) : "Win";
  //获取网址
  const baseUrl = window.location.origin;
  //提供最新的路径信息
  const currenToute = router.currentRoute;
  //获取路径信息
  const currentUrls = currenToute.value.fullPath;
  //拼接
  const url = baseUrl + currentUrls + "?system=" + systemInfo;
  return url;
});

//洗牌算法 - 随机数组
export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// 节流函数
type ThrottleFunc = (...args: any[]) => void;

const throttle = (func: ThrottleFunc, limit: number) => {
  let lastFunc: ReturnType<typeof setTimeout> | undefined;
  let lastRan: number | undefined;

  return function (this: any, ...args: any[]) {
    const context = this;
    if (lastRan === undefined) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan! >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan!));
    }
  };
};
//滚动距离大于600则未true
export const showTopType = ref(false);

export const handleScroll = throttle(() => {
  showTopType.value = window.scrollY > 600;
}, 100); // 节流时间间隔为 100 毫秒

// 点击按钮时滚动到页面顶部
export const handleButtonClick = () => {
  // 滚动到页面顶部
  window.scrollTo({ top: 0, behavior: "smooth" });
};
//滑动一段距离后，图标亮起

//颜色模式切换组件
// 从 localStorage 中获取 颜色模式的 数据
const colorMode = localStorage.getItem("colorMode");

//初始颜色模式
const colorData = ref(dayColor);

//状态
export const isDark = ref(false);
//初始颜色模式
//调整颜色模式
if (colorMode) {
  // 解析 colorMode 字符串为对象数组
  isDark.value = JSON.parse(colorMode);
  if (isDark.value) {
    //设为黑色模式
    document.body.classList.add("dark");
    //初始颜色模式
    colorData.value = darkColor;
  } else {
    //设为白色模式
    document.body.classList.remove("dark");
    //初始颜色模式
    colorData.value = dayColor;
  }
}
//切换颜色模式
export const switchBtn = () => {
  const body = document.body;
  if (body.classList.contains("dark")) {
    // 如果已经有类名 'dark'，则移除它
    body.classList.remove("dark");
    isDark.value = false; //切换图标
    localStorage.setItem("colorMode", JSON.stringify(false));
    //设为黑色模式
    colorData.value = dayColor;
  } else {
    // 如果没有类名 'dark'，则添加它
    body.classList.add("dark");
    isDark.value = true; //切换图标
    localStorage.setItem("colorMode", JSON.stringify(true));
    //设为黑色模式
    colorData.value = darkColor;
    console.log(colorData.value);
  }
};

// 导出最新的颜色值
export const ColorSetting = computed(() => {
  return colorData.value;
});
