//文档
const data = [
  {
    name: "MDN",
    
    download: "https://developer.mozilla.org/zh-CN/",
    icon: "svg",
    msg: "记录Web技术，包括CSS，HTML和JavaScript",
  },
  {
    name: "学习CSS布局",
    
    download: "https://zh.learnlayout.com/",
    icon: "png",
    msg: "互动式教程学习 CSS 布局基础",
  },
  {
    name: "JavaScript 初学指南",
    download: "https://segmentfault.com/a/1190000016337325",
    icon: "about",
    msg: "很棒的基础教程",
  },
  {
    name: "envato tuts+",
    
    download: "https://tutsplus.com",
    icon: "svg",
    state: "free",
    msg: "来自领先行业专家的数千个教程、提示和技巧",
  },
  {
    name: "Envato设计教程",
    download: "https://design.tutsplus.com/tutorials",
    
    icon: "about",
    msg: "Envato时尚设计效果教程",
  },
  {
    name: "理解极简主义",
    
    download: "http://understandingminimalism.com/",
    icon: "about",
    msg: "关于极简主义的相关探讨",
  },
  {
    name: "AnywayFM",
    download: "http://anyway.fm/",
    
    icon: "png",
    msg: "设计经验、历程杂谈的一款播客节目",
  },
  {
    name: "Medium",
    download: "https://medium.com/",
    
    icon: "png",
    msg: "分享写作的力量",
  },
  {
    name: "Medium设计",
    download: "https://medium.design/",
    
    icon: "png",
    msg: "国外精美的设计文章欣赏",
  },
  {
    name: "知识麦田",
    download: "https://zhishimt.com/",
    
    icon: "png",
    msg: "设计师教程分享平台",
  },

  {
    name: "优设",
    download: "http://www.uisdc.com/",
    
    icon: "jpg",
    msg: "国内设计师教程分享文章",
  },

  {
    name: "设计咖",
    download: "https://uxcoffee.com/",
    
    icon: "png",
    msg: "关注产品设计和注用户体验FM播客",
  },

  {
    name: "图月志",
    download: "http://iconmoon.com/blog2/",
    
    icon: "png",
    msg: "JJYing的界面设计个人博客[设计师]",
  },
  {
    name: "HeyDesigner",
    download: "http://heydesigner.com/",
    
    icon: "png",
    msg: "设计师和前端开发者的美文收录",
  },
  {
    name: "Web Design Blog",
    download: "http://www.webdesignerdepot.com/",
    
    icon: "svg",
    msg: "网页设计教程和文章学习平台",
  },

  {
    name: "Pixel Joint",
    download: "http://pixeljoint.com/",
    
    icon: "png",
    msg: "像素艺术爱好者交流学习社区",
  },
  {
    name: "Smashing Magazine",
    download: "https://www.smashingmagazine.com/",
    
    icon: "svg",
    msg: "WEB设计开发杂志博客",
  },
  {
    name: "FEDEV",
    msg: "前端开发者学堂",
    download: "https://fedev.cn/",
    icon: "png",
    
  },
];

const newArr = {
  type: "文档",
  path: "doc",
  data: data,
  describe: "设计的理论基础",
};

export default newArr;
