//清理文件
const data = [
  {
    name: "毛主席纪念堂",
    
    download: "http://cpc.people.com.cn/GB/143527/143528/index.html",
    icon: "about",
  },
  {
    name: "周恩来纪念网",
    
    download: "http://zhouenlai.people.com.cn/",
    icon: "about",
  },
  {
    name: "邓小平纪念堂",
    
    download: "http://cpc.people.com.cn/GB/143527/147177/index.html",
    icon: "about",
  },

  {
    name: "李鸿章享堂",
    
    download: "http://www.lihongzhang.org.cn/",
    icon: "about",
  },
];

const newArr = { type: "人物", path: "character", data: data };

export default newArr;
