//购物
const data = [
  {
    name: "阿里拍卖",
    download: "https://sf.taobao.com/",
    icon: "svg",
    msg: "法拍大宗资产",
  },
  {
    name: "闲鱼",
    download: "https://www.goofish.com/",
    icon: "svg",
    msg: "二手平台",
  },
  {
    name: "京东",
    download: "https://www.jd.com/",
    icon: "svg",
    msg: "家电数码",
  },
  {
    name: "淘宝",
    download: "https://www.taobao.com/",
    icon: "svg",
    msg: "应有尽有",
  },
  {
    name: "ThinkPad",
    download: "https://www.thinkpad.com/index.html",
    icon: "svg",
    msg: "笔记本电脑和台式机",
  },
  {
    name: "联想",
    download: "https://www.thinkpad.com/index.html",
    icon: "svg",
    msg: "企业商用站点",
  },
  {
    name: "惠普",
    download: "https://www.hp.com/cn-zh/home.html",
    icon: "svg",
  },
  {
    name: "戴尔",
    download: "https://www.dell.com/zh-cn",
    icon: "svg",
  },
  {
    name: "新华三",
    download: "https://www.h3c.com/cn/",
    icon: "svg",
  },
  {
    name: "华硕",
    download: "https://w3.asus.com.cn/",
    icon: "svg",
  },
];

const newArr = { type: "购物", path: "shopping", data: data };

export default newArr;
