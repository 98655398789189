<script setup lang="ts">
//右侧图标
import { inject, Ref } from "vue";
import Share from "@/components/block/function/share.vue";
import SvgIcon from "@/components/icon/SvgIcon.vue";
import { ColorSetting, IconData } from "@/store/tool";
//拿到当前路由名字
const RouterName = inject("RouterName") as Ref<string>;
</script>
<template>
  <div class="flex justify-center items-center">
    <router-link to="/" title="首页">
      <SvgIcon
        class="w-10 h-10 p-[0.6rem] hover:opacity-50"
        :name="IconData.home"
        :color="
          RouterName === 'home' ? ColorSetting.active : ColorSetting.default
        "
        title="首页"
        alt="首页"
      />
    </router-link>
    <!--分享-->
    <Share />
    <router-link to="/search" title="搜索">
      <SvgIcon
        class="w-10 h-10 p-2 hover:opacity-50"
        :name="IconData.search"
        :color="
          ['search', 'searchdata'].includes(RouterName)
            ? ColorSetting.active
            : ColorSetting.default
        "
        title="搜索"
        alt="搜索"
      />
    </router-link>
  </div>
</template>
