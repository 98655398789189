<script setup lang="ts">
//页面模版 - 弃用
import Data from "@/data/software/index";
import { CategoryType } from "@/store/interface";
import { PageSiteData } from "@/data/page/summary";
import SvgIcon from "@/components/icon/SvgIcon.vue";
import { ColorSetting, IconData } from "@/store/tool";

// 统计所有站点中的网址个数
const SiteCount = PageSiteData.reduce(
  (count, obj) => count + (obj.data ? obj.data.length : 0),
  0
);

//准备类型
interface CountType {
  num: number;
  name: string;
  msg: string;
}
interface Count {
  [key: string]: any;
  win: CountType;
  mac: CountType;
  linux: CountType;
  web: CountType;
}

//计算个类型数量
const countValues = (obj: CategoryType | CategoryType[], count: Count) => {
  if (Array.isArray(obj)) {
    for (let item of obj) {
      countValues(item, count);
    }
  } else {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        if (key === "system") {
          for (let i = 0; i < obj[key].length; i++) {
            switch (obj[key][i]) {
              case "Win":
                count.win.num += 1;
                break;
              case "Mac":
                count.mac.num += 1;
                break;
              case "Linux":
                count.linux.num += 1;
                break;
              case "Web":
                count.web.num += 1;
                break;
              default:
                // Handle other cases if necessary
                break;
            }
          }
        } else {
          for (let i = 0; i < obj[key].length; i++) {
            if (typeof obj[key][i] === "object") {
              countValues(obj[key][i], count);
            }
          }
        }
      } else if (typeof obj[key] === "object") {
        countValues(obj[key], count);
      }
    }
  }
};

// 初始化四种系统的计数器
const initialCount: Count = {
  win: { num: 0, name: "Win", msg: "Windows 系统" },
  mac: { num: 0, name: "Mac", msg: "MAC 系统" },
  linux: { num: 0, name: "Linux", msg: "Linux 系统" },
  web: { num: SiteCount, name: "Web", msg: "网页" },
};

// 调用递归函数，传入对象获取各系统出现次数
countValues(Data, initialCount);

//计算占比
const calculatePercentage = (obj: Count) => {
  const totalNum = Object.values(obj).reduce((acc, val) => acc + val.num, 0);

  for (const key in obj) {
    obj[key].num = ((obj[key].num / totalNum) * 100).toFixed(1) + "%"; // 计算百分比并转换为对应类型
  }

  return obj;
};

const result = calculatePercentage(initialCount);
</script>
<template>
  <!--反馈-->
  <h5
    class="text-center text-xl font-semibold leading-9 dark:text-gray-200 text-gray-900 m-2"
  >
    各系统下链接占比
  </h5>
  <div class="flex justify-center flex-wrap gap-6 px-2 lg:px-28">
    <div
      v-for="(value, key) in result"
      :key="key"
      :style="{ '--width-one': value.num }"
      class="g-progress"
      :alt="value.msg"
      :title="value.msg"
    >
      <span
        class="text dark:bg-darkBgCard bg-[#fff] text-ThreeTitle dark:text-darkThreeTitle"
      >
        <div class="block">
          <SvgIcon
            class="w-8 h-8 my-0 mx-auto"
            :name="IconData[value.name]"
            :color="ColorSetting.ThreeTitle"
            :alt="value.msg"
            :title="value.msg"
          />
          <p>{{ value.num }}</p>
        </div>
      </span>
    </div>
  </div>
</template>
<style scoped>
.g-progress {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: conic-gradient(
    #16a34a 0,
    #16a34a var(--width-one),
    #e5e7eb var(--width-one),
    #e5e7eb
  );
}

.text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-weight: bold;

  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border-radius: 50%;
}
</style>
