//变现
const data = [
  {
    name: "Patreon",
    msg: "社区变现站",
    download: "https://www.patreon.com/zh-CN",
    icon: "svg",
  },
  {
    name: "面包多",
    msg: "为创作者创收",
    download: "https://mbd.pub/",
    icon: "svg",
  },
  {
    name: "小报童",
    msg: "人们愿意追随一个活生生的人，而非一个公司",
    download: "https://xiaobot.net/home.html",
    icon: "svg",
  },
  {
    name: "爱发电",
    msg: "连接创作者与粉丝的赞助平台",
    download: "https://ifdian.net/",
    icon: "png",
  },
  {
    name: "一杯咖啡",
    msg: "你创作内容，观众请你喝咖啡",
    download: "https://1kafei.com/",
    icon: "svg",
  },
  {
    name: "竹白",
    msg: "专为创作者设计的一站式工具",
    download: "https://zhubai.love/",
    icon: "png",
  },

  {
    name: "知12",
    msg: "将内容和资源变为长期收入",
    download: "https://www.zhi12.cn/",
    icon: "png",
    mark: "#bfbfbf",
  },
];

const newArr = { type: "变现", path: "realization", data: data };

export default newArr;
