//其他
const data = [
  {
    name: "一证通查",
    msg: "查询名下电话卡数量",
    download: "https://getsimnum.caict.ac.cn/",
    icon: "ico",
  },
  {
    name: "一证通查2.0",
    msg: "查询名下绑定的互联网账号数量",
    download: "http://www.news.cn/politics/2022-07/21/c_1128850668.htm",
    icon: "about",
  },
  {
    name: "网盘有效性检查 - 油猴",
    msg: "自动识别并检查链接状态,自动填写密码并跳转",
    download:
      "https://greasyfork.org/zh-CN/scripts/439266-%E7%BD%91%E7%9B%98%E6%9C%89%E6%95%88%E6%80%A7%E6%A3%80%E6%9F%A5",
    icon: "greasy",
    text: "扩展",
  },
  {
    name: "Dragon",
    msg: "可以在历史记录日历上快速跳转",
    download:
      "https://microsoftedge.microsoft.com/addons/detail/dragon-%E5%8E%86%E5%8F%B2%E8%AE%B0%E5%BD%95/lmnppmohpgndifoijdjcnljcdbffjdoo",
    icon: "png",
    text: "扩展",
  },
  {
    name: "Octotree",
    msg: "GitHub 代码树",
    download: "https://www.octotree.io/",
    icon: "svg",
    text: "扩展",
  },
  {
    name: "Vimium C",
    msg: "全键盘操作浏览器",
    download: "https://github.com/gdh1995/vimium-c",
    icon: "png",
    text: "扩展",
  },
  {
    name: "Wappalyzer",
    msg: "网页技术检测工具",
    download: "https://www.wappalyzer.com/",
    icon: "svg",
  },
  {
    name: "Bookmarks clean up",
    msg: "删除、合并重复书签",
    download:
      "https://chromewebstore.google.com/detail/bookmarks-clean-up/oncbjlgldmiagjophlhobkogeladjijl",
    icon: "png",
  },
  {
    name: "JSON Formatter",
    msg: "使 JSON 易于阅读",
    download:
      "https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa?hl=en",
    icon: "png",
  },
  {
    name: "勤诚",
    msg: "个人或服务器机箱等产品",
    download: "https://www.chenbro.com/zh-CN",
    icon: "ico",
  },
  {
    name: "MacOS 使用手册",
    download: "https://support.apple.com/zh-cn/guide/mac-help/welcome/mac",
    icon: "svg",
  },
  {
    name: "Apple 服务营销工具",
    download: "https://toolbox.marketingtools.apple.com/zh-cn",
    icon: "svg",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
