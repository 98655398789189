//路由数据，准备菜单
const catData = [
  {
    title: "电商",
    path: "business",
  },
  {
    title: "常用",
    path: "wasteland",
  },
  {
    title: "办公",
    path: "work",
  },
  {
    title: "多媒体",
    path: "amuse",
  },
  {
    title: "文件",
    path: "file",
  },
  {
    title: "图片",
    path: "picture",
  },

  {
    title: "工具",
    path: "tool",
  },
];
//文章路由统一添加名称
const catArray = catData.map((obj) => {
  return {
    ...obj,
    name: "cat",
  };
});

//页面数据
const pageData = [
  {
    title: "站点",
    path: "site",
    name: "page",
  },

  {
    title: "媒体",
    path: "see",
    name: "page",
  },

  {
    title: "页面",
    path: "page",
    name: "page",
  },
  //{
  //  title: "多媒体",
  //  path: "media",
  //  name: "page",
  //},
];

const aboutData = [
  {
    title: "首页",
    path: "/",
    name: "home",
  },
  {
    title: "搜索",
    path: "search",
    name: "search",
  },
  {
    title: "我的记录",
    path: "record",
    name: "record",
  },
];

//路由数据
const routerData = [...catArray, ...pageData];

//标题数据
const titleData = [...catArray, ...pageData, , ...aboutData];

const data = {
  title: titleData,
  router: routerData,
};

export default data;
