//办公套件
const data = [
  {
    name: "WPS",
    system: ["Win", "Mac", "Linux"],
    download: "https://platform.wps.cn/",
    icon: "svg",
  },
  {
    name: "Office",
    system: ["Win", "Mac","Web"],
    download: "https://account.microsoft.com/services",
    icon: "svg",
    describe: "您的微软账户购买过或登录过全新笔记本电脑才能安装",
  },
  {
    name: "金山文档",
    system: ["Win", "Mac", "Web"],
    download: "https://www.kdocs.cn/newDownload",
    icon: "svg",
    msg: "多人协作办公",
  },
  {
    name: "石墨文档",
    system: ["Win", "Mac", "Linux","Web"],
    download: "https://shimo.im/download",
    icon: "png",
    msg: "文档实时协同 · 知识沉淀管理 · 数据安全可控",
  },
  {
    name: "LibreOffice",
    system: ["Win", "Mac", "Linux"],
    download: "https://zh-cn.libreoffice.org/download/libreoffice/",
    icon: "png",
    state: "gnu",
    msg: "自由免费的全能办公套件",
  },
  {
    name: "vivo办公套件",
    system: ["Win", "Mac", "Web"],
    download: "https://quantumkit.vivo.com/#/",
    icon: "png",
    msg: "跨设备，提供更高效便捷的办公体验",
  },
  {
    name: "腾讯文档",
    system: ["Win", "Mac","Linux", "Web"],
    download: "https://docs.qq.com/",
    icon: "svg",
    msg: "高效协作，轻松创作",
  },
  {
    name: "飞书文档",
    system: ["Win", "Mac","Linux", "Web"],
    download: "https://docs.feishu.cn/",
    icon: "webp",
    msg: "融合了在线文档和协同文档的所有功能",
  },
];

const newArr = { type: "文档表格", path: "work", data: data };

export default newArr;
