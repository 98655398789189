//清理文件
const data = [
  {
    name: "腾讯柠檬",
    system: ["Mac"],
    download: "https://lemon.qq.com/",
    icon: "png",
    state: "free",
    msg: "一键释放磁盘空间",
  },
  {
    name: "HiBit Uninstaller",
    system: ["Win"],
    download: "http://hibitsoft.ir/Uninstaller.html",
    icon: "png",
    state: "free",
    msg: "强力卸载工具",
  },
  {
    name: "SpaceSniffer",
    system: ["Win"],
    download: "http://www.uderzo.it/main_products/space_sniffer/index.html",
    icon: "png",
    state: "free",
    msg: "磁盘空间分析",
  },
  {
    name: "CCleaner",
    system: ["Win", "Mac"],
    download: "https://www.ccleaner.com/zh-cn",
    icon: "svg",
    msg: "知名电脑优化工具",
  },
  {
    name: "Geek Uninstaller",
    system: ["Win"],
    download: "https://geekuninstaller.com/",
    icon: "png",
    state: "free",
    msg: "清理和卸载文件",
  },
  {
    name: "Duplicate Cleaner",
    system: ["Win"],
    download: "https://www.duplicatecleaner.com/",
    icon: "png",
    msg: "查找并删除重复文件",
  },
];

const newArr = { type: "清理", path: "clear", data: data };

export default newArr;
