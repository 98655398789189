//网盘
const data = [
  {
    name: "百度网盘",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://pan.baidu.com/download",
    icon: "png",
  },
  {
    name: "夸克网盘",
    system: ["Win", "Mac", "Web"],
    download: "https://pan.quark.cn/",
    icon: "png",
  },

  {
    name: "阿里云盘",
    system: ["Win", "Mac", "Web"],
    download: "https://www.alipan.com/download",
    icon: "png",
  },
  {
    name: "腾讯微云",
    system: ["Win", "Mac", "Web"],
    download: "https://www.weiyun.com/download.html",
    icon: "png",
    msg: "支持QQ文件一键转存",
  },

  {
    name: "115生活",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://115.com/5/product_cloud",
    icon: "svg",
    msg: "原115网盘",
  },
  {
    name: "迅雷云盘",
    system: ["Win", "Mac", "Web"],
    download: "https://pan.xunlei.com/",
    icon: "svg",
    mark: "#308bfd",
    msg: "在线云盘",
  },
  {
    name: "360AI云盘",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.yunpan.com/download#/",
    icon: "png",
    msg: "安全存储的云盘",
  },
  {
    name: "UC 网盘",
    system: ["Win", "Mac", "Web"],
    download: "https://drive.uc.cn/",
    icon: "png",
  },
  {
    name: "天翼云盘",
    system: ["Win", "Mac","Linux", "Web"],
    download: "https://cloud.189.cn/",
    icon: "svg",
  },
];

const newArr = { type: "网盘", path: "netdisk", data: data };

export default newArr;
