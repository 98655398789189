//翻译
const data = [
  {
    name: "百度翻译",
    system: ["Win", "Mac", "Web"],
    download: "https://fanyi.baidu.com/",
    icon: "png",
  },
  {
    name: "微软翻译",
    system: ["Win", "Web"],
    download: "https://cn.bing.com/translator/",
    icon: "svg",
  },
  {
    name: "谷歌翻译",
    system: ["Web"],
    download: "https://translate.google.com/",
    icon: "svg",
  },
  {
    name: "有道翻译",
    system: ["Win", "Mac", "Web"],
    download: "https://fanyi.youdao.com/",
    icon: "png",
  },
  {
    name: "Bob",
    system: ["Mac"],
    download: "https://bobtranslate.com/",
    icon: "png",
    msg: "在任何应用程序中使用 OCR 翻译",
  },
  {
    name: "搜狗翻译",
    system: ["Web"],
    download: "https://fanyi.sogou.com",
    icon: "svg",
    msg: "贴身智能翻译专家",
  },
  {
    name: "智能翻译官",
    system: ["Win","Mac","Web"],
    download: "https://www.fanyi1234.com/",
    icon: "png",
  },
];

const newArr = { type: "翻译", path: "translate", data: data };

export default newArr;
