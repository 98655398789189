//编辑
const data = [
  {
    name: "GIMP",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.gimp.org/downloads/",
    icon: "svg",
    mark: "#0e2426",
    state: "gnu",
    msg: "开源图片编辑器",
  },
  {
    name: "Inkscape",
    download: "https://inkscape.org/zh-hans/",
    system: ["Win", "Mac", "Linux"],
    icon: "png",
    msg: "一个强大，免费的设计工具",
    state: "gnu",
  },
  {
    name: "PhotoDemon",
    download: "https://photodemon.org/download/",
    system: ["Win"],
    icon: "png",
    msg: "便携式的，开源的照片编辑器",
    state: "gnu",
  },
  {
    name: "eDrawings",
    download: "https://www.edrawingsviewer.com/download-edrawings",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "查看3D模型和2D图纸",
  },
  {
    name: "一键抠图",
    download: "https://www.yijiankoutu.com/",
    system: ["Win", "Mac", "Web"],
    icon: "png",
    msg: "在线抠图",
  },
];

const newArr = {
  type: "编辑",
  path: "edit",
  data: data,
};

export default newArr;
