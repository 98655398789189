import Wasteland from "@/data/software/wasteland";
import Tool from "@/data/software/tool";
import Business from "@/data/software/business/";
import File from "@/data/software/file/";
import Amuse from "@/data/software/amuse/";
import Work from "@/data/software/work/";
import Picture from "@/data/software/picture";

import { CategoryType } from "@/store/interface";
const data = [
  {
    title: "电商",
    path: "business",
    data: Business,
  },
  {
    title: "常用",
    path: "wasteland",
    data: Wasteland,
  },
  {
    title: "办公",
    path: "work",
    data: Work,
  },
  { title: "多媒体", path: "amuse", data: Amuse },
  { title: "文件", path: "file", data: File },
  { title: "图片", path: "picture", data: Picture },
  { title: "工具", path: "tool", data: Tool },
] as CategoryType[];

export default data;
