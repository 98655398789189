//界面交互
const data = [
  {
    name: "Reeoo",
    download: "http://reeoo.com/",
    
    icon: "svg",
    msg: "全球最火热的酷站画廊",
  },

  {
    name: "4DB",
    download: "http://4db.cc/",
    
    icon: "about",
    msg: "日本网页设计风格展",
  },

  {
    name: "Pttrns",
    download: "http://pttrns.com/",
    
    icon: "png",
    msg: "专业收集APP截图的网站",
  },

  {
    name: "uplabs",
    download: "https://www.uplabs.com/",
    
    icon: "png",
    msg: "采集前端作品设计、iOS UI设计",
  },
  {
    name: "OnePageLove",
    download: "https://onepagelove.com/",
    
    icon: "svg",
    msg: "网站和APP单页界面欣赏",
  },

  {
    name: "Freebiesbug",
    download: "http://freebiesbug.com/",
    
    icon: "about",
    msg: "高质量设计网站、资源聚合站点",
  },
  {
    name: "UIgarage",
    download: "https://uigarage.net/",
    
    icon: "jpg",
    msg: "UI灵感收集聚合站点",
  },
  {
    name: "Little Big Details",
    download: "http://littlebigdetails.com/",
    
    icon: "png",
    msg: "设计细节动效灵感分享网站",
  },
  {
    name: "Calltoidea",
    download: "http://www.calltoidea.com/",
    
    icon: "png",
    mark: "#000",
    msg: "收集优秀UI组件元素设计的站点",
  },
  {
    name: "MUUUUU",
    download: "http://muuuuu.org/",
    
    icon: "svg",
    msg: "日本漂亮的酷站收集",
    text:"科学",
  },

  {
    name: "SiteSee",
    download: "https://sitesee.co/",
    
    icon: "svg",
    msg: "收录漂亮的界面设计网站",
  },
  {
    name: "Best Website Gallery",
    download: "https://bestwebsite.gallery/",
    
    icon: "png",
    msg: "漂亮的酷站收集展示",
  },
  {
    name: "Web Design Clip",
    download: "http://www.webdesignclip.com/",
    
    icon: "svg",
    msg: "丰富的日式响应式设计收录站点",
  },
  {
    name: "CSS Winner",
    download: "http://www.csswinner.com/",
    
    icon: "svg",
    mark: "#d93f3f",
    msg: "优秀CSS网站界面和交互设计获奖作品",
  },
  {
    name: "Siteinspire",
    download: "https://www.siteinspire.com/",
    
    icon: "png",
    msg: "优秀网页设计展示平台",
  },

  {
    name: "lovely ui",
    download: "http://www.lovelyui.com/",
    
    icon: "jpg",
    msg: "可爱的UI手机界面设计搜集",
  },
];

const newArr = {
  type: "交互",
  path: "interactive",
  data: data,
  describe: "前端交互设计灵感",
};

export default newArr;
