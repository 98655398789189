<script setup lang="ts">
//优雅展示SVG图标
import { computed } from "vue";

export interface Props {
  prefix?: string;
  name: string; //必选
  color?: string;
}

//默认值
const props = withDefaults(defineProps<Props>(), {
  prefix: "icon",
  //name: "hello",
  color: "#333",
});

const symbolId = computed(() => {
  if (props.name == "git") {
    return `#icon-Git`;
  }
  if (props.name == "win") {
    return `#icon-Win`;
  } else {
    return `#${props.prefix}-${props.name}`;
  }
});
</script>
<template>
  <svg aria-hidden="true">
    <use :xlink:href="symbolId" :fill="color" />
  </svg>
</template>
