//本地记录数据

import { DataType } from "@/store/interface";

const updateLocalStorage = (data: DataType): void => {
  // 从 localStorage 中获取 HistoryData 数据
  const savedataString = localStorage.getItem("HistoryData");

  let HistoryData: DataType[] = [];

  if (savedataString) {
    // 解析 HistoryData 字符串为对象数组
    HistoryData = JSON.parse(savedataString);
  }

  //删除系统类型键值对 - 最后一个会保留
  const newArray = HistoryData.map((obj) => {
    const { system, ...rest } = obj;
    return rest;
  });

  // 查找是否已存在相同的软件名
  const existingSoftware = newArray.find((item) => item.name === data.name);

  if (existingSoftware && existingSoftware.num) {
    // 存在相同的软件名，将其 num 值加 1
    existingSoftware.num += 1;
  } else {
    // 不存在相同的软件名，添加 num: 1 的新数据
    const newSoftware: DataType = { ...data, num: 1 };
    newArray.push(newSoftware);
  }

  // 将更新后的 HistoryData 保存回 localStorage
  localStorage.setItem("HistoryData", JSON.stringify(newArray));
};

export default updateLocalStorage;
