//文章链接
export const singleLink = [
  {
    id: 1,
    title: "怎么给win10多系统设置默认的启动系统",
    link: "https://www.bkqs.com.cn/content/zpe4qz7ny.html",
    type: "系统",
  },
  {
    id: 2,
    title: "win11系统第一次开机跳过联网的办法",
    link: "https://blog.csdn.net/jaray/article/details/130722929",
    type: "系统",
  },
  {
    id: 3,
    title: "Windows10 切换用户访问共享",
    link: "https://www.cnblogs.com/Yogile/p/12589615.html",
    type: "NAS",
  },
  {
    id: 4,
    title: "微信多开",
    link: "https://www.bilibili.com/read/cv6259988/",
    type: "教程",
  },
  {
    id: 5,
    title: "Win10系统中取消本地账户密码的方法",
    link: "https://blog.csdn.net/xzji001/article/details/134201141",
    type: "教程",
  },
  {
    id: 6,
    title: "华为电脑驱动解决办法",
    link: "https://consumer.huawei.com/cn/support/content/zh-cn00688502/",
    type: "驱动",
  },
  {
    id: 7,
    title: "安装系统驱动方法",
    link: "https://zhidao.baidu.com/question/2202828209592209668/answer/2336475000.html",
    type: "驱动",
  },
  {
    id: 8,
    title: "Microsoft Edge 中的 Internet Explorer 模式",
    link: "https://support.microsoft.com/zh-cn/microsoft-edge/microsoft-edge-%E4%B8%AD%E7%9A%84-internet-explorer-%E6%A8%A1%E5%BC%8F-6604162f-e38a-48b2-acd2-682dbac6f0de",
    type: "教程",
  },
  {
    id: 8,
    title: "PS制作满屏水印",
    link: "https://jingyan.baidu.com/article/af9f5a2de8b1eb03150a455c.html",
    type: "教程",
  },
  {
    id: 8,
    title: "什么是网络堆叠？",
    link: "https://info.support.huawei.com/info-finder/encyclopedia/zh/%E5%A0%86%E5%8F%A0.html",
    type: "网络",
  },
];

//教程
export const postList = [
  {
    title: "win11 跳过联网激活",
    msg: ` 按下【Shift+F10】快捷键（无效可试下【FN+Shfit+F10】），
    在出现的命令提示符页面输入OOBE\\BYPASSNRO，按回车键，等待电脑重启完成"`,
  },
  {
    title: "修改Windows用户名",
    msg: "win+R,运行‘netplwiz’",
  },
  {
    title: "修改网盘",
    msg: "搜索“凭据”",
  },
  {
    title: "双开微信",
    msg: "鼠标点击一次微信图标，双击两次键盘上的回车键（Enter）即可”",
  },
  {
    title: "双开微信2",
    msg: 'start "" "D:\\Program Files\\WeChat\\WeChat.exe"',
  },
  {
    title: "关闭系统杀毒",
    msg: "点击开始---运行输入gpedit.msc---计算机配置--管理模板--windows组件---Windows Defender 防病毒程序<br/>关闭Windows Defender防病毒程序， 设置已启动，点击确定<br/>再在cmd中执行gpupdate来更新策略。",
  },
  {
    title: "中国银行软件无法打开",
    msg: "选中图标，右键属性，选择",
  },
];
