//博客
const data = [
  {
    name: "孤斗",
    download: "https://d-d.design/",
    icon: "png",
  },
  {
    name: "Kevin's",
    download: "https://www.shephe.com/",
    icon: "ico",
  },
  {
    name: "梨数码",
    download: "https://lishuma.com/",
    icon: "ico",
  },
  {
    name: "耳朵的主人",
    download: "https://www.imerduo.com/",
    icon: "png",
  },
  {
    name: "大鱼的博客",
    download: "https://dayuzy.com/",
    icon: "png",
  },
  {
    name: "奔跑中的奶酪",
    msg: "有智，有趣，有爱",
    download: "https://www.runningcheese.com/",
    icon: "png",
  },
  {
    name: "王登科-DK博客",
    download: "https://greatdk.com/",
    icon: "ico",
  },
  {
    name: "chopstack",
    download: "https://chopstack.com/",
    icon: "blog",
  },
  {
    name: "TonyHe",
    download: "https://www.ouorz.com/",
    icon: "ico",
  },
  {
    name: "Switch头号玩家",
    msg:"游戏资讯",
    download: "https://www.ouorz.com/",
    icon: "png",
  },
];

const newArr = { type: "博客", path: "blog", data: data };

export default newArr;
