//内网分享
const data = [
  {
    name: "Snapdrop",
    system: ["Web"],
    download: "https://snapdrop.net/",
    icon: "svg",

    state: "free",
    msg: "内网传文件",
  },
  {
    name: "LocalSend",
    system: ["Win", "Mac", "Linux"],
    download: "https://localsend.org/#/download",
    icon: "png",
    state: "gnu",
    msg: "内网分享文件",
  },
  {
    name: "闪电藤",
    system: ["Win", "Mac", "Linux"],
    download: "https://lightningvine.zishu.life/download.html",
    icon: "png",
    state: "gnu",
    msg: "LocalSend国内优化版",
  },
  {
    name: "IPmsg",
    system: ["Win"],
    download: "https://ipmsg.org/",
    icon: "png",
    state: "free",
    msg: "内网传文件",
  },
];

const newArr = { type: "内网", path: "sharelan", data: data };

export default newArr;
