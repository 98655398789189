import Page from "@/router/page/page";
import Site from "@/router/page/site";
import See from "@/router/page/see";

export const PageData = [
  { title: "页面", path: "page", data: Page },
  { title: "站点", path: "site", data: Site },
  { title: "阅览", path: "see", data: See },
];

// 获取所有 data 下的 data 的 path 值组成的数组
export const PagePathArray = PageData.flatMap((item) =>
  item.data.map((subItem) => subItem.path)
);
