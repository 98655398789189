<script setup lang="ts">
import Header from "@/components/Header.vue";
import Content from "@/components/Content.vue";
import Footer from "@/components/Footer.vue";
import { ref, watch, computed, provide } from "vue";
import { useRoute } from "vue-router";

//拿到路由中的值
const route = useRoute();
const name = ref(route.name);
const path = ref(route.params); //当前路由信息

// 获取当前最新路由信息
watch(
  () => route.name,
  (newId) => {
    name.value = newId;
  }
);
watch(
  () => route.params,
  (newId) => {
    path.value = newId;
  }
);
provide(/* 注入名 */ "RouterName", /* 值 */ name); //集中监听，方便复用
provide(/* 注入名 */ "RouterPath", /* 值 */ path); //集中监听，方便复用

//从浏览器本地获取默认选中的值
const locaData = localStorage.getItem("screenSystem");

//如果没有拿到则给默认值
const screenSystemData = ref(locaData ? JSON.parse(locaData) : "Win");

provide(/* 注入名 */ "screenSystem", /* 值 */ screenSystemData);

//获取默认系统值
const defaultSystem = computed(() => {
  //从URL中拿到系统值
  return route.query.system;
});

//监听链接 若有系统值则写入本地

watch(
  () => defaultSystem.value,
  (newId) => {
    if (newId) {
      localStorage.setItem("screenSystem", JSON.stringify(newId));
    }
  }
);
</script>

<template>
  <div
    class="bg-gradient-to-b from-[#eeeeee] via-[#f0f0f0] to-[#f2f2f2] dark:from-darkBgBody dark:via-darkBgBody dark:to-darkBgBody min-h-screen backdrop-opacity-100 backdrop-blur-lg"
  >
    <Header></Header>

    <Content></Content>

    <Footer></Footer>
  </div>
</template>

<style scoped>
/*
背景色
.jobs {
  background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.6)
    ),
    #ededed;
  background-blend-mode: soft-light, normal;
}
  */
</style>
