//协作
const data = [
  {
    name: "Pixso",
    system: ["Win", "Mac", "Web"],
    download: "https://pixso.cn/",
    icon: "png",
    msg: "产品设计协作一体化工具",
  },
  {
    name: "博思白板",
    system: ["Win", "Mac", "Web"],
    download: "https://boardmix.cn/",
    icon: "svg",
    msg: "可视化协同设计工具",
  },
  {
    name: "MasterGo",
    system: ["Win", "Mac", "Web"],
    download: "https://mastergo.com/",
    icon: "svg",
    msg: "数字界面生产平台",
  },
];

const newArr = { type: "协作", path: "collaborate", data: data };

export default newArr;
