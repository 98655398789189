//格式转化
const data = [
  {
    name: "格式工厂",
    system: ["Win"],
    download: "http://www.pcfreetime.com/formatfactory/CN/download.html",
    icon: "gif",
    state: "free",
  },
  {
    name: "在线格式转换",
    msg: "支持文档、图片、电子书和视频",
    download: "https://www.aconvert.com/cn/",
    system: ["Web"],
    icon: "ico",
    state: "free",
  },
  {
    name: "Online Converter",
    download: "https://www.onlineconverter.com/",
    system: ["Web"],
    icon: "png",

    state: "free",
    msg: "转换视频，音频，图像，电子书，文档等",
  },
  {
    name: "在线文件转换器",
    download: "https://cdkm.com/cn/",
    system: ["Web"],
    icon: "svg",
    state: "free",
    msg: "将文件从一种格式转换为另一种格式",
  },
  {
    name: "ASPOSE",
    download: "https://www.aspose.app/zh/",
    system: ["Web"],
    icon: "svg",
    msg: "文档类和图片格式为主",
  },
  {
    name: "Onlineconvertfree",
    download: "https://onlineconvertfree.com/zh/",
    system: ["Web"],
    icon: "svg",
    msg: "多类型多格式在线转换文件，最多每天10个",
  },
  {
    name: "即刻转换",
    download: "https://zh.okaapps.com/product/1637557903",
    system: ["Mac"],
    icon: "png",
    state: "",
    msg: "万能小视频音频批量格式转码工厂",
    describe: "一天免费用三次",
  },
  {
    name: "HandBrake",
    download: "https://handbrake.fr/",
    system: ["Mac"],
    icon: "png",
    state: "gnu",
    msg: "几乎任何格式的编解码器",
  },
];

const newArr = { type: "转换", path: "convert", data: data };

export default newArr;
