//AI 相关工具
const data = [
  {
    name: "秘塔AI搜索",
    system: ["Web"],
    download: "https://metaso.cn/",
    icon: "svg",
    msg: "没有广告，直达结果",
  },
  {
    name: "KIMI",
    system: ["Web"],
    download: "https://kimi.moonshot.cn/",
    icon: "png",
  },
  {
    name: "豆包",
    system: ["Win", "Mac", "Web"],
    download: "https://www.doubao.com/chat/",
    icon: "png",
    msg: "字节跳动旗下 AI 智能助手",
  },
  {
    name: "CueMe",
    system: ["Web"],
    download: "https://www.cueme.cn/",
    icon: "svg",
    msg: "夸克旗下 AI 智能助手",
  },
];

const newArr = { type: "AI", path: "ai", data: data, describe: "AI 相关工具" };

export default newArr;
