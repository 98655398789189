<script setup lang="ts">
/**
 * 移动端底部按钮
 */
import { inject, Ref, onMounted, onBeforeUnmount } from "vue";
import {
  showTopType,
  handleButtonClick,
  handleScroll,
  IconData,
  ColorSetting,
} from "@/store/tool";
import Share from "@/components/block/function/share.vue";
import SvgIcon from "@/components/icon/SvgIcon.vue";

//拿到当前路由名字
const RouterName = inject("RouterName") as Ref<string>;
//监听
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<template>
  <section class="site-tabbar-container block md:hidden">
    <div class="site-tabbar-content">
      <div class="flex justify-center items-center gap-4">
        <!--首页-->
        <router-link
          to="/"
          title="首页"
          class="flex justify-center transition-all duration-200"
        >
          <SvgIcon
            class="w-10 h-10 p-2"
            :name="IconData.home"
            :color="
              RouterName === 'home' ? ColorSetting.white : ColorSetting.default
            "
            title="首页"
            alt="首页"
          />
        </router-link>
        <!--分享-->

        <span class="flex justify-center transition-all duration-200">
          <Share />
        </span>

        <!--搜索-->
        <router-link
          class="flex justify-center transition-all duration-200"
          to="/search"
          title="搜索"
        >
          <SvgIcon
            class="w-10 h-10 p-2"
            :name="IconData.search"
            :color="
              ['search', 'searchdata'].includes(RouterName)
                ? ColorSetting.white
                : ColorSetting.default
            "
            title="搜索"
            alt="搜索"
          />
        </router-link>
        <!--返回顶部-->
        <div
          class="flex justify-center cursor-pointer transition-all duration-200 topBtn"
          @click="handleButtonClick"
          :class="{ invisible: showTopType }"
        >
          <SvgIcon
            class="w-10 h-10 p-2"
            :name="IconData.top"
            :color="ColorSetting.default"
            title="返回顶部"
            alt="返回顶部"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
/**返回顶部按钮出现样式 */
.topBtn {
  visibility: hidden;
  width: 0;

  opacity: 0;
}

.topBtn.invisible {
  visibility: visible;
  opacity: 1;
  width: 2.5rem;
}
/*
* 移动端底部按钮背景
*/
.site-tabbar-container {
  position: sticky;
  left: 0;
  bottom: 0;
  font-size: 1.4rem;
  width: 100%;
  height: 4rem;
  pointer-events: none;
  z-index: 19;
}
.site-tabbar-content {
  visibility: visible;
  will-change: auto;
  -webkit-transform: translate(-50%, -1rem);
  transform: translate(-50%, -1rem);
  background-color: rgba(51, 51, 51, 0.7);
  box-shadow: inset 0 0 0 0.1rem rgba(244, 244, 244, 0.1);

  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 0.2rem 1rem;

  transition: visibility 0.6s,
    -webkit-transform 0.6s cubic-bezier(0.19, 1.5, 0.48, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1.5, 0.48, 1), visibility 0.6s;
  transition: transform 0.6s cubic-bezier(0.19, 1.5, 0.48, 1), visibility 0.6s,
    -webkit-transform 0.6s cubic-bezier(0.19, 1.5, 0.48, 1);
  border-radius: 10rem;
  pointer-events: auto;

  -webkit-backdrop-filter: saturate(180%) blur(2rem);
  backdrop-filter: saturate(180%) blur(2rem);
}
</style>
