//开发
const data = [
  {
    name: "VS Code",
    system: ["Win", "Mac", "Linux"],
    download: "https://code.visualstudio.com/",
    icon: "png",
    state: "gnu",
    msg: "前端开发用 IDE",
  },
  {
    name: "VS Code",
    system: ["Web"],
    download: "https://vscode.dev/",
    icon: "png",
    state: "gnu",
    msg: "在线开发用 IDE",
  },
  {
    name: "Android Studio",
    system: ["Win", "Mac", "Linux"],
    download: "https://developer.android.com/studio",
    icon: "svg",
    msg: "Flutter 开发用 IDE",
  },
  {
    name: "Goland",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.jetbrains.com/zh-cn/go/",
    icon: "svg",
    msg: "GO 开发用 IDE",
  },
  {
    name: "Docker",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.docker.com/",
    icon: "svg",
    state: "gnu",
    msg: "容器平台",
  },
  {
    name: "Orbstack",
    system: ["Mac"],
    download: "https://orbstack.dev/",
    icon: "webp",
    state: "gnu",
    msg: "专属优化版 Docker 工具",
  },
  {
    name: "Tower",
    system: ["Win", "Mac"],
    download: "https://www.git-tower.com/",
    icon: "svg",
    state: "free",
    msg: "git 管理工具",
  },
  {
    name: "iTerm2",
    system: ["Mac"],
    download: "https://iterm2.com/",
    icon: "jpg",
    msg: "终端的替代品",
  },
  {
    name: "Homebrew",
    system: ["Mac", "Linux"],
    download: "https://brew.sh/zh-cn/",
    icon: "svg",
    msg: "包管理器",
  },

  {
    name: "Sourcetree",
    system: ["Win", "Mac"],
    download: "https://www.sourcetreeapp.com/",
    icon: "svg",
    state: "free",
    msg: "简化与Git存储库的交互方式",
  },

  {
    name: "WinSCP",
    system: ["Win"],
    download: "https://winscp.net/eng/download.php",
    icon: "png",
    state: "gnu",
    msg: "免费的本地和远程计算机之间的文件传输",
  },
  {
    name: "Laragon",
    system: ["Win"],
    download: "https://laragon.org/download/",
    icon: "png",
    state: "gnu",
    msg: "PHP 前端通用开发环境",
  },
  {
    name: "Local",
    system: ["Win", "Mac", "Linux"],
    download: "https://localwp.com/",
    icon: "webp",
    state: "free",
    msg: "WordPress 本地开发环境",
  },
  {
    name: "CsvToJson",
    system: ["Web"],
    download: "https://csvtojson.dev/",
    icon: "svg",
    state: "free",
    msg: "csv 与 json 互转",
  },
  {
    name: "PhpWebStudy",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.macphpstudy.com/index.html",
    icon: "png",
    state: "free",
    msg: "强大的 Web 服务器和 PHP 环境",
  },
  {
    name: "MarsCode",
    system: ["Web"],
    download: "https://www.marscode.cn/",
    icon: "svg",
    state: "free",
    msg: "云端IDE",
  },
];

const newArr = { type: "开发", path: "develop", data: data };

export default newArr;
