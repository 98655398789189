//浏览器
const data = [
  {
    name: "Chrome",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.google.cn/chrome/index.html",
    icon: "svg",
  },
  {
    name: "Firefox浏览器",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.mozilla.org/zh-CN/firefox/new/",
    icon: "svg",
  },
  {
    name: "Edge浏览器",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.microsoft.com/zh-cn/edge/download",
    icon: "png",
  },
  {
    name: "夸克",
    system: ["Win"],
    download: "https://www.quark.cn/",
    icon: "svg",
  },
  {
    name: "360浏览器",
    system: ["Win", "Mac"],
    download: "https://browser.360.cn/se/",
    icon: "png",
    msg: "自带IE内核，兼容性好",
  },
  {
    name: "QQ浏览器",
    system: ["Win", "Mac"],
    download: "https://browser.qq.com/",
    icon: "svg",
  },
  {
    name: "搜狗浏览器",
    system: ["Win"],
    download: "https://ie.sogou.com/",
    icon: "svg",
  },

  {
    name: "Opera浏览器",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.opera.com/zh-cn",
    icon: "svg",
    msg:"安全、智能、隐私",
  },
  {
    name: "Yandex",
    system: ["Win", "Mac", "Linux"],
    download: "https://browser.yandex.com/",
    icon: "svg",
    msg:"俄罗斯隐私浏览器"
  },
  {
    name: "紫鸟浏览器",
    system: ["Win", "Mac"],
    download: "https://www.superbrowser.com/download/",
    icon: "svg",
    msg:"跨境电商用"
  },
  {
    name: "Vivaldi",
    system: ["Win", "Mac","Linux"],
    download: "https://vivaldi.com/zh-hans/",
    icon: "svg",
    msg:"强大、个性、私密",
    text: "科学",
  },
];

const newArr = { type: "浏览器", path: "browser", data: data };

export default newArr;
