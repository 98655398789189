//查看文件
const data = [
  {
    name: "坚果云",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.jianguoyun.com/s/downloads",
    icon: "png",
    msg: "跨平台同步工具",
  },
  {
    name: "OneDrive",
    system: ["Win", "Mac", "Web"],
    download: "https://www.microsoft.com/zh-cn/microsoft-365/onedrive/download",
    icon: "svg",
    msg: "专为商业而设计",
  },
  {
    name: "Dropbox",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.dropbox.com/install",
    icon: "svg",
    msg: "关于文件的一整套方案",
  },
];

const newArr = { type: "同步", path: "sync", data: data };

export default newArr;
