//邮箱
const data = [
  {
    name: "QQ邮箱",
    system: ["Web"],
    download: "https://mail.qq.com/",
    icon: "gif",
  },
  {
    name: "163邮箱",
    system: ["Web"],
    download: "https://mail.163.com/",
    icon: "png",
  },
  {
    name: "网易邮箱大师",
    system: ["Win", "Mac", "Linux"],
    download: "https://dashi.163.com/download.html",
    icon: "svg",
    msg: "全平台",
  },
  {
    name: "Foxmail",
    system: ["Win", "Mac"],
    download: "https://www.foxmail.com/",
    icon: "png",
    msg: "老牌邮箱",
  },
  {
    name: "Thunderbird",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.thunderbird.net/zh-CN/",
    icon: "svg",
    state: "gnu",
    msg: "火狐旗下开源邮箱",
  },
  {
    name: "Gmail",
    system: ["Web"],
    download: "https://www.google.com/intl/zh-CN/gmail/about/",
    icon: "png",
    msg: "知名邮箱",
    text: "科学",
  },
  {
    name: "Outlook",
    system: ["Win", "Mac", "Web"],
    download:
      "https://www.microsoft.com/zh-cn/microsoft-365/outlook/email-and-calendar-software-microsoft-outlook",
    icon: "svg",

    msg: "知名邮箱",
  },
  {
    name: "腾讯企业邮",
    system: ["Web"],
    download: "https://work.weixin.qq.com/mail/",
    icon: "png",

    msg: "企业微信下的邮箱",
  },
  {
    name: "阿里邮箱",
    system: ["Win", "Mac", "Web"],
    download: "https://mail.aliyun.com/mail-pages/download.html",
    icon: "svg",
    msg: "符合全栈国产化",
  },
];

const newArr = { type: "邮箱", path: "mail", data: data };

export default newArr;
