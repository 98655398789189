//二维码
const data = [
  {
    name: "QRBTF",
    download: "https://qrbtf.com/",
    
    icon: "svg",
    msg: "参数化二维码生成器",
  },

  {
    name: "草料二维码",
    download: "https://cli.im/",
    
    icon: "svg",
    msg: "国内创建二维码在线应用",
  },

  {
    name: "QuickMark",
    download: "http://www.quickmark.com.tw/cht/qrcode-datamatrix-generator/",
    
    icon: "png",
    msg: "台湾一家二维码在线制作和APP程序",
  },
];

const newArr = { type: "二维码", path: "qr", data: data };

export default newArr;
