<script setup lang="ts">
//底部功能区
import { inject, Ref } from "vue";
import SvgIcon from "@/components/icon/SvgIcon.vue";
import { switchBtn, isDark, ColorSetting, IconData } from "@/store/tool";

//关闭菜单方法
defineProps<{ toggleMobile: (value: boolean) => void }>();

//拿到当前路由名字
const RouterName = inject("RouterName") as Ref<string>;
</script>
<template>
  <div class="flex justify-center items-center gap-4 mt-6">
    <!--颜色模式切换-->
    <div
      class="flex justify-center items-center w-1/3 h-16 border rounded-lg"
      @click="switchBtn(), toggleMobile(true)"
    >
      <SvgIcon
        class="w-12 h-12 p-1"
        :name="isDark ? IconData.sun : IconData.moon"
        :color="isDark ? ColorSetting.white : ColorSetting.default"
        title="颜色切换"
        alt="颜色切换"
      />
    </div>

    <!--历史记录-->
    <router-link
      to="/record"
      class="flex justify-center items-center w-1/3 h-16 border rounded-lg"
      title="历史记录"
      @click="toggleMobile(true)"
    >
      <SvgIcon
        class="w-12 h-12 p-1"
        :name="IconData.record"
        :color="
          RouterName === 'record' ? ColorSetting.active : ColorSetting.default
        "
        title="历史记录"
        alt="历史记录"
      />
    </router-link>
  </div>
</template>
