//办公学习

import Work from "@/data/software/work/work";
import Read from "@/data/software/work/note";
import Mail from "@/data/software/work/mail";
import MindMap from "@/data/software/work/mindMap";
import Schedule from "@/data/software/work/schedule";
import Collaborate from "@/data/software/work/collaborate";
import Translate from "@/data/software/work/translate";
import About from "@/data/software/work/about";
const Data = [
  Work, //办公套件
  Read, //笔记文档
  Mail, //邮箱
  MindMap, //思维导图
  Schedule, //日程管理
  Collaborate, //协作
  Translate, //翻译
  About, //其他
];
export default Data;
