//视频
const data = [
  {
    name: "OBS",
    system: ["Win", "Mac", "Linux"],
    download: "https://obsproject.com/download",
    icon: "svg",
    state: "gnu",
    msg: "用于视频录制和直播的免费开源软件",
  },
  {
    name: "剪映",
    system: ["Win", "Mac", "Web"],
    download: "https://www.capcut.cn/",
    icon: "svg",
    mark: "black",
  },
  {
    name: "疯狂视频去水印",
    download: "https://douyin.video996.com/",
    system: ["Web"],
    icon: "png",

    state: "free",
    msg: "去除各平台短视频水印",
  },
  {
    name: "EV录屏",
    download: "https://www.ieway.cn/evcapture.html",
    system: ["Win"],
    icon: "png",
    msg: "分屏录制，场景编辑，教学画板",
  },
  {
    name: "Captura",
    download: "https://github.com/MathewSachin/Captura/releases",
    system: ["Win"],
    icon: "git",
    msg: "视频录制，已停更",
    state: "gnu",
  },
  {
    name: "录播姬",
    download: "https://rec.danmuji.org/",
    system: ["Win"],
    icon: "svg",
    msg: "简单好用的直播录制工具",

    state: "gnu",
  },
  {
    name: "必剪",
    download: "https://bcut.bilibili.cn/",
    system: ["Win", "Mac"],
    icon: "png",
    msg: "基础视频剪辑",
    mark: "rgba(14,14,16,.8)",
  },
  {
    name: "快剪辑",
    download: "http://kuai.360.cn/home.html",
    system: ["Win"],
    icon: "png",
    msg: "会打字就能用",
    mark: "rgb(0, 0, 0)",
  },
  {
    name: "Tern",
    download: "https://github.com/1c7/Translate-Subtitle-File",
    system: ["Web"],
    icon: "git",
    msg: "字幕组机翻小助手",

    state: "gnu",
  },
  {
    name: "Final Cut Pro",
    download: "https://www.apple.com.cn/final-cut-pro/",
    system: ["Mac"],
    icon: "png",
    msg: "知名视频剪辑软件",
  },

  {
    name: "唧唧",
    download: "https://www.jijidown.com/",
    system: ["Web"],
    icon: "ico",
    state: "free",
    msg: "下载哔哩哔哩的视频或者弹幕和封面",
  },
  {
    name: "LemonDownload",
    download: "https://lemondl.com/zh-CN",
    system: ["Web"],
    icon: "svg",
    state: "free",
    msg: "免费的哔哩哔哩视频下载器，无水印",
  },
  {
    name: "m3u8 视频在线提取工具",
    download: "https://blog.luckly-mjw.cn/tool-show/m3u8-downloader/index.html",
    system: ["Web"],
    icon: "png",
    msg: "点开即用",
  },
  {
    name: "Twitterfk",
    download: "https://www.twitterfk.com/",
    system: ["Web"],
    icon: "png",
    msg: "简单易用的 Twitter 视频下载器",
  },
  {
    name: "一键解析",
    download: "http://www.yijianjiexi.com/",
    system: ["Web"],
    icon: "png",
    state: "free",
    msg: "解析视频链接并下载",
  },
  {
    name: "Video Grabber",
    download: "https://www.videograbber.net/",
    system: ["Web"],
    icon: "svg",
    msg: "从各大网站抓取视频下载",
  },

  {
    name: "HOTBOX",
    download: "https://www.hotbox.fun/",
    system: ["Web"],
    icon: "about",
    msg: "视频文件下载",
  },
];

const newArr = { type: "视频", path: "video", data: data };

export default newArr;
