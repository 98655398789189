//案例
const data = [
  {
    name: "普通人网页配色方案",
    download: "http://www.ruanyifeng.com/blog/2019/03/coloring-scheme.html",
    
    icon: "about",
    msg: "手把手教你配色，不会太难看",
  },
  {
    name: "千通彩色管理",
    download: "https://www.qtccolor.com/secaiku/tool/convert",
    
    icon: "png",
    
    msg: "色彩空间转换",
  },
  {
    name: "Color Able",
    download: "https://colorable.jxnblk.com/",
    
    icon: "png",
    msg: "便捷的在线评估颜色对比度工具。 ",
  },
  {
    name: "奇迹秀-色彩空间",
    download: "https://colordesigner.io/",
    
    icon: "about",
    msg: "设计师的实用配色工具",
  },
  {
    name: "Color Designer",
    download: "https://colordesigner.io/",
    
    icon: "svg",
    msg: "基于所选颜色生成相似配色",
  },
  {
    name: "ColorSpace",
    download: "https://mycolor.space/",
    
    icon: "png",
    msg: "便捷、实用的渐变生成器",
  },
  {
    name: "WebGradients",
    download: "https://webgradients.com/",
    
    icon: "png",
    state: "free",
    msg: "180个线性渐变的集合",
  },

  {
    name: "Grabient",
    download: "https://www.grabient.com/",
    
    icon: "ico",
    state: "gnu",
    msg: "可视化生成渐变色",
  },
  {
    name: "Color Thief",
    download: "https://lokeshdhakar.com/projects/color-thief/",
    
    icon: "git",
    state: "gnu",
    msg: "从图像中获取调色板",
  },
  {
    name: "Color Stacks",
    download: "https://lokeshdhakar.com/projects/color-stacks/",
    
    icon: "svg",
    state: "free",
    msg: "一个用于设计系统的调色板生成器",
  },
  {
    name: "Adobe Color",
    download: "https://color.adobe.com/zh/",
    
    icon: "png",
    msg: "使用色轮或图像建立调色盘，或浏览数千个颜色组合。",
  },
  {
    name: "Color Hunt",
    download: "https://colorhunt.co/",
    
    icon: "png",
    msg: "手工挑选调色板。为你的设计和艺术项目获取色彩灵感",
  },
  {
    name: "Muzli Colors",
    download: "https://colors.muz.li/",
    
    icon: "png",
    msg: "根据配色提供案例参考",
  },
  {
    name: "Huesnap",
    download: "https://www.huesnap.com/",
    
    icon: "png",
    msg: "使用图像或色轮创建调色板",
  },

  {
    name: "Eva Design System",
    download: "https://colors.eva.design/",
    
    icon: "svg",
    msg: "深度学习彩色发生器设计系统",
  },
  {
    name: "Color Leap",
    download: "https://colorleap.app/home",
    icon: "png",
    msg: "复古色爱好者",
  },
  {
    name: "Picular",
    download: "https://picular.co/",
    
    icon: "svg",
    msg: "提供词语给出颜色",
  },
  {
    name: "Colourcode",
    download: "https://www.toptal.com/designers/colourcode",
    
    icon: "webp",
    msg: "让色彩理论可视化的配色网站",
  },
  {
    name: "Culrs",
    download: "https://culrs.com/",
    
    icon: "svg",
    msg: "邻近色、三色、四色和单色，色彩简洁清新",
  },
  {
    name: "中国传统色",
    download: "http://zhongguose.com/",
    
    mark:"#bfbfbf",
    icon: "ico",
    msg: "提供了约560种中国传统风格的颜色色值",
  },
  {
    name: "日本传统色",
    download: "https://nipponcolors.com",
    
    icon: "jpg",
    msg: "日本传统色配色网站，和风配色、上百种选择",
  },

  {
    name: "Colrd",
    download: "http://colrd.com/",
    
    icon: "png",
    mark: "#000",
    msg: "为创造和分享色彩灵感与世界的一个配色网站",
  },
  {
    name: "CoolHue",
    download: "https://webkul.github.io/coolhue/",
    
    icon: "png",
    msg: "最酷的手工挑选的渐变色集合调色板",
  },
];

const newArr = {
  type: "颜色",
  path: "color",
  data: data,
  describe: "颜色搭配建议",
};

export default newArr;
