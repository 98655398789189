//底层服务
const data = [
  {
    name: "Vercel",
    download: "https://vercel.com/",
    icon: "svg",
    msg: "一个云计算和部署平台",
  },
  {
    name: "Milkdown",
    download: "https://milkdown.dev/#/",
    system: "",
    icon: "git",
    msg: "MarkDown编辑器框架",
    state: "gnu",
  },
  {
    name: "Netlify",
    download: "https://www.netlify.com/",
    system: ["Web"],
    icon: "svg",
    msg: "卓越和动态Web体验的重要平台",
  },
];

const newArr = {
  type: "BASE",
  path: "base",
  data: data,
  describe: "底层服务",
};

export default newArr;
