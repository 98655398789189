//清理文件
const data = [
  {
    name: "微信读书",

    download: "https://weread.qq.com/",
    icon: "png",
  },
  {
    name: "机工新阅读",

    download: "http://www.cmpreading.com/",
    icon: "png",
    msg: "重新定义学习",
  },
  {
    name: "MOJi辞書",

    download: "https://www.mojidict.com/",
    icon: "png",
    msg: "百万用户共建的日语学习词典",
  },
  {
    name: "鸠摩搜索",

    download: "https://www.jiumodiary.com/",
    icon: "png",
    msg: "在线搜索百万书籍资源",
  },
  {
    name: "时光图书馆",

    download: "https://atimebook.com/",
    icon: "ico",
    msg: "经典珍藏图书、文章系列",
  },
  {
    name: "Libgen",
    download: "https://llhlf.com",
    icon: "png",
    msg: "知名搜书平台",
    text: "科学",
  },
  {
    name: "小时百科",
    download: "https://wuli.wiki/index.html",
    icon: "ico",
    msg: "自我提升，学习和科研利器",
  },
];

const newArr = {
  type: "书籍",
  path: "book",
  data: data,
  describe: "书籍是人类增长进步的阶梯",
};

export default newArr;
