//小组
const data = [
  {
    name: "Nodebb",
    download: "https://nodebb.org/",
    icon: "png",
    msg: "下一代论坛软件 – 功能强大、移动就绪且易于使用",
    mark: "#051c38",
  },
  {
    name: "Apache Answer",
    download: "https://answer.apache.org/",
    icon: "png",
    msg: "适用于任何规模团队的 Q&A 平台软件",
  },
  {
    name: "Sym",
    download: "https://b3log.org/sym/",
    icon: "png",
    msg: "现代化社区",
  },
];

const newArr = {
  type: "小组",
  path: "group",
  data: data,
  describe: "小组、论坛或社区",
};

export default newArr;
