//日程管理
const data = [
  {
    name: "金山待办",
    download: "https://todo.wps.cn/",
    system: ["Web"],
    icon: "png",

    state: "free",
  },
  {
    name: "Microsoft To Do",
    system: ["Win", "Mac", "Web"],
    download: "https://to-do.office.com/tasks/zh-cn",
    icon: "png",
    state: "free",
    msg: "让你从工作到娱乐都保持专注",
  },

  {
    name: "Todo清单",
    system: ["Win", "Mac"],
    download: "https://todo.evestudio.cn/",
    icon: "png",
    msg: "跨平台待办事项与时间管理",
  },

  {
    name: "Todoist",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://todoist.com/zh-CN/downloads",
    icon: "svg",
    msg: "全球第一的任务管理器和待办清单应用",
  },
  {
    name: "滴答清单",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.dida365.com/download",
    icon: "svg",
    msg: "达成更多，用心生活",
  },
  {
    name: "小黄条",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.yynote.cn/",
    icon: "png",
    msg: "极简、透明的桌面待办工具",
  },
];

const newArr = { type: "日程", path: "schedule", data: data };

export default newArr;
