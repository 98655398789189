//外网分享
const data = [
  {
    name: "微信传输助手",
    system: ["Web"],
    download: "https://filehelper.weixin.qq.com/",
    icon: "png",
  },

  {
    name: "AirPortal",
    system: ["Web"],
    download: "https://airportal.cn/",
    icon: "png",

    msg: "互联网免登录传文件",
  },
  {
    name: "奶牛快传",
    system: ["Web"],
    download: "https://cowtransfer.com/",
    icon: "png",

    msg: "大容量文件分享",
  },
  {
    name: "文叔叔",
    system: ["Web"],
    download: "https://www.wenshushu.cn/",
    icon: "svg",

    msg: "永不限速的云存储产品-传文件、收文件、网盘",
  },
  {
    name: "Wormhole",
    system: ["Web"],
    download: "https://wormhole.app/",
    icon: "png",

    state: "free",
    msg: "互联网免登录传文件",
  },
  {
    name: "1fichier",
    system: ["Web"],
    download: "https://1fichier.com/",
    icon: "png",
    msg: "互联网免登录传文件",
  },
];

const newArr = { type: "外网", path: "sharewan", data: data };

export default newArr;
