//笔记文档
const data = [
  {
    name: "Obsidian",
    system: ["Win", "Mac", "Linux"],
    download: "https://obsidian.md/download",
    icon: "svg",
    state: "gnu",
    msg: "流行的笔记软件",
  },
  {
    name: "Typora",
    system: ["Win", "Mac", "Linux"],
    download: "https://typoraio.cn/",
    icon: "png",
    msg: " Markdown 编辑器和阅读器",
  },
  {
    name: "浮墨笔记",
    download: "https://flomoapp.com/",
    system: ["Win", "Mac", "Web"],
    icon: "svg",
    msg: "快速记录，不断回顾",
  },
  {
    name: "语雀",
    system: ["Win", "Mac", "Web"],
    download: "https://www.yuque.com/download",
    icon: "svg",
    msg: "优秀的文档和知识库工具",
  },
  {
    name: "印象笔记",
    system: ["Win", "Mac", "Web"],
    download: "https://www.yinxiang.com/",
    mark: "#fff",
    icon: "png",
    msg: "记录备忘|收集信息|整理思路|共享成果",
  },
  {
    name: "有道云笔记",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://note.youdao.com/note-download",
    icon: "svg",
    msg: "记录，成为更好的自己",
  },
  {
    name: "MWeb",
    system: ["Mac"],
    download: "https://zh.mweb.im/",
    icon: "png",
    msg: "专业的 Markdown 写作、记笔记、静态博客生成软件",
  },
  {
    name: "Appflowy",
    system: ["Win", "Mac", "Linux"],
    download: "https://appflowy.io/download",
    icon: "svg",
    state: "gnu",
    msg: "Notion开源替代",
  },
  {
    name: "Notion",
    system: ["Win", "Mac", "Web"],
    download: "https://www.notion.so/desktop",
    icon: "svg",
    msg: "写作、计划、分享",
    text: "科学",
  },
  {
    name: "NotionChina",
    system: ["Web"],
    download: "https://notionchina.co/",
    icon: "svg",
    msg: "中文用户指南、工具、资源、模版",
  },
  {
    name: "Wolai",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.wolai.com/downloads",
    icon: "svg",
    msg: "国内版Notion",
  },
  {
    name: "Craft",
    system: ["Win", "Mac", "Web"],
    download: "https://www.craft.do/",
    icon: "png",
    msg: "创建个人或团队文档",
  },
  {
    name: "妙言",
    system: ["Mac"],
    download: "https://miaoyan.app/",
    icon: "png",
    msg: "轻灵的 Markdown 笔记本",
  },
  {
    name: "Verse",
    system: ["Win", "Mac", "Web"],
    download: "https://static.app.yinxiang.com/doc/",
    icon: "svg",
    msg: "印象笔记版Notion",
  },
  {
    name: "Lattics",
    system: ["Win", "Mac"],
    download: "https://lattics.zineapi.com/zh-CN",
    icon: "svg",
    msg: "类脑式卡片，章节式写作",
  },
  {
    name: "OneNote",
    system: ["Win", "Mac", "Web"],
    download: "https://www.onenote.com/download/",
    icon: "svg",
    msg: "知名笔记软件",
  },
  {
    name: "思源笔记",
    system: ["Win", "Mac", "Linux"],
    download: "https://b3log.org/siyuan/download.html",
    icon: "svg",
    state: "gnu",
    msg: "隐私优先的个人知识管理系统",
  },
  {
    name: "Effie",
    system: ["Mac"],
    download: "https://www.effie.co/",
    icon: "webp",
    msg: "写作、思维导图、大纲、AI 助手一体化选择",
  },
  {
    name: "DAY ONE",
    system: ["Mac"],
    download: "https://dayoneapp.com/",
    icon: "svg",
    msg: "生活日记",
  },
  {
    name: "Trilium",
    system: ["Win", "Mac", "Linux"],
    download: "https://github.com/zadam/trilium/blob/master/README-ZH_CN.md",
    icon: "svg",
    state: "gnu",
    msg: "专注于建立大型个人知识库",
  },
  {
    name: "WonderPen",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.tominlab.com/wonderpen/downloads",
    icon: "png",
    msg: "专业的写作软件",
  },
  {
    name: "Goodnotes",
    system: ["Win", "Mac"],
    download: "https://www.goodnotes.com/zh-cn/",
    icon: "svg",
    msg: "重塑手写笔记体验",
  },
  {
    name: "Logseq",
    system: ["Win", "Mac"],
    download: "https://logseq.com/",
    icon: "png",
    state: "gnu",
    msg: "一个隐私至上、开源的知识管理和协作平台",
  },
  {
    name: "为知笔记",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.wiz.cn/zh-cn/wiznew.html",
    icon: "png",
    msg: "从个人笔记到团队知识库，构建知识图谱",
  },

  {
    name: "Joplin",
    system: ["Win", "Mac", "Linux"],
    download: "https://joplinapp.org/download/",
    icon: "svg",
    state: "gnu",
    msg: "开源笔记应用程序，支持多平台",
  },
  {
    name: "Gridea",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://open.gridea.dev/#started",
    icon: "png",
    state: "gnu",
    msg: "静态博客写作客户端",
  },
  {
    name: "Notebook",
    system: ["Mac", "Web"],
    download: "https://www.zoho.com.cn/notebook/notebook-for-mac.html",
    icon: "png",
    msg: "漂亮、简单的Mac版笔记应用",
  },

  {
    name: "AxMath",
    system: ["Win"],
    download: "https://www.amyxun.com/",
    icon: "png",
    msg: "专业数学公式编辑排版工具",
  },
  {
    name: "MarkText",
    system: ["Win", "Mac", "Linux"],
    download: "https://marktext.weero.net/download",
    icon: "svg",
    msg: "中文化的MarkText特别版",
  },
  {
    name: "etherpad",
    system: ["Win", "Mac", "Linux"],
    download: "https://etherpad.org",
    icon: "svg",
    state: "gnu",
    msg: "开源在线协作编辑器",
  },
  {
    name: "蚂蚁笔记",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://app.leanote.com/",
    icon: "png",
    msg: "有极客范的云笔记！",
  },
  {
    name: "DokuWiki",
    system: ["Web"],
    download: "https://www.dokuwiki.org/zh:dokuwiki",
    state: "gnu",
    icon: "png",
    msg: "无需数据库的开源在线维基文档",
  },
  {
    name: "TiddlyWik",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://tiddlywiki.com/",
    state: "free",
    icon: "svg",
    msg: "无需数据库的非线性笔记本",
  },
  {
    name: "MacDown",
    system: ["Mac"],
    download: "https://macdown.uranusjr.com/",
    state: "free",
    icon: "png",
    msg: "MacOS的开源Markdown编辑器",
  },
];

const newArr = { type: "笔记", path: "note", data: data };

export default newArr;
