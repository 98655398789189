//商家
const data = [
  {
    name: "沙丁鱼星球",
    system: ["Win", "Mac"],
    download: "https://www.sdyxq.cn/",
    icon: "png",
  },
  {
    name: "千牛",
    system: ["Win", "Mac"],
    download: "https://work.taobao.com/download.html",
    icon: "png",
  },
  {
    name: "京麦",
    system: ["Win", "Mac"],
    download: "https://jm.jd.com/index/index.html",
    mark: "#028bf5",
    icon: "png",
  },
  {
    name: "拼多多",
    system: ["Win", "Mac", "Web"],
    download: "https://mms.pinduoduo.com/other/downloadapp/",
    icon: "svg",
  },
  {
    name: "抖店",
    system: ["Win", "Mac"],
    download: "https://im.jinritemai.com/",
    icon: "svg",
  },
  {
    name: "旺店通",
    system: ["Win"],
    download: "https://www.wangdian.cn/erpCompany/",
    icon: "png",
  },
  {
    name: "1688商家",
    system: ["Win", "Mac"],
    download: "https://cxt.1688.com/shangjpc.html",
    icon: "svg",
  },
  {
    name: "聚水潭",
    system: ["Web"],
    download: "https://www.erp321.com/app/support/down.html",
    icon: "png",
  },
];

const newArr = { type: "商家", path: "shop", data: data };

export default newArr;
