//转换
const data = [
  {
    name: "Webp Converter",
    download: "https://compressjpeg.com/zh/",
    
    icon: "svg",
    msg: "各种格式与webp互相转换",
    state: "free",
  },
  {
    name: "CloudConvert",
    download: "https://cloudconvert.org/",
    
    icon: "png",
    mark: "#202020",
    msg: "云端在线图片格式转换",
  },

  {
    name: "VectorMagic",
    download: "https://zh.vectormagic.com/",
    
    icon: "svg",
    msg: "收费！非常强大的位图转矢量图",
  },
];

const newArr = {
  type: "转换",
  path: "convert",
  data: data,
  describe: "各种图片格式间相互转换",
};

export default newArr;
