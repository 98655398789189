//其他
const data = [
  {
    name: "AsciiToday",
    download: "https://ascii.today/",
    icon: "png",
    msg: "生成有趣的ASCII标题",
  },
  {
    name: "Carbon",
    download: "https://carbon.now.sh/",
    icon: "ico",
    state: "free",
    msg: "分享精美源代码截图",
  },
  {
    name: "Github Gist",
    download: "https://gist.github.com",
    icon: "svg",
    state: "free",
    msg: "即时共享代码、注释和代码片段。 ",
  },
  {
    name: "BootCDN",
    download: "https://www.bootcdn.cn/index.html",
    icon: "png",
    msg: "前端开源项目 CDN 加速服务",
    state: "free",
  },
  {
    name: "Staticfile",
    download: "https://staticfile.net/",
    icon: "png",
    msg: "免费、快速、开放的 CDN 服务",
    state: "free",
  },
  {
    name: "cdnJS",
    download: "https://cdnjs.com/",
    icon: "svg",
    mark: "#343535",
    msg: "简单可靠，由 Cloudflare 提供支持",
    state: "gnu",
  },
  {
    name: "Qiuziti",
    download: "https://www.qiuziti.com/",
    icon: "png",
    mark: "#343535",
    msg: "中文上传图片查字体",
    state: "gnu",
  },
  {
    name: "FindMyfont",
    download: "http://www.myfonts.com/WhatTheFont/",
    icon: "png",
    msg: "英文上传图片查找字体",
  },
  {
    name: "stack overflow",
    download: "https://stackoverflow.com/",
    icon: "svg",
    msg: "构建编码问题答案的权威集合平台",
  },
  {
    name: "Internet Archive",
    download: "https://archive.org/",
    icon: "svg",
    msg: "互联网历史档案馆",
  },
  {
    name: "心知天气",
    download: "https://www.seniverse.com/",
    icon: "png",
    msg: "企业级高精度气象数据服务",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
