//前端开发

import Case from "@/data/page/site/frontend/case";
import Color from "@/data/page/site/frontend/color";
import Design from "@/data/page/site/frontend/design";
import Inspiration from "@/data/page/site/frontend/inspiration";
import Tool from "@/data/page/site/frontend/tool";
import ToolBox from "@/data/page/site/frontend/toolBox";
import Doc from "@/data/page/site/frontend/doc";
import Icon from "@/data/page/site/frontend/icon";
import Interactive from "@/data/page/site/frontend/interactive";
//import Api from "@/data/page/site/frontend/api";
import Framework from "@/data/page/site/frontend/framework";
import About from "@/data/page/site/frontend/about";

import { SonType } from "@/store/interface";
const Data = [
  Color, //颜色设计
  Design, //模块设计
  Interactive, //界面交互
  Case, //优秀页面
  Icon, //图标
  Inspiration, //灵感
  //Api, //Api
  Doc, //文档
  Framework, //框架
  Tool, //工具
  ToolBox, //工具箱
  About, //其他
];
export default Data as SonType[];
