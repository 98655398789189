<script setup lang="ts">
import { ref, inject, Ref } from "vue";
import menu from "@/router/data";
import ScreenSystem from "@/components/block/function/screenSystem.vue";
import LeftMobile from "@/components/block/function/left_mobile.vue";
import SvgIcon from "@/components/icon/SvgIcon.vue";
import {
  clickRouter,
  showMenuType,
  ColorSetting,
  IconData,
  isPath,
} from "@/store/tool";
//控制移动端菜单是否收起
const isMobile = ref(false);
//方法
const toggleMobile = (active: boolean) => {
  if (active) {
    //关则开，开则关
    isMobile.value = !isMobile.value;
    //展开菜单时禁止滑动
    const body = document.body;
    if (body.style.overflow === "hidden") {
      body.style.overflow = ""; // 恢复默认样式
    } else {
      body.style.overflow = "hidden"; // 设置 overflow: hidden;
    }
  } else {
    //常关 - 点击功能菜单用
    isMobile.value = false;
  }
};

/**

//拿到系统筛选
const screenSystemActive = inject("screenSystem") as Ref<string>;

//系统筛选变化时关闭菜单
watch(
  () => screenSystemActive.value,
  (_newId) => {
    isMobile.value = false;
  }
);
 */

//拿到最新路由名
const RouterName = inject("RouterName") as Ref<string>;
// 使用工具函数
const showMenuComputed = showMenuType(RouterName);

//TODO:能不能合并下
//拿到当前路由path
const RouterPath = inject("RouterPath") as Ref<{
  pagename?: string;
  screen: string;
  page?: string;
}>;

//找到当前路由的页面名称
const routeTitles = {
  home: "首页",
  search: "搜索",
  searchdata: "搜索",
  record: "历史记录",
};
import Data from "@/router/data";
import { computed } from "vue";
//页面标题
const result = computed(() => {
  //存在路由
  const pageName = RouterPath.value.pagename;
  const page = RouterPath.value.page;
  //是链接导航

  if (pageName) {
    return Data.router.find((item) => item.path === pageName);
  }
  //是页面

  if (page) {
    return Data.title.find((item) => item?.path === page);
  }

  //是单页
  const title = routeTitles[RouterName.value as keyof typeof routeTitles]; // 如果找到了对应的标题
  if (title) {
    return { title };
  }
});
</script>
<template>
  <header
    class="h-16 bg-BgHeader dark:bg-darkBgHeader shadow-sm flex items-center justify-center text-OneTitle dark:text-darkOneTitle"
  >
    <router-link to="/" class="contents" @click="toggleMobile(false)">
      <img
        class="w-6 h-6"
        src="/logo.svg"
        alt="SoFile - 专业且便捷的软件和站点导航站"
        title="SoFile - 专业且便捷的软件和站点导航站"
      />
      <p class="ml-2 text-2xl tracking-widest">SoFile</p>
    </router-link>
  </header>

  <!--移动端菜单-->
  <div class="relative dark:bg-gray-800 bg-white cursor-pointer md:hidden">
    <!--目录-->
    <div
      class="h-12 flex items-center justify-between lg:px-6 px-4 relative z-10 border-y bg-BgCardHeader dark:bg-darkBgCardHeader text-OneTitle dark:text-darkOneTitle"
    >
      <!--在不同页面，展示不同内容-->

      <!--文本-->
      <span class="text-base font-semibold dark:text-gray-200 text-black">
        {{ result?.title || "404 NotFound" }}
      </span>
      <!--系统筛选-->
      <component v-show="showMenuComputed" :is="ScreenSystem" />
      <!--菜单按钮-->
      <span :class="{ headerSpan: isMobile }" @click="toggleMobile(true)">
        <div class="header-action-button"></div>
      </span>
    </div>
    <!--移动端子菜单-->
    <section class="fixed left-0 right-0 z-20 h-full" v-show="isMobile">
      <div
        class="bg-BgCard dark:bg-darkBgCard text-TwoTitle dark:text-darkTwoTitle overflow-y-auto h-full"
      >
        <ul>
          <li
            class="h-12 flex items-center justify-between lg:px-6 px-4 border-b dark:border-darkBgCardSeparate last:border-none"
            v-for="(item, index) in menu.router"
            :key="index"
            @click="toggleMobile(true), clickRouter(item.name, item.path)"
          >
            <span class="text-sm font-black">
              {{ item.title }}
            </span>
            <!--菜单图标-->
            <div
              class="flex items-center justify-center"
              v-show="isPath(item.path)"
            >
              <SvgIcon
                class="w-6 h-6 rounded-3xl group-hover:bg-gray-400"
                :name="IconData.right"
                :color="ColorSetting.TwoTitle"
                title="菜单图标"
                alt="菜单图标"
              />
            </div>
            <!--推荐-->
            <div class="red-tag flex items-center justify-center" v-if="false">
              <span class="tag-text">推荐</span>
            </div>
          </li>
          <!--功能切换-->
          <LeftMobile :toggleMobile="toggleMobile" />
        </ul>
      </div>
    </section>
  </div>
</template>
<style scoped>
/**
* 菜单图标
 */
.header-action-button {
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.header-action-button::before {
  content: "";
  position: absolute;
  top: -0.9rem;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1.5rem;
  height: 0.2rem;
  border-top: 0.15rem solid currentColor;
  transition: 0.4s;
}

.header-action-button::after {
  content: "";
  position: absolute;
  /* top: -.9rem;*/
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1.5rem;
  height: 0.2rem;
  border-top: 0.15rem solid currentColor;
  transition: 0.4s;

  top: 0.9rem;
}

.headerSpan .header-action-button::before {
  -webkit-transform: rotate(45deg) translate(0.3rem, 0.2rem);
  transform: rotate(45deg) translate(0.3rem, 0.2rem);
  right: -2px;
}

.headerSpan .header-action-button::after {
  -webkit-transform: rotate(-45deg) translate(0.4rem, -0.3rem);
  transform: rotate(-45deg) translate(0.4rem, -0.3rem);
  right: -2px;
}
/*暗盒模式*/
.dark .header-action-button,
.dark .header-action-button::before,
.dark .header-action-button::after {
  color: #e5e7eb;
}
</style>
