//思维导图
const data = [
  {
    name: "Smallpdf",
    system: ["Web"],
    download: "https://smallpdf.com/cn",
    icon: "svg",
    msg: "专注于PDF格式互转、压缩等功能",
  },
  {
    name: "PDFcandy",
    download: "https://pdfcandy.com/",
    system: ["Web"],
    icon: "svg",
    msg: "汇集众多常用PDF在线工具",
  },
  {
    name: "iLovePDF",
    system: ["Win", "Mac", "Web"],
    download: "https://www.ilovepdf.com/zh-cn/desktop",
    icon: "svg",
    msg: "阅读和编辑PDF",
  },
  {
    name: "PDF24",
    system: ["Win", "Web"],
    download: "https://tools.pdf24.org/zh/creator",
    icon: "png",
    state: "free",
    msg: "个人开发的免费PDF工具",
  },
  {
    name: "Icecream PDF Editor",
    system: ["Win"],
    download: "https://icecreamapps.com/PDF-Editor/",
    icon: "svg",
    msg: "支持编辑的PDF阅读器",
  },
  {
    name: "PDF 补丁丁",
    system: ["Win"],
    download: "https://www.cnblogs.com/pdfpatcher/",
    icon: "svg",
    state: "gnu",
    msg: "免费且开源的编辑功能",
  },
  {
    name: "CleverPDF",
    system: ["Web"],
    download: "https://www.cleverpdf.com/cn",
    icon: "svg",
    state: "free",
    msg: "几十个强大的PDF在线工具，无需注册会员",
  },
];

const newArr = { type: "PDF", path: "pdf", data: data };

export default newArr;
