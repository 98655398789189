//清理文件
const data = [
  {
    name: "Fliqlo",
    system: ["Win", "Mac"],
    download: "https://fliqlo.com/screensaver/",
    icon: "png",
    state: "free",
    msg: "桌面时钟屏保",
  },
  {
    name: "FlipClocker",
    system: ["Web"],
    download: "https://flipclocker.com/",
    icon: "svg",
    state: "free",
    msg: "网页版时钟屏保",
  },
  {
    name: "Fliqlo",
    system: ["Web"],
    download: "https://www.douban.com/group/topic/220597184",
    icon: "png",
    state: "gnu",
    msg: "网页版时钟屏保",
  },
  {
    name: "Fliqlo",
    system: ["Web"],
    download: "https://www.douban.com/group/topic/220597184",
    icon: "png",
    state: "gnu",
    msg: "网页版时钟屏保",
  },
  {
    name: "YOU",
    system: ["Web"],
    download: "https://www.hanshiltermann.nl/",
    icon: "about",
    msg: "极简主义肖像画（有爱的，干净看着你）",
  },
  {
    name: "AsPoem",
    system: ["Web"],
    download: "https://aspoem.com/zh-Hans",
    icon: "ico",
    msg: "现代化古诗词学习",
  },
  {
    name: "古诗文网",
    msg: "古诗文经典传承",
    system: ["Web"],
    download: "https://www.gushiwen.cn/",
    icon: "ico",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
