//远程
const data = [
  {
    name: "贝锐向日葵",
    system: ["Win", "Mac", "Linux"],
    download: "https://sunlogin.oray.com/download",
    icon: "png",
  },
  {
    name: "ToDesk",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.todesk.com/download.html",
    icon: "svg",
  },
  {
    name: "Rustdesk",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.todesk.com/download.html",
    icon: "svg",
  },
];

const newArr = { type: "远程", path: "remote", data: data };

export default newArr;
