<script setup lang="ts">
/**
 * 网址导航的二级菜单
 */
import { Ref, computed, inject, ref, watchEffect } from "vue";
import { SonType } from "@/store/interface";

import TableData from "@/components/block/data/table.vue";
import BlockData from "@/components/block/data/block.vue";
import NoData from "@/components/block/noData.vue";

import { screenWidth, ColorSetting } from "@/store/tool";
defineProps<{
  screenArr: { type: string; path: string }[]; //二级菜单
  filteredData: SonType; //筛选后的数据
}>();

interface ScreenData {
  // screenValue: string;
  setScreenValue: (item: string) => void;
}

//二级筛选
const screenData = inject<ScreenData>("screenData");

//取出方法
const { setScreenValue } = screenData!;

//拿到当前路由path
const Router = inject("RouterPath") as Ref<{
  pagename: string;
  screen: string;
}>;

//计算二级筛选选中样式
const itemStyle = (index: number, path: string) => {
  const isItemSelected = computed(() => {
    return (
      (Router.value.screen === undefined && index === 0) ||
      Router.value.screen === path
    );
  });

  return {
    fontWeight: isItemSelected.value ? "bold" : "inherit",
    borderBottom: isItemSelected.value ? "1px solid" : "inherit",
    color: isItemSelected.value ? ColorSetting.value.pitch : "inherit",
  };
};

/**
 * 移动端
 */
//准备选择的值
const onScreenData = ref("all");

watchEffect(() => {
  //选中的筛选选项
  if (Router.value.screen) {
    onScreenData.value = Router.value.screen;
  } else {
    //若传来的值为空，则默认值为全部
    onScreenData.value = "all";
  }
});

//传递数据
const handleChange = () => {
  setScreenValue(onScreenData.value);
};
</script>
<template>
  <div v-if="screenWidth">
    <!--PC -->
    <!--二级菜单-->
    <div
      class="flex justify-start flex-wrap gap-3 lg:gap-5 container w-full"
      v-if="screenArr.length > 2"
    >
      <span class="font-bold" v-show="false"> 类型： </span>

      <button
        v-for="(item, index) in screenArr"
        :key="index"
        @click="setScreenValue(item.path)"
        :title="item.type + '筛选'"
        :style="itemStyle(index, item.path)"
      >
        {{ item.type || "其他" }}
      </button>
    </div>

    <!-- 
      子分类描述 
    -->
    <div
      class="text-ThreeTitle dark:text-darkThreeTitle text-xs h-8 flex items-center"
    >
      <span>
        {{ filteredData.describe }}
      </span>
    </div>

    <!-- 
      内容展示
    -->

    <div v-if="filteredData.data.length != 0">
      <TableData :data="filteredData.data" />
    </div>
    <!--系统筛选后无数据提示-->
    <div class="flex justify-center items-center" v-else>
      <NoData />
    </div>
  </div>
  <div v-else>
    <!--移动端-->
    <!--二级菜单-->
    <label class="block text-center" v-if="screenArr.length > 2 && screenData">
      <select
        class="select select-bordered w-full dark:text-[#797C80]"
        name="animals"
        @change="handleChange"
        v-model="onScreenData"
      >
        <option v-for="item in screenArr" :value="item.path">
          类型： {{ item.type }}
        </option>
      </select>
    </label>

    <!--内容展示-->

    <BlockData :data="filteredData.data" v-if="filteredData.data.length != 0" />
    <!--系统筛选后无数据提示-->
    <div class="flex justify-center items-center" v-else>
      <NoData />
    </div>
  </div>
</template>
