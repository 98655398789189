<script setup lang="ts">
//提示组件
export interface Props {
  content?: string;
}

//默认值
const props = withDefaults(defineProps<Props>(), {
  content: "icon",
});
</script>
<template>
  <div class="tooltip">
    ?
    <span class="tooltiptext rounded-lg" v-html="props.content"></span>
  </div>
</template>
<style scoped>
.tooltip {
  position: relative;
  display: block;
  margin: 0 auto;
  line-height: 20px;
  width: 20px;
  height: 20px;

  font-size: 12px;

  background-color: #666;
  border-radius: 50%;
  font-weight: bold;
  color: #fff;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  font-size: 12px;

  background-color: black;
  color: #fff;
  text-align: center;

  padding: 1em;
  position: absolute;
  z-index: 1;
  top: 150%;

  margin-left: -230px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* 提示工具头部 */
  left: 50%;
  margin-left: 70px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
