//其他
const data = [
  {
    name: "枝枝栗子",
    username: "枝枝栗子",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAkO3Mu7e7T9E3mnu06HmFdBMGh5rCCPRHHzzro1-gvXA",
  },
  {
    name: "小白兔(˵¯͒〰¯͒˵)",
    username: "小白兔",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAMldxSuMxqifl9aJN7qew-_2KWET7R-UmtpXr5GOK5xlrEwW_M_pCgD0DzaGVr2Iy",
  },
  {
    name: "木骨",
    username: "木骨",
    icon: "jpeg",
    msg: "木骨木骨，木有锁骨。",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAA2616Y4O-__4p4axg6hSsY5PIQhhNQEE66ZFKKPDOzoM",
  },
  {
    name: "有耳阿郁",
    username: "有耳阿郁",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAJc2VsvIkkq8E5zXoyOlJ-cidGm3jXnlSWxdCf4cnfns",
  },
  {
    name: "白居",
    username: "白居",
    icon: "jpeg",
    msg: "摄影师 不是模特 花絮和群像账号 不止汉服",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAACNRG0ZjThOOoNBOIUT9NJOJVbjSsHyFASPyYl1_JHJ37IkjTWd0viwVAGDqgL44h",
  },
  {
    name: "朴和桑",
    username: "朴和桑",
    icon: "png",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAA6YEeUTV8IbmncYPvuRswQEOX6JR2Vz3yGyPK9utm3UE",
    msg: "分享穿搭给老婆们",
  },
  {
    name: "霸波奔^_^",
    username: "霸波奔",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAHl84yqmktzpUcxtLlIqCfgI3GcFJksVf4IGyNMqYamQ",
  },
  {
    name: "小兔钥匙丢了",
    username: "小兔钥匙丢了",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAA80GNGHHlD52Uj3JQRuT2Bgm5RiFdd6M5PeDi6jxc4M",
  },
  {
    name: "陈初",
    username: "陈初",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAOIXGeD2CaqUuXwE9vA2Ndnhhd4WzbmGm_QRY5ZyfxDZdJE_ktkeAADqxt79cqPFG",
  },
  {
    name: "自然醒RR",
    username: "自然醒RR",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAWncTUiIiUb0KcZ8JanvHogyguEXjNASblQuoYfETod-xf0V4iHi6ktVBqn5Yq7yF",
  },
  {
    name: "思涵.",
    username: "思涵",
    icon: "png",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAA189Aj3ZJPjWJ__fZ_miVk9grl1qKbcJTm6CPL0_sWQeFQ5N4u1PMZNALfi3riSRt",
  },
  {
    name: "张雅倩",
    username: "张雅倩",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAXvUpygsrKt4ngRbSt91yLNSq_Bz2mTdNJdmHal3N1bQ",
  },
  {
    name: "自贡移动",
    username: "自贡移动",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAMgTVqCG6WORJdTvqB1lJtKEXrc4lQHMll9WLG-NFAD9U9f61OGKo5NcyDIlw2NK1",
  },
  {
    name: "九七困4了",
    username: "九七困4了",
    icon: "jpeg",
    download:
      "https://www.douyin.com/user/MS4wLjABAAAAOT6wGPu60FNeFZ1PFOcvTgLhOwgtbtn_wk71BUsJSyk",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
