//灵感
const data = [
  {
    name: "Awwwards",

    download: "https://www.awwwards.com/",
    icon: "svg",
    msg: "为精美及创意的设计UI颁奖站点 ",
  },
  {
    name: "Wolfwhale",

    download: "https://www.wolfwhale.com/",
    icon: "png",
    msg: "屡获殊荣的数字设计工作室",
  },
  {
    name: "设计达人",
    download: "https://www.shejidaren.com/",

    icon: "png",
    msg: "原创和手工收集国内外高质量设计资源",
  },

  {
    name: "Dribbble",
    download: "https://dribbble.com/",

    icon: "svg",
    msg: "全球UI设计师作品秀社区",
  },

  {
    name: "Behance",
    download: "https://www.behance.net/",

    icon: "png",
    msg: "Adobe旗下设计师创意视觉社区",
  },


  {
    name: "Designspiration",
    download: "https://www.designspiration.com/",

    icon: "png",
    msg: "通过颜色查找艺术、设计、徽标、摄影、等",
  },

  {
    name: "数字像素",
    download: "https://www.shuzixs.com/",

    icon: "png",
    mark: "#1d243a",
    msg: "超实用可视化设计师社区",
  },
  {
    name: "Logopond",
    download: "http://logopond.com/",

    icon: "svg",
    msg: "国外LOGO展示社区",
  },

  {
    name: "站酷",
    download: "http://www.zcool.com.cn/",

    icon: "svg",
    msg: "国内综合设计展示平台",
  },
  {
    name: "UICN",
    download: "http://www.ui.cn/",

    icon: "svg",
    msg: "UI中国用户体验平台",
  },
  {
    name: "Sketchfab",
    download: "https://sketchfab.com/",

    icon: "svg",
    msg: "全球最极致3D设计模型在线展示平台",
  },
  {
    name: "lapa",
    download: "http://lapa.ninja/",

    icon: "svg",
    msg: "优秀LandingPage落地页收集",
  },
  {
    name: "Fubiz",
    download: "http://www.fubiz.net/",

    icon: "png",
    msg: "法国每日新鲜创意灵感分享",
  },
  {
    name: "Vimeo",
    download: "https://vimeo.com/",

    icon: "svg",
    msg: "视频分享平台创意动画频道[需要翻墙]",
  },
  {
    name: "MyModernMet",
    download: "http://mymodernmet.com/",

    icon: "png",
    msg: "视觉创意和艺术爱好者网站",
  },
  {
    name: "Muzli",
    download: "http://muz.li/",

    icon: "png",
    msg: "设计灵感资源聚合",
  },

  {
    name: "Panda",
    download: "http://usepanda.com/app/",

    icon: "svg",
    msg: "设计作品和资讯文摘订阅平台",
  },
  {
    name: "花瓣网",
    download: "http://huaban.com/",

    icon: "png",
    msg: "中国美图收藏采集站",
  },
  {
    name: "FWA",
    download: "https://thefwa.com/",

    icon: "png",
    msg: "创意媒体艺术设计作品展",
  },

  {
    name: "Abduzeedo",
    download: "http://abduzeedo.com/",

    icon: "png",
    msg: "创意灵感和教程的设计博客",
  },
  {
    name: "Bestfolios",
    download: "http://bestfolios.com/",

    icon: "svg",
    msg: "产品丨界面丨插画灵感设计画廊",
  },
  {
    name: "Landbook",
    download: "https://land-book.com/",

    icon: "svg",
    msg: "精挑细选的美站收集",
  },

  {
    name: "CSSDesignAwards",
    download: "http://www.cssdesignawards.com/",

    icon: "svg",
    msg: "全球优秀CSS网页设计奖提名网",
  },
  {
    name: "InspirationGrid",
    download: "http://theinspirationgrid.com/",

    icon: "svg",
    msg: "创意灵感收录集合",
  },
  {
    name: "Ello",
    download: "https://ello.co/",

    icon: "svg",
    msg: "创意工作者网络社区和资讯",
  },
  {
    name: "云端",
    download: "https://www.yrucd.com/",

    icon: "png",
    msg: "聚合高品质设计素材与资讯",
  },
  {
    name: "素材中国",
    download: "http://www.sccnn.com/",

    icon: "gif",
    msg: "聚合高品质设计素材与资讯",
  },
  {
    name: "站长素材",
    download: "https://sc.chinaz.com/jiaoben/",
    icon: "png",
    msg: "网页特效源码",
  },
  {
    name: "视觉传达",
    download: "https://shijuechuanda.com/",
    icon: "png",
    msg: "免费素材共享平台",
  },
];

const newArr = {
  type: "灵感",
  path: "inspiration",
  data: data,
};

export default newArr;
