<script setup lang="ts">
//操作系统筛选
import { Ref,  inject } from "vue";

const dataArray = [
  {
    name: "Win",
    title: "Windows 系统",
  },
  {
    name: "Web",
    title: "网页版",
  },
  {
    name: "Mac",
    title: "Mac OS 系统",
  },

  {
    name: "Linux",
    title: "Linux 系统",
  },
  
];

//拿到系统值
const active = inject("screenSystem") as Ref<string>;

/**
 * 选中方法,添加设置信息
 *
 * @param name 系统名称
 */
const clickActive = (name: string) => {
  active.value = name; //高亮图标
  localStorage.setItem("screenSystem", JSON.stringify(name)); //存入本地
};


</script>
<template>
  <select
    v-model="active"
    @change="clickActive(active)"
    class="rounded-lg py-1 px-2 bg-transparent dark:bg-transparent  dark:border-darkBgCardSeparate"
  >
    <option
      v-for="(item, index) in dataArray"
      :key="index"
      :value="item.name"
      @click="clickActive(item.name)"
    >
      {{ item.name }}
    </option>
  </select>
</template>
<style scoped></style>

