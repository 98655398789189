const data = [
  {
    title: "Shelter",
    country:"美国",
    author: ["Porter Robinson", "Madeon"],
    time: "2016-08-12",
    language: "英语",
    type: ["电子"],
    introduce: "在梦中好好活下去 ",
    link: "https://music.douban.com/subject/26855169/",
  },
];
export default data;
