//框架
const data = [
  {
    name: "VueJs",
    download: "https://cn.vuejs.org/",
    icon: "svg",
    msg: "原子化css",
  },
  {
    name: "Element Plus",
    download: "https://www.tailwindcss.cn/docs/installation",
    icon: "svg",
    msg: "Vue 组件库",
  },
  {
    name: "React",
    download: "https://www.reactjs.cn/",
    icon: "svg",
    msg: "React 前端框架",
  },
  {
    name: "Ant Design",
    download: "https://ant-design.antgroup.com/index-cn",
    icon: "png",
    msg: "React 前端框架",
  },
  {
    name: "Tailwind CSS",
    download: "https://www.tailwindcss.cn/docs/installation",
    icon: "svg",
    msg: "原子化css",
  },
];

const newArr = {
  type: "框架",
  path: "framework",
  data: data,
  describe: "前端框架",
};

export default newArr;
