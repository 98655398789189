//硬件信息
const data = [
  {
    name: "鲁大师",
    system: ["Win"],
    download: "https://www.ludashi.com/page/pclog.php",
    mark: "#186eb2",
    icon: "png",
  },
  {
    name: "GPU-Z",
    system: ["Win"],
    download: "https://www.techpowerup.com/download/techpowerup-gpu-z/",
    icon: "png",
    state: "free",
    msg: "查看GPU信息",
  },
  {
    name: "CPU-Z",
    system: ["Win"],
    download: "https://www.cpuid.com/softwares/cpu-z.html",
    icon: "svg",
    state: "free",
    msg: "查看CPU信息",
  },
  {
    name: "HWiNFO硬件检测",
    system: ["Win"],
    download: "https://www.hwinfo.com/download/",
    mark: "#01092b",
    icon: "png",
    describe: "老牌硬件分析及监控工具",
  },

  {
    name: "CPU天梯图",
    system: ["Web"],
    download: "https://topic.expreview.com/CPU/",
    icon: "svg",
  },
  {
    name: "CPU比较",
    system: ["Web"],
    download: "https://www.cpu-monkey.com/zh-cn/",
    state: "free",
    icon: "webp",
  },
  {
    name: "GPU天梯图",
    system: ["Web"],
    download: "https://topic.expreview.com/GPU/",
    icon: "svg",
  },
  {
    name: "GPU比较",
    system: ["Web"],
    download: "https://www.gpu-monkey.com/en/",
    state: "free",
    icon: "webp",
  },
  {
    name: "苹果设备比较",
    system: ["Web"],
    download: "https://www.check-mac.com/en/",
    state: "free",
    icon: "webp",
  },
  {
    name: "图拉丁吧工具箱",
    system: ["Win"],
    download: "https://www.tbtool.cn/index.html",
    icon: "ico",
    msg: "常用硬件测试和检测工具！",
  },
  {
    name: "CpuTime",
    system: ["Web"],
    download: "https://cputime.net/",
    icon: "png",
    msg: "CPU参数对比与性能评测",
  },
  {
    name: "Versus",
    system: ["Web"],
    download: "https://versus.com/cn",
    icon: "svg",
    msg: "万物皆可对比",
    mark:"#030105",
  },
];

const newArr = { type: "硬件信息", path: "hardware", data: data };

export default newArr;
