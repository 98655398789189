//其他
const data = [
  {
    name: "Zen",
    download: "https://github.com/anfragment/zen",
    system: ["Win", "Mac", "Linux"],
    icon: "png",
    state: "gnu",
    msg: "全系统广告拦截器和隐私保护程序",
  },
  {
    name: "Focusky",
    download: "http://www.focusky.com.cn/download/",
    system: ["Win", "Mac"],
    icon: "png",
    msg: "快速创作炫酷的交互式课件",
    mark: "#333",
  },
  {
    name: "Prezi",
    download: "https://prezi.com/",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "打动观众的演讲",
  },

  {
    name: "Adobe",
    download: "https://www.ghxi.com/adobe2024.html",
    system: ["Win", "Mac"],
    icon: "win",
    msg: "第三方站点",
  },
  {
    name: "幕连",
    system: ["Win", "Mac"],
    download: "https://letsview.cn/download-letsview",
    icon: "png",
    msg: "一键投屏",
  },
  {
    name: "Quicker",
    system: ["Win"],
    download: "https://getquicker.net/Download",
    icon: "svg",
    mark: "#343a40",
    msg: "预设动作库，类似 iOS 的捷径",
  },
  {
    name: "OneQuick",
    system: ["Win"],
    download: "https://onequick.org/",
    icon: "png",
    msg: "热键快捷工具",
  },
  {
    name: "影刀",
    download: "https://www.yingdao.com/client-download/",
    system: ["Win", "Mac", "Linux"],
    icon: "svg",
    msg: "流程自动化",
  },
  {
    name: "ZoomIt",
    system: ["Win"],
    download: "https://learn.microsoft.com/zh-cn/sysinternals/downloads/zoomit",
    icon: "win",
    state: "free",
    msg: "微软放大镜",
  },

  {
    name: "Bartender 4",
    system: ["Mac"],
    download: "https://koodo.960960.xyz/zh",
    icon: "svg",
    mark: "black",
    msg: "状态栏管理",
  },
  {
    name: "超级右键",
    system: ["Mac"],
    download: "https://www.irightmouse.com/irightmouse.html",
    icon: "png",
    msg: "右键菜单工具",
  },
  {
    name: "Alfred",
    system: ["Mac"],
    download: "https://www.alfredapp.com/",
    icon: "svg",
    mark: "#5c1f87",
    msg: "通过热键、关键字、文本扩展等提高您的效率",
  },
  {
    name: "Raycast",
    system: ["Mac"],
    download: "https://www.raycast.com/",
    icon: "svg",
    msg: "Alfred 平替",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
