//思维导图
const data = [
  {
    name: "幕布",
    system: ["Win", "Mac", "Web"],
    download: "https://mubu.com/apps",
    icon: "png",
    msg: "极简大纲笔记，一键生成思维导图",
  },
  {
    name: "百度脑图",
    system: ["Web"],
    download: "https://naotu.baidu.com/",
    icon: "png",

    state: "free",
    msg: "基本够用的思维导图",
  },
  {
    name: "Xmind",
    system: ["Win", "Mac", "Linux"],
    download: "https://xmind.cn/download/",
    icon: "svg",
    msg: "全功能的思维导图和头脑风暴软件",
  },
  {
    name: "ZhiMap",
    system: ["Web"],
    download: "https://zhimap.com/",
    icon: "svg",
    mark: "#22252a",

    state: "free",
    msg: "免费思维导图",
  },

  {
    name: "Draw",
    system: ["Web"],
    download: "https://app.diagrams.net/",
    icon: "svg",
    mark: "rgb(240, 135, 5)",

    state: "free",
    msg: "在线流程图",
  },
  {
    name: "StarUML",
    system: ["Win", "Mac", "Linux"],
    download: "https://staruml.io/download/",
    icon: "png",
    msg: "UML 流程图",
  },
  {
    name: "Whiteboard",
    system: ["Win", "Web"],
    download:
      "https://www.microsoft.com/zh-cn/microsoft-365/microsoft-whiteboard/digital-whiteboard-app/",
    icon: "ico",
    msg: "微软发布的可视化协作白板",
  },
  {
    name: "Excalidraw",
    system: ["Web"],
    download: "https://excalidraw.com/",
    icon: "svg",
    state: "gnu",
    msg: "广受欢迎的在线白板工具",
  },
  {
    name: "Revezone",
    system: ["Win", "Mac", "Web"],
    download: "https://revezone.com/index.html",
    icon: "png",
    state: "gnu",
    msg: "轻量级本地优先以图形为中心的白板",
  },
];

const newArr = { type: "思维导图", path: "mindmap", data: data };

export default newArr;
