//开源项目

import CMS from "@/data/page/site/git/cms";
import BASE from "@/data/page/site/git/base";
import Doc from "@/data/page/site/git/doc";
import Brilliant from "@/data/page/site/git/brilliant";
import Group from "@/data/page/site/git/group";
import { SonType } from "@/store/interface";
const Data = [
  CMS, //内容框架
  BASE, //底座
  Doc, //文档
  Brilliant, //卓越
  Group, //小组
];
export default Data as SonType[];
