//表单
const data = [
  {
    name: "iCloud",
    
    download: "https://www.icloud.com.cn/",
    icon: "svg",
    msg: "云上贵州运营的苹果相册",
  },
  {
    name: "小米云服务",
    
    download: "https://i.mi.com",
    icon: "png",
  },
  {
    name: "vivo 云服务",
    
    download: "https://yun.vivo.com.cn",
    icon: "png",
  },
  {
    name: "OPPO 云服务",
    
    download: "https://cloud.oppo.com",
    icon: "svg",
    msg: "高效跨端的随身工作台",
  },
  {
    name: "华为云空间",
    
    download: "https://cloud.huawei.com/",
    icon: "png",
    msg: "存于云间，尽享精彩",
  },
  {
    name: "Flyme 云服务",
    
    download: "https://cloud.flyme.cn",
    icon: "png",
    msg: "存于云间，尽享精彩",
  },
];

const newArr = { type: "手机网盘", path: "form",describe:"手机上的云空间", data: data };

export default newArr;
