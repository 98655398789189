//安全
const data = [
  {
    name: "火绒安全",
    system: ["Win"],
    download: "https://www.huorong.cn/person5.html",
    icon: "svg",
  },

  {
    name: "360安全卫士",
    system: ["Win", "Mac"],
    download: "https://www.360.cn/",
    icon: "png",
  },
  {
    name: "腾讯电脑管家",
    system: ["Win", "Mac"],
    download: "https://guanjia.qq.com/page/product_download.html",
    icon: "png",
  },
  {
    name: "联想电脑管家",
    system: ["Win"],
    download: "https://guanjia.lenovo.com.cn/moreversions.html",
    icon: "png",
  },
  {
    name: "微软电脑管家",
    system: ["Win"],
    download: "https://pcmanager.microsoft.com/zh-cn",
    icon: "png",
  },
  {
    name: "McAfee",
    system: ["Win"],
    download: "https://www.mcafee.com/zh-cn/antivirus/free.html",
    icon: "svg",
  },
  {
    name: "瑞星",
    system: ["Win"],
    download: "https://mall.rising.com.cn/index.html",
    icon: "jpg",
  },
];

const newArr = { type: "安全", path: "secure", data: data };

export default newArr;
