<script setup lang="ts">
//文章模版
import {
  ref,
  watch,
  reactive,
  computed,
  provide,
  inject,
  Ref,
  watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import SoftwareData from "@/data/software/index";
import { goNotFound, shuffleArray } from "@/store/tool";
import SingleSon from "@/components/template/single_son.vue";

//准备路由
const route = useRoute();
const router = useRouter();

//拿到当前路由path
const RouterPath = inject("RouterPath") as Ref<{
  pagename: string;
  screen: string;
}>;

//拿到当前系统筛选的值
const screenSystem = inject("screenSystem") as Ref<"Win" | "Mac" | "Linux">;

//获取默认系统值
const defaultSystem = computed(() => {
  //从URL中拿到系统值
  return route.query.system;
});

//分享链接时用，监听链接 若有系统值则传入值
watch(
  () => defaultSystem.value,
  (newId) => {
    if (defaultSystem.value) {
      screenSystem.value = newId!.toString() as "Win" | "Mac" | "Linux";
    }
  }
);

//找出符合当前路由的值
const result = computed(() => {
  const data = SoftwareData.find(
    (item) => item.path === RouterPath.value.pagename
  );
  return data;
});

//传给子组件
const screenData = reactive({
  //点击传值 - 筛选用
  setScreenValue(item: string) {
    if (item !== "all") {
      //修改路由
      router.push({
        name: "screen",
        params: { screen: item },
      });
    } else {
      //点击全部，回到当前二级筛选的父级路由
      const url = RouterPath.value.pagename;
      // 命名的路由，并加上参数，让路由建立 url
      router.push({ name: "cat", params: { pagename: url } });
    }
  },
});

provide("screenData", screenData);

//存储筛选用表头
const headScreenData = ref([{ type: "", path: "" }]);

// 使用 map 方法将每个对象转换为包含 type 和 path 属性的新对象
watchEffect(() => {
  //拿到表头需要的数组对象
  const newArray = result.value?.data.map((obj) => {
    return {
      type: obj.type, //名称
      path: obj.path, //路由
    };
  });

  if (newArray) {
    //首位添加“全部”筛选选项
    newArray.unshift({ type: "全部", path: "all" });
    //传值
    headScreenData.value = newArray;
  } else {
    //没有找到表头数据，则跳转404
    goNotFound();
  }
});

//获取所有二级路由，组成数组
// 初始化一个空数组来存储所有的 path 值
let sitePathArray: string[] = [];

// 遍历 SoftwareData 数组中的每个对象
SoftwareData.forEach((obj) => {
  // 遍历当前对象的 data 数组
  obj.data.forEach((item) => {
    // 将每个 item 的 path 值加入到 sitePathArray 数组中
    sitePathArray.push(item.path);
  });
});

// 计算属性：返回按类型筛选和系统筛选后的数据列表
const filteredData = computed(() => {
  //拿到二级筛选菜单
  const pathScreen = RouterPath.value.screen;

  //存在二级筛选
  //二级筛选菜单数组中的
  //有匹配当前路径值的
  const screenDataType =
    pathScreen && sitePathArray.includes(pathScreen) && result.value;
  if (screenDataType) {
    //拿到二级筛选后的数据
    const screenData = result.value.data.find((obj) => obj.path === pathScreen);

    //不存在二级筛选后的数据，给出默认值
    if (!screenData) {
      return undefined;
    }

    //系统类型筛选
    const systemScreenData = screenData.data.filter((item) =>
      item.system
        ? item.system?.includes(screenSystem.value)
        : screenSystem.value
    );

    //没有筛选出，给默认值
    if (!systemScreenData) {
      return undefined;
    }

    //按格式存储数据TODO:好奇怪，为啥要构造的，才能正确的在切换系统时更新数据
    const datas = {
      data: systemScreenData,
      type: screenData.type,
      path: screenData.path,
      describe: screenData.describe,
    };

    return datas;
  }
  //全部
  //将所有当前父分类下的值进行合并
  const newArray = result.value?.data.flatMap((obj) => obj.data);

  //系统类型筛选
  const systemScreenData = newArray?.filter((item) =>
    item.system ? item.system?.includes(screenSystem.value) : screenSystem.value
  );

  //如果没有值，则输出默认值
  if (!systemScreenData) {
    return undefined;
  }

  //洗牌算法，随机数组
  const shuffData = shuffleArray(systemScreenData);

  //按格式存储数据
  const data = {
    data: shuffData,
    type: "全部",
    path: "all",
  };
  return data;
});

//监听，跳转至404页面
watchEffect(() => {
  //拿到当前的path
  const path = RouterPath.value.screen;

  //确定页面在二级菜单并且不在二级菜单数组中
  const type = path && !sitePathArray.includes(path);

  //或者，监听headScreenData，没有值也跳转
  if (type || !filteredData) {
    //跳转 404 页面
    goNotFound();
  }
});
</script>
<template>
  <!--展示引导列表-->
  <SingleSon
    :screenArr="headScreenData"
    :filteredData="filteredData"
    v-if="filteredData"
  />
</template>
