<script setup lang="ts">
//用户中心页
import { ref } from "vue";
import { DataType } from "@/store/interface";
import TableData from "@/components/block/data/table.vue";
import BlockData from "@/components/block/data/block.vue";
import { screenWidth } from "@/store/tool";
import Triangle from "@/components/block/garnish/triangle.vue";

// 从 localStorage 中获取 HistoryData 数据
const savedataString = localStorage.getItem("HistoryData");

//存储获取数据
const saveData = ref<DataType[]>([]);

//对象在数组中按num大小排序
const sortSoftwareDataByNum = (data: DataType[]): DataType[] => {
  // 根据 num 大小降序排序
  const sortedData = [...data].sort((a, b) => b.num! - a.num!);

  return sortedData;
};

if (savedataString) {
  // 解析 HistoryData 字符串为对象数组
  const data = JSON.parse(savedataString);
  saveData.value = sortSoftwareDataByNum(data);
}

//监听本地数据变化

//删除历史记录
const removeHistory = () => {
  // 弹出确认框，询问用户是否确定删除
  if (window.confirm("确认要清空全部历史记录吗？")) {
    localStorage.removeItem("HistoryData");
    saveData.value = []; // 清空数组
  }
};
</script>

<template>
  <div class="pb-20" v-if="saveData.length > 0">
    <TableData :data="saveData" v-if="screenWidth" />
    <BlockData :data="saveData" v-else />
    <button
      type="button"
      class="absolute bottom-4 py-2 px-4 flex justify-center items-center dark:bg-darkBgButton bg-BgButton dark:text-darkColorButton text-ColorButton hover:opacity-90   w-36 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
      @click="removeHistory"
    >
      清空历史记录
    </button>
  </div>
  <div class="block justify-center items-center" v-else>
    <Triangle class="w-72 h-72" />
    <p class="text-center dark:text-gray-100 text-gray-900 text-sm sm:text-lg">
      目前还没有历史记录，待会再来看看吧
    </p>
  </div>
</template>
