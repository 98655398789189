<script setup lang="ts">
import Icon from "@/components/icon/Icon.vue";
import data from "@/data/function/home";
import Data from "@/data/software/index";
import { PageSiteData } from "@/data/page/summary";
import SvgIcon from "@/components/icon/SvgIcon.vue";
import { ColorSetting, IconData } from "@/store/tool";

//所有软件或站点数据
const AllData = [...Data, ...PageSiteData];

//总收录数量
let countData = 0;
//计算总收录数量
const countNestedObjects = (arr: any[]) => {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if (Array.isArray(obj.data)) {
      // countData++; // 每次遍历到 CategoryType 对象时计数加一
      countNestedObjects(obj.data);
    } else {
      countData++;
    }
  }
  return countData;
};

//开始计算
const counts = countNestedObjects(AllData);

//发邮件
const sendEmail = () => {
  // 例如，可以使用 window.open() 打开邮件客户端，并填写预设的收件人、主题和正文
  const email = encodeURIComponent("link@baimu.org");
  const title = encodeURIComponent("关于 SoFile 的反馈");
  const content = encodeURIComponent(
    "在使用贵站时，我有一些好的建议，如下，还望采纳"
  );
  window.open(`mailto:${email}?subject=${title}&body=${content}`);
};
</script>
<template>
  <div class="p-4 flex justify-center items-center mt-5">
    <div
      class="flex-col flex justify-center items-center w-[25%]"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="flex-shrink-0">
        <a
          :href="item.download"
          class="relative block"
          target="_blank"
          :aria-label="'跳转至' + item.name"
        >
          <Icon :data="item" class="max-h-14 w-12" />
        </a>
      </div>
      <div class="mt-2 text-center flex flex-col">
        <span class="text-xs">{{ item.name }} </span>
      </div>
    </div>
  </div>

  <div
    class="relative overflow-hidden bg-white rounded-lg shadow lg:w-72 md:w-60 mx-auto mt-16 w-full"
  >
    <SvgIcon
      class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-5"
      :name="IconData.home"
      :color="ColorSetting.logo"
      alt="LOGO"
      title="LOGO"
    />
    <div class="px-4 py-5 md:p-6 dark:bg-darkBgTable bg-BgTable">
      <dl>
        <dt class="text-sm font-medium leading-5 truncate">
          目前共收录软件或网址
        </dt>
        <dd class="mt-1 text-3xl font-semibold leading-9">{{ counts }} 款</dd>
        <dd class="font-semibold">
          <span class="text-xs">
            <button
              @click="sendEmail()"
              type="button"
              class="py-2 px-3 transition ease-in duration-200 text-center text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-5 bg-BgButton dark:bg-darkBgButton text-ColorButton dark:text-darkColorButton hover:opacity-90"
            >
              我有建议
              <SvgIcon
                class="inline w-4 h-4 ml-2"
                :name="IconData.email"
                :color="ColorSetting.ColorButton"
                alt="邮件"
                title="邮件"
              />
            </button>
          </span>
        </dd>
      </dl>
    </div>
  </div>
</template>
<style scoped>
</style>
