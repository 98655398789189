<script setup lang="ts">
//页面模版
import { ref, Ref, computed, inject, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { PageData, PagePathArray } from "@/router/page/index";
import NotFound from "@/page/function/notFound.vue";
import { goNotFound, ColorSetting } from "@/store/tool";

//拿到当前路由path
const Router = inject("RouterPath") as Ref<{
  page: string;
  type: string;
}>;
//找出符合当前路由的菜单值
const result = computed(() => {
  const data = PageData.find((item) => item.path === Router.value.page);
  return data;
});

//找出当前路由的页面值
//准备类型
interface BlackDataType {
  title: string; //标题
  path: string; //路由
  describe: string; //描述
  component: any; //页面组件
}

const blackData = computed(() => {
  return result.value?.data.find(
    (item) => item.path === Router.value.type
  ) as BlackDataType;
});

//准备路由
const router = useRouter();

//设置路由
const setRouter = (path: string) => {
  router.push({
    name: "pagetype",
    params: {
      type: path,
    },
  });
};

//拿到当前路由名称
type RouterPathType = {
  type: string;
};
const RouterPath = inject("RouterPath") as Ref<RouterPathType>;

//一级页面打开状态
const pageType = ref(false);
const RouterName = inject("RouterName") as Ref<string>;
//计算样式
const itemStyle = (path: string) => {
  //一级页面状态
  if (RouterName.value === "page") {
    pageType.value = true;
  } else {
    pageType.value = false;
  }

  const isItemSelected = computed(() => {
    const type = RouterPath.value.type === path;
    return type;
  });

  return {
    fontWeight: isItemSelected.value ? "bold" : "inherit",
    borderBottom: isItemSelected.value ? "1px solid" : "inherit",
    color: isItemSelected.value ? ColorSetting.value.pitch : "inherit",
  };
};

// 在 blackData 中查找对应的组件配置
const currentComponent = computed(() => {
  return blackData.value?.component;
});

//监听path,不在指定范围的，跳转至404页面
watchEffect(() => {
  //拿到当前的path
  const path = RouterPath.value.type;
  //确定是页面二级菜单
  if (path) {
    if (PagePathArray.includes(path)) {
    } else {
      //跳转 404 页面
      goNotFound();
    }
  }
});
</script>
<template>
  <div v-if="result">
    <div
      class="flex justify-start flex-wrap gap-3 lg:gap-5 container text-TwoTitle dark:text-darkTwoTitle"
    >
      <span> 页面：</span>

      <button
        v-for="(item, index) in result.data"
        :key="index"
        @click="setRouter(item.path)"
        :title="item.title + '页面'"
        :style="itemStyle(item.path)"
      >
        {{ item.title }}
      </button>
    </div>
    <!--页面描述-->
    <div
      class="text-ThreeTitle dark:text-darkThreeTitle text-xs h-8 flex items-center"
    >
      {{ pageType ? "选择需要的页面内容" : blackData?.describe }}
    </div>

    <component :is="currentComponent" v-bind="$props"></component>
  </div>
  <!--没有数据时展示404内容-->
  <div v-else><NotFound /></div>
</template>
