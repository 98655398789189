//视频
const data = [
  {
    name: "腾讯视频",
    system: ["Win", "Mac", "Web"],
    download: "https://v.qq.com/download.html",
    icon: "svg",
  },
  {
    name: "爱奇艺",
    system: ["Win", "Mac", "Web"],
    download: "https://www.iqiyi.com/appstore.html",
    icon: "png",
  },
  {
    name: "优酷",
    system: ["Win", "Mac", "Web"],
    download: "https://youku.com/product/index",
    icon: "svg",
  },
  {
    name: "哔哩哔哩",
    system: ["Win", "Mac", "Web"],
    download: "https://app.bilibili.com/",
    icon: "svg",
    msg: "弹幕视频网站",
  },
  {
    name: "PotPlayer",
    system: ["Win"],
    download: "http://potplayer.tv/?lang=zh_CN",
    icon: "jpg",
    state: "free",
    msg: "视频播放器",
  },

  {
    name: "迅雷影音",
    system: ["Win", "Mac"],
    download: "https://video.xunlei.com/pc.html",
    icon: "svg",
    state: "free",
    msg: "视频播放器",
  },
  {
    name: "IINA",
    system: ["Mac"],
    download: "https://iina.io/",
    icon: "svg",
    state: "gnu",
    msg: "视频播放器",
  },
  {
    name: "恒星播放器",
    system: ["Win", "Mac"],
    download: "https://www.stellarplayer.com/",
    icon: "webp",
    state: "free",
    msg: "视频播放器",
  },
  {
    name: "暴风影音",
    system: ["Win", "Mac", "Web"],
    download: "http://www.baofeng.com/download",
    icon: "svg",
    msg: "视频播放器",
  },
  {
    name: "VLC",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.videolan.org/vlc/",
    icon: "svg",
    state: "gnu",
    msg: "开源视频播放器",
  },
  {
    name: "ZY Player",
    system: ["Win", "Mac", "Linux"],
    download: "http://zyplayer.fun/",
    icon: "png",
    state: "gnu",
    msg: "跨平台视频资源播放器",
  },
  {
    name: "Code Guide",
    system: ["Win"],
    download: "https://www.codecguide.com/",
    icon: "png",
    msg: "K-Lite 编解码器包,",
  },
  {
    name: "抖音",
    system: ["Win", "Mac","Web"],
    download: "https://www.douyin.com/",
    icon: "svg",
    msg: "短视频平台",
  },
  {
    name: "快手",
    system: ["Web"],
    download: "https://www.kuaishou.cn/",
    icon: "svg",
    msg: "短视频平台",
  },
  
];

const newArr = { type: "视频", path: "video", data: data };

export default newArr;
