const data = [
  {
    title: "本杰明·巴顿奇事",
    author: ["大卫·芬奇"],
    time: "2008-12-25",
    country: "美国",
    type: ["剧情", "爱情", "奇幻"],
    number:"tt0421715",
    link: "https://movie.douban.com/subject/1485260/",
    introduce:
      "每个人在某种程度上都对自己有不同的认识，但是我们最后都会去往同一个地方。只是走的路不同罢了。你也有属于你的道路。",
  },
];
export default data;
