//导入Vue Router模块中的两个方法
import { createRouter, createWebHistory } from "vue-router";
// 1. 定义路由组件.
import Home from "@/page/function/home.vue";
import Single from "@/components/template/single.vue";
import Page from "@/components/template/page.vue";
import Search from "@/page/function/search.vue";
import Record from "@/page/function/record.vue";
import NotFound from "@/page/function/notFound.vue";

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  //首页
  { path: "/", name: "home", component: Home },

  //历史记录
  { path: "/record", name: "record", component: Record },

  //分类
  {
    path: "/:pagename",
    name: "cat",
    component: Single,
    children: [
      {
        path: "/:pagename/:screen",
        name: "screen",
        component: Single,
      },
    ],
  },

  //页面
  {
    path: "/page/:page",
    name: "page",
    component: Page,
    children: [
      {
        path: "/page/:page/:type",
        name: "pagetype",
        component: Page,
        children: [
          {
            path: "/page/:page/:type/:screen",
            name: "pagescreen",
            component: Page,
          },
        ],
      },
    ],
  },
  //搜索
  {
    path: "/search",
    name: "search",
    component: Search,
    children: [
      {
        path: ":searchdata",
        name: "searchdata",
        component: Search,
      },
    ],
  },

  //404
  { path: "/notfound", name: "notFound", component: NotFound },

  { path: "/:pathMatch(.*)*", redirect: "/notfound" }, // 将所有未匹配到的路径重定向到 /notfound
];

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
export default router;
