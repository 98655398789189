//输入法
const data = [
  {
    name: "迅雷",
    system: ["Win", "Mac"],
    download: "https://pc.xunlei.com/",
    icon: "png",
  },
  {
    name: "Motrix",
    system: ["Win", "Mac", "Linux"],
    download: "https://motrix.app/zh-CN/download",
    icon: "png",
    state: "gnu",
    msg: "全能的下载工具",
  },
  {
    name: "FDM",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.freedownloadmanager.org/zh/download.htm",
    icon: "svg",
    state: "gnu",
  },
  {
    name: "IDM",
    system: ["Win"],
    download: "https://www.internetdownloadmanager.com/download.html",
    icon: "png",
    msg: "知名付费下载软件",
  },
  {
    name: "VDown",
    system: ["Mac"],
    download: "https://vdownapp.com/index.html",
    icon: "png",
    state: "free",
    msg: "在线视频下载",
  },
  {
    name: "Downie",
    system: ["Mac"],
    download: "https://www.downie.cn/",
    mark: "#030000",
    icon: "png",
    msg: "在线视频下载",
  },

  {
    name: "稞麦",
    system: ["Win", "Mac"],
    download: "https://www.xmlbar.net/client.php",
    icon: "png",
    msg: "视频下载（有广告）",
  },
  {
    name: "Neat Download Manager",
    system: ["Win", "Mac"],
    download: "https://www.neatdownloadmanager.com/index.php/en/",
    icon: "png",
    msg: "轻量小巧的多线程下载工具",
    state: "free",
  },
];

const newArr = { type: "下载", path: "download", data: data };

export default newArr;
