//清理文件
const data = [
  {
    name: "QQ音乐",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://y.qq.com/download/download.html",
    icon: "svg",
  },
  {
    name: "网易云音乐",
    system: ["Win", "Mac", "Web"],
    download: "https://music.163.com/#/download",
    icon: "svg",
  },
  {
    name: "酷狗音乐",
    system: ["Win", "Mac", "Web"],
    download: "https://download.kugou.com/",
    icon: "svg",
    msg: "酷狗音乐，就是歌多！",
  },
  {
    name: "酷我音乐",
    system: ["Win", "Mac", "Web"],
    download: "https://kuwo.cn/down",
    icon: "svg",
    msg: "无损音质正版在线试听网站",
  },

  {
    name: "汽水音乐",
    system: ["Win", "Mac"],
    download: "https://www.qishui.com/download",
    icon: "svg",
    msg: "抖音旗下音乐软件",
  },
  {
    name: "Foobar2000",
    system: ["Win", "Mac"],
    download: "https://www.foobar2000.org/download",
    icon: "jpeg",
    state: "gnu",
    msg: "音乐播放器",
  },
  {
    name: "Listen 1",
    system: ["Win", "Mac", "Linux"],
    download: "https://listen1.github.io/listen1/",
    icon: "git",
    state: "gnu",
    msg: "播放多平台音乐",
  },
];

const newArr = { type: "音乐", path: "music", data: data };

export default newArr;
