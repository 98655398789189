//文件管理

import ShareLan from "@/data/software/file/shareLan";
import ShareWan from "@/data/software/file/shareWan";
import Clear from "@/data/software/file/clear";
import Manage from "@/data/software/file/manage";
import Sync from "@/data/software/file/sync";
import Pdf from "@/data/software/file/pdf";
import Convert from "@/data/software/file/convert";
import NetDisk from "@/data/software/file/netDisk";
import Unzip from "@/data/software/file/unzip";
import Download from "@/data/software/file/download";
import About from "@/data/page/site/life/about";
const Data = [
  ShareLan, //内网分享
  ShareWan, //外网分享
  Clear, //清理文件
  Manage, //管理文件
  Sync, //同步文件
  Pdf, //PDF管理
  Convert, //格式转换
  Unzip, // 解压
  NetDisk, // 网盘
  Download, // 下载
  About, ///其他
];
export default Data;
