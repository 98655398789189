//各类BIOS快捷方式表格

//组装
const assemble = [
  {
    brand: "华硕/ASUS", //品牌
    bios: "Del",
    firing: "F8", //启动
  },
  {
    brand: "技嘉/Gigabyte",
    bios: "Del",
    firing: "F12",
  },
  {
    brand: "技嘉/AORUS",
    bios: "Del",
    firing: "F12",
  },
  {
    brand: "华擎/ASRock",
    bios: "Del/F2",
    firing: "F12",
  },
  {
    brand: "微星/MSI",
    bios: "Del",
    firing: "F11",
  },
  {
    brand: "映泰/Biostar",
    bios: "Del",
    firing: "F9",
  },
  {
    brand: "梅捷/SOYO",
    bios: "Del",
    firing: "ESC/F12",
  },
  {
    brand: "七彩虹/Colorful",
    bios: "Del",
    firing: "ESC/F11",
  },
  {
    brand: "昂达/Onda",
    bios: "Del",
    firing: "F11",
  },
  {
    brand: "顶星/Topstar",
    bios: "Del",
    firing: "F11/F12",
  },
  {
    brand: "玩家国度/ROG",
    bios: "Del",
    firing: "F8",
  },
  {
    brand: "铭瑄/Maxsun",
    bios: "ESC",
    firing: "F11/F12",
  },
  {
    brand: "影驰/GALAXY",
    bios: "Del",
    firing: "F12",
  },
  {
    brand: "精英/EliteGroup",
    bios: "Del",
    firing: "ESC/F11",
  },
  {
    brand: "超微/SuperMicr",
    bios: "Del",
    firing: "-",
  },
  {
    brand: "劲鲨/ingsha",
    bios: "Del",
    firing: "-",
  },
  {
    brand: "华南/Huananzhi",
    bios: "Del",
    firing: "-",
  },
  {
    brand: "泰安/TYAN",
    bios: "Del",
    firing: "-",
  },
];

//品牌笔记本
const loptop = [
  {
    brand: "华硕/ASUS",
    bios: "F2",
    firing: "ESC",
  },
  {
    brand: "联想/Lenovo",
    bios: "F2/Fn+F2",
    firing: "F12",
  },
  {
    brand: "戴尔/Dell",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "惠普/HP",
    bios: "F10",
    firing: "F9",
  },
  {
    brand: "微软/Surface",
    bios: "power+vol up ",
    firing: "power+vol down",
  },
  {
    brand: "联想/ThinkPad",
    bios: "Del",
    firing: "F12",
  },
  {
    brand: "华为/Huawei",
    bios: "Fn+F12",
    firing: "F12",
  },
  {
    brand: "小米/xiaomi",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "宏碁/Acer",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "雷神/Thunderobot",
    bios: "F2",
    firing: "F7",
  },
  {
    brand: "机械革命/Mechrevo",
    bios: "F2",
    firing: "F10",
  },
  {
    brand: "三星/Samsung",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "神舟/HASEE",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "LG",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "VAIO",
    bios: "F2",
    firing: "ESC/F11",
  },
  {
    brand: "雷蛇/Razer",
    bios: "Del/F1",
    firing: "F12",
  },
  {
    brand: "华为/Honor",
    bios: "Fn+F12",
    firing: "F12",
  },
  {
    brand: "海尔/Hair",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "微星/MSI",
    bios: "Del",
    firing: "F11",
  },
  {
    brand: "外星人/Alienware",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "华硕/ROG",
    bios: "F2",
    firing: "ESC",
  },
  {
    brand: "机械师/MACHENIKE",
    bios: "F2",
    firing: "F7",
  },
  {
    brand: "东芝/TOSHIBA",
    bios: "ESC",
    firing: "F12",
  },
  {
    brand: "YEPO",
    bios: "ESC",
    firing: "-",
  },
];

//品牌台式
const desktop = [
  {
    brand: "华硕/ASUS",
    bios: "Del",
    firing: "F8",
  },
  {
    brand: "联想/Lenovo",
    bios: "Del",
    firing: "F12",
  },
  {
    brand: "戴尔/Dell",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "惠普/HP",
    bios: "F10",
    firing: "ESC",
  },
  {
    brand: "宏碁/Acer",
    bios: "F2",
    firing: "F12",
  },
  {
    brand: "神舟/HASEE",
    bios: "F2",
    firing: "ESC",
  },
  {
    brand: "海尔/Hair",
    bios: "Del",
    firing: "F12",
  },
];
const MenuData = [
  {
    title: "组装",
    data: assemble,
  },
  {
    title: "品牌笔记本",
    data: loptop,
  },
  {
    title: "品牌台式",
    data: desktop,
  },
];

export default MenuData;
