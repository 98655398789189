//其他
const data = [
  {
    name: "图文海报生成器",
    download: "https://m.wenanwang.com/haibao/",
    icon: "about",
  },
  {
    name: "PhotoFun",
    download: "https://www.photofun.cn/",
    system: ["Web"],
    icon: "png",
    state: "free",
    msg: "本地图片编辑处理和压缩",
  },
  {
    name: "简单封面",
    download: "https://www.jiandan.link/",
    system: ["Web"],
    icon: "jpeg",
    state: "free",
    msg: "本地图片编辑处理和压缩",
  },
  {
    name: "Remove",
    download: "https://www.remove.bg/zh",
    system: ["Web"],
    icon: "svg",
    msg: "在线抠图",
  },
  {
    name: "Vectr",
    download: "https://vectr.com/",
    system: ["Web"],
    mark: "#0663dc",
    icon: "svg",
    msg: "在线编辑SVG",
  },
  {
    name: "FocoClipping",
    download: "https://www.fococlipping.com/",
    system: ["Web"],
    icon: "ico",
    msg: "在线抠图",
  },
  {
    name: "insMind",
    download: "https://www.insmind.com/",
    system: ["Web"],
    icon: "svg",
    msg: "在线照片编辑",
  },
  {
    name: "iLoveIMG",
    system: ["Web"],
    download: "https://www.iloveimg.com/zh-cn/",
    icon: "svg",
    msg: "批量编辑图片",
  },
];

const newArr = { type: "编辑", path: "edit", data: data };

export default newArr;
