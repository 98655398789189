//首页用数据
const data = [
  {
    name: "钉钉",
    system: ["Win"] as ("Win" | "Mac" | "Linux")[],
    type: "首页",
    download:
      "https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownload#/",
    icon: "svg",
  },
  {
    name: "微信",
    system: ["Win"] as ("Win" | "Mac" | "Linux")[],
    type: "首页",
    download: "https://weixin.qq.com/",
    icon: "svg",
  },
  {
    name: "企业微信",
    system: ["Win"] as ("Win" | "Mac" | "Linux")[],
    type: "首页",
    download: "https://work.weixin.qq.com/#indexDownload",
    icon: "svg",
  },
  {
    name: "金山文档",
    system: ["Win"] as ("Win" | "Mac" | "Linux")[],
    type: "首页",
    download: "https://www.kdocs.cn/",
    icon: "svg",
  },
];

export default data;
