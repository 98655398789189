//新媒体
const data = [
  {
    name: "易媒助手",
    download: "https://yimeizhushou.com/",
    icon: "png",
    msg: "新自媒体矩阵系统",
  },
];

const newArr = { type: "新媒体", path: "new-media", data: data };

export default newArr;
