//案例
const data = [
  {
    name: "Caniuse",
    download: "https://caniuse.com/",
    icon: "png",
    state: "free",
    msg: "H5标签兼容性检查",
  },
  {
    name: "小闪电",
    download: "https://jsrun.net/",
    icon: "jpg",
    msg: "专业的代码在线运行平台",
  },
  {
    name: "CodePen",
    download: "https://codepen.io/",
    icon: "svg",
    msg: "构建、测试和发现前端代码的最佳场所",
  },
  {
    name: "CodeSandbox",
    download: "https://codesandbox.io/",
    icon: "svg",
    msg: "在线进行云开发",
  },
  {
    name: "JS Bin",
    download: "https://jsbin.com/",
    icon: "svg",
    msg: "在线运行HTML、CSS和JavaScript代码",
  },
  {
    name: "Transform",
    download: "https://transform.tools/",
    mark: "#0e7ccf",
    icon: "svg",
    msg: "JSON转换",
    state: "free",
  },
  {
    name: "NameBeta",
    download: "https://namebeta.com/",
    icon: "svg",
    msg: "域名注册和IP查询",
  },
  {
    name: "IPIP",
    download: "https://www.ipip.net/",
    icon: "png",
    mark: "rgba(0,0,0,.6)",
    msg: "IP工具和服务",
  },
];

const newArr = { type: "工具", path: "tool", data: data };

export default newArr;
