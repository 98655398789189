//模块设计
const data = [
  {
    name: "ChokCoco",
    download: "https://www.cnblogs.com/coco1s",
    
    icon: "about",
    state: "free",
    msg: "CSS 各种奇技淫巧",
  },
  {
    name: "FlexItem",
    download: "https://lokeshdhakar.com/projects/flexitem/",
    
    icon: "png",
    
    msg: "CSS flexbox 布局快速参考。",
  },
  {
    name: "Grid by Example",
    download: "https://gridbyexample.com/",
    
    icon: "git",
    msg: "学习CSS Grid Layout 网格布局所需的一切",
    state: "free",
  },
  {
    name: "Neumorphism",
    download: "https://neumorphism.io",
    
    icon: "png",
    state: "gnu",
    msg: "可视化生成模态按钮",
  },
  {
    name: "Css Glass",
    download: "https://css.glass/",
    
    icon: "ico",
    state: "gnu",
    msg: "创建CSS玻璃效果",
  },
  {
    name: "Checklist Design",
    download: "https://www.checklist.design/",
    
    icon: "svg",
    
    msg: "各种检查表等表单设计集合",
  },
  {
    name: "酷背景",
    download: "https://coolbackgrounds.io/",
    
    icon: "svg",
    state: "free",
    msg: "在线选择颜色生成壁纸",
  },
  {
    name: "Uiverse",
    download: "https://uiverse.io/",
    
    icon: "svg",
    state: "free",
    msg: "任何WEB项目的开源UI元素",
    mark: "#171717",
    text: "科学",
  },
  {
    name: "itmeo",
    download: "https://itmeo.com/",
    
    icon: "png",
    
    msg: "提供各种现成模版组件",
  },
  {
    name: "Lightbox",
    download: "https://lokeshdhakar.com/projects/lightbox2/",
    
    icon: "git",
    state: "gnu",
    msg: "在页面遮罩上展示图片",
  },
  {
    name: "Haikei",
    download: "https://app.haikei.app/",
    
    icon: "svg",
    msg: "SVG背景生成器",

    mark: "rgb(36, 36, 36)",
  },
  {
    name: "HTML5 UP",
    download: "https://html5up.net/",
    
    icon: "png",
    state: "free",
    msg: "现代响应式模版",
  },
  {
    name: "HTML5 Tricks",
    download: "https://www.html5tricks.com/",
    
    icon: "about",
    state: "free",
    msg: "丰富的现代化模块组件分享",
  },
];

const newArr = {
  type: "模块",
  path: "design",
  data: data,
  describe: "前端模块设计参考",
};

export default newArr;
