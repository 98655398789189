//美女

import About from "@/data/page/see/beauty/about";
import { SonType } from "@/store/interface";
const Data = [
  About, //其他
];
export default Data as SonType[];

/**
  {
    name: "",
    username: "",
    icon: "jpeg",
    download: "",
  },
 */
