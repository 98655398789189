//占位符
const data = [
  {
    name: "Lorem Picsum",
    download: "https://picsum.photos/",

    icon: "svg",
    state: "free",
    msg: "易于使用，时尚的占位符",
  },
  {
    name: "Fake Img",
    download: "https://fakeimg.pl/",

    icon: "about",
    state: "free",
    msg: "自定义尺寸、颜色、内容甚至字体",
  },
  {
    name: "Unsplash",
    download: "https://unsplash.com/documentation",

    icon: "svg",
    msg: "专业的图片API",
  },

  {
    name: "DDIG",
    download: "https://dummyimage.com",
    icon: "about",
    state: "free",
    msg: "自定义尺寸、背景色、文本、文本色",
  },

  {
    name: "Loremflickr",
    download: "https://loremflickr.com/",

    icon: "png",
    msg: "为网络和打印提供免费占位符图像的服务",
  },
  {
    name: "Dummy Image Generator",
    download: "http://dummy-image-generator.com/",
    icon: "about",
    state: "free",
    msg: "下载占位符图像以满足布局需要。",
  },
  {
    name: "placeholder",
    download: "https://via.placeholder.com/200x200",

    icon: "about",
    state: "free",
    msg: "修改URL参数即得指定尺寸占位图",
  },
  {
    name: "placekitten",
    download: "https://placekitten.com/",

    icon: "png",
    state: "free",
    msg: "小猫占位图",
  },
  {
    name: "placebear",
    download: "https://placebear.com/",

    icon: "png",
    state: "free",
    msg: "熊熊占位图",
  },
];

const newArr = {
  type: "占位图",
  path: "occupy",
  describe: "插入占位图方便预览最终效果",
  data: data,
};

export default newArr;
