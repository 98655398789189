//搜索引擎
const data = [
  {
    name: "百度搜索",
    system: ["Web"],
    download: "https://www.baidu.com/",
    icon: "svg",
  },
  {
    name: "必应搜索",
    system: ["Web"],
    download: "https://cn.bing.com/",
    icon: "svg",
  },
  {
    name: "搜狗搜索",
    system: ["Web"],
    download: "https://www.sogou.com/",
    icon: "svg",
  },
  {
    name: "360 搜索",
    system: ["Web"],
    download: "https://www.so.com/",
    icon: "svg",
  },
  {
    name: "谷歌搜索",
    system: ["Web"],
    download: "https://www.google.com/",
    icon: "svg",
    text: "科学",
  },
  {
    name: "Yandex",
    system: ["Web"],
    download: "https://ya.ru/",
    icon: "svg",
    msg: "俄罗斯快速搜索",
  },
  {
    name: "非凡搜索",
    system: ["Web"],
    download: "https://ffsou.com/",
    icon: "png",
  },
  {
    name: "Backdata",
    system: ["Web"],
    download: "https://backdata.net/",
    icon: "ico",
  },
];

const newArr = { type: "搜索", path: "search", data: data };

export default newArr;
