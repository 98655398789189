<script setup lang="ts">
//网站导航页面
import {
  ref,
  reactive,
  computed,
  provide,
  inject,
  Ref,
  watchEffect,
} from "vue";
import SingleSon from "@/components/template/single_son.vue";
import { goNotFound, shuffleArray } from "@/store/tool";
import { useRouter } from "vue-router";
import { SonType } from "@/store/interface";
const props = defineProps<{
  SiteData: SonType[]; //二级菜单
}>();

const router = useRouter();
//传给子组件
const screenData = reactive({
  //点击传值 - 筛选用
  setScreenValue(item: string) {
    if (item !== "all") {
      //修改路由
      router.push({
        name: "pagescreen",
        params: { screen: item },
      });
    } else {
      //点击全部，回到当前二级路由
      const url = RouterPath.value.type;
      // 命名的路由，并加上参数，让路由建立 url
      router.push({ name: "pagetype", params: { type: url } });
    }
  },
});

provide("screenData", screenData);

//存储筛选用表头
const headScreenData = ref([{ type: "", path: "" }]);

//拿到当前路由path
const RouterPath = inject("RouterPath") as Ref<{
  page: string; //一级菜单
  type: string; //二级菜单
  screen: string; //三级菜单
}>;

// 使用 map 方法将每个对象转换为包含 type 和 path 属性的新对象
watchEffect(() => {
  //拿到表头需要的数组对象
  const newArray = props.SiteData.map((obj) => {
    return {
      type: obj.type, //名称
      path: obj.path, //路由
    };
  });

  //三级路由
  const routerScreen = RouterPath.value.screen;

  if (newArray) {
    //首位添加“全部”筛选选项
    newArray.unshift({ type: "全部", path: "all" });
    //传值
    headScreenData.value = newArray;

    //获取三级菜单数组
    const threeMenuArray = newArray.flatMap((obj) => obj.path);
    //监听当前的三级路由是否在指定数组中
    //存在三级筛选

    if (routerScreen && !threeMenuArray.includes(routerScreen)) {
      //不存在三级筛选，且不在指定范围内，跳转404
      goNotFound();
    }
  } else {
    //没有找到表头数据，则跳转404
    goNotFound();
  }
});

//获取所有二级路由，组成数组
// 初始化一个空数组来存储所有的 path 值
let sitePathArray: string[] = [];

// 遍历 SiteData 数组中的每个对象
sitePathArray = props.SiteData.flatMap((obj) => obj.path); // 提取每个 item 的 path，并展平为一个一维数组

// 计算属性：返回按类型筛选和系统筛选后的数据列表
const filteredData = computed(() => {
  //拿到三级筛选菜单
  const pathScreen = RouterPath.value.screen;

  //存在二级筛选
  //二级筛选菜单数组中的
  //有匹配当前路径值的
  const screenDataType =
    pathScreen && sitePathArray.includes(pathScreen) && props.SiteData;
  if (screenDataType) {
    //拿到二级筛选后的数据
    const screenData = props.SiteData.find((obj) => obj.path === pathScreen);

    //不存在二级筛选后的数据，给出默认值
    if (!screenData) {
      return undefined;
    }

    return screenData;
  }
  //全部
  //将所有当前父分类下的值进行合并
  const newArray = props.SiteData?.flatMap((obj) => obj.data);

  //洗牌算法，随机数组
  const shuffData = shuffleArray(newArray);

  //按格式存储数据
  const data = {
    data: shuffData,
    type: "全部",
    path: "all",
  };
  return data;
});
</script>

<template>
  <SingleSon
    :screenArr="headScreenData"
    :filteredData="filteredData"
    v-if="filteredData"
  />
</template>
