//案例
const data = [
  {
    name: "Koodo Reader",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://koodo.960960.xyz/zh",
    icon: "png",
    state: "gnu",
    msg: "开源免费的电子书阅读器",
  },
  {
    name: "ccReader",
    system: ["Mac"],
    download: "https://www.tominlab.com/ccreader",
    icon: "png",
    msg: "清爽易用的 TXT 电子书阅读器",
  },
  {
    name: "以方",
    system: ["Win", "Mac", "Web"],
    download: "https://ifonts.com/client/free-model",
    icon: "svg",
    msg: "iFonts字体助手",
  },
  {
    name: "字由",
    system: ["Win", "Mac"],
    download: "https://www.hellofont.cn/",
    icon: "png",
    msg: "字体管理工具",
  },
  {
    name: "字魂",
    system: ["Win", "Mac"],
    download: "https://izihun.com/client/index.html",
    icon: "svg",
    msg: "商用字体设计定制",
  },

  {
    name: "Virus Total",
    system: ["Web"],
    download: "https://www.virustotal.com",
    icon: "svg",
    msg: "分析可疑文件与网址",
  },

  {
    name: "字体天下",
    system: ["Web"],
    download: "https://www.fonts.net.cn/",
    icon: "png",
    msg: "字体免费下，在线预览",
  },
  {
    name: "问卷星",
    system: ["Web"],
    download: "https://www.wjx.cn/",
    icon: "png",
  },
  {
    name: "腾讯问卷",
    system: ["Web"],
    download: "https://wj.qq.com/",
    icon: "svg",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
