//管理文件
const data = [
  {
    name: "Everything",
    system: ["Win"],
    type: "系统",
    download: "https://www.voidtools.com/zh-cn/downloads/",
    icon: "svg",
    state: "free",
    msg: "基于文件名进行快速定位",
  },
  {
    name: "Anytxt",
    system: ["Win"],
    type: "系统",
    download: "https://anytxt.net/download/",
    icon: "png",
    state: "free",
    msg: "全文搜索",
  },
  {
    name: "QuickLook",
    system: ["Win"],
    download: "https://github.com/QL-Win/QuickLook/releases",
    icon: "png",
    state: "gnu",
    msg: "空格预览文件",
  },
  {
    name: "Seer",
    system: ["Win"],
    download: "http://1218.io/",
    icon: "svg",
    msg: "空格预览文件，可定制",
  },
  {
    name: "TextCrawler",
    msg: "立即在多个文本文件上查找和替换",
    system: ["Win"],
    icon: "png",
    download: "https://www.digitalvolcano.co.uk/textcrawler.html",
  },
  {
    name: "MT Photos",
    msg: "简单、好用的照片管理系统",
    system: ["Win"],
    icon: "png",
    download: "https://mtmt.tech/",
  },
];

const newArr = { type: "管理", path: "manage", data: data };

export default newArr;
