//聊天
const data = [
  {
    name: "钉钉",
    system: ["Win", "Mac", "Linux"],
    download:
      "https://page.dingtalk.com/wow/z/dingtalk/simple/ddhomedownload#/",
    icon: "svg",
  },
  {
    name: "微信",
    system: ["Win", "Mac"],
    download: "https://weixin.qq.com/",
    icon: "svg",
  },
  {
    name: "企业微信",
    system: ["Win", "Mac", "Web"],
    download: "https://work.weixin.qq.com/#indexDownload",
    icon: "svg",
  },
  {
    name: "QQ",
    system: ["Win", "Mac", "Linux"],
    download: "https://im.qq.com/index/#downloadAnchor",
    icon: "svg",
  },
  {
    name: "飞书",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://www.feishu.cn/download",
    icon: "svg",
  },
  {
    name: "Telegram",
    system: ["Win", "Mac", "Linux", "Web"],
    download: "https://desktop.telegram.org/",
    icon: "png",
    msg: "消息传递的新时代",
    text: "科学",
  },
  {
    name: "Tumblr",
    system: ["Web"],
    download: "https://www.tumblr.com/",
    icon: "svg",
    text: "科学",
  },
  {
    name: "Twitter",
    system: ["Web"],
    download: "https://twitter.com",
    icon: "svg",
    text: "科学",
  },
  {
    name: "FaceBook",
    system: ["Web"],
    download: "https://twitter.com",
    icon: "svg",
    text: "科学",
  },
  {
    name: "浙政钉",
    system: ["Win", "Linux"],
    download: "https://www.ding.zj.gov.cn/pc/index.html#/",
    icon: "png",
  },
];

const newArr = { type: "沟通", path: "chat", data: data };

export default newArr;
