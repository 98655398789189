//内容框架
const data = [
  {
    name: "GitBook",
    download: "https://www.gitbook.com/",
    icon: "svg",
    msg: "捕获和记录技术知识的平台",
  },
  {
    name: "VitePress",
    download: "https://vitepress.dev/zh/",
    msg: "Vite 和 Vue 驱动的静态站点生成器",
    icon: "png",
  },
  {
    name: "Docsify",
    download: "https://docsify.js.org/#/",
    icon: "svg",
    msg: "一个神奇的文档网站生成器",
  },
  {
    name: "WikiJs",
    download: "https://js.wiki/",
    icon: "png",
    msg: "强大可扩展的开源 Wiki 软件",
  },

  {
    name: "Navidrome",
    download: "https://www.navidrome.org/",
    icon: "svg",
    msg: "个人云音乐",
  },
  {
    name: "音流",
    download: "https://aqzscn.cn/archives/stream-music-versions",
    msg: "NAS 上的个人音乐播放APP",
    icon: "png",
  },

  {
    name: "网络记事本-1",
    download: "https://github.com/pereorga/minimalist-web-notepad",
    icon: "git",
  },
  {
    name: "网络记事本-2",
    download: "https://github.com/domorielton/minimal-web-notepad",
    icon: "git",
  },

  {
    name: "MdBook",
    download: "https://hellowac.github.io/mdbook-doc-zh/zh-cn/index.html",
    icon: "svg",
    msg: "使用 Markdown 创建书籍",
  },
  {
    name: "MkDocs",
    download: "https://www.mkdocs.org/",
    icon: "png",
    msg: "构建项目文档的生成器",
  },
  {
    name: "Starlight",
    download: "https://starlight.astro.build/zh-cn/",
    icon: "png",
    msg: "快速、易用、易于访问的文档",
  },

  {
    name: "pea3nut-info",
    download: "https://github.com/pea3nut/pea3nut-info",
    icon: "git",
    msg: "个人简历引导页",
  },
  {
    name: "Pintree",
    download: "https://pintree.io/",
    icon: "png",
    msg: "浏览器书签变成美丽的导航网站！",
  },
  {
    name: "FarBox",
    download: "https://www.farbox.com/",
    icon: "ico",
    msg: "人类负责思考，我们负责渲染",
  },
  {
    name: "咖啡壶",
    download: "https://github.com/celaraze/chemex",
    icon: "git",
    msg: "轻量的、现代设计风格的 ICT 资产管理系统",
  },

];

const newArr = { type: "CMS", path: "cms", data: data, describe: "内容框架" };

export default newArr;
