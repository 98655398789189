/**
 * 汇总数据
 */
import SiteFrontend from "@/data/page/site/frontend/index";
import SiteLife from "@/data/page/site/life/index";
import SitePicture from "@/data/page/site/picture/index";
import Git from "@/data/page/site/git/index";
import Amuse from "@/data/page/site/amuse/index";

//搜索用
export const SiteDataTitleArray = ["生活", "前端", "图片", "Git", "多媒体"];
export const SiteRouter = [
  {
    title: "生活",
    name: "page",
    path: "life",
    data: SiteLife,
  },
  {
    title: "前端",
    name: "page",
    path: "frontend",
    data: SiteFrontend,
  },
  {
    title: "图片",
    name: "page",
    path: "picture",
    data: SitePicture,
  },
  {
    title: "Git",
    name: "page",
    path: "git",
    data: Git,
  },
  {
    title: "多媒体",
    name: "page",
    path: "amuse",
    data: Amuse,
  },
];

//站点统计用
export const SiteDatas = [
  ...SiteFrontend,
  ...SiteLife,
  ...SitePicture,
  ...Git,
  ...Amuse,
];
