/**
 * 汇总数据
 */
import {
  SiteDataTitleArray,
  SiteRouter,
  SiteDatas,
} from "@/data/page/site/summary";
import { SeeTitleArray, SeeRouter, SeeData } from "@/data/page/see/summary";

//搜索用
export const PageDataTitleArray = [...SiteDataTitleArray, ...SeeTitleArray];
export const PageData = [...SiteRouter, ...SeeRouter];

//站点统计用
export const PageSiteData = [...SiteDatas, ...SeeData];
