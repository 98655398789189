//其他工具
const data = [
  {
    name: "Syncthing",
    system: ["Win", "Mac", "Linux"],
    download: "https://syncthing.net/downloads/",
    icon: "png",
    statu: "gnu",
    msg: "超流行同步工具",
  },
  {
    name: "Watt Toolkit",
    download: "https://steampp.net/download",
    system: ["Win", "Mac", "Linux"],
    mark: "",
    icon: "png",
    text: "",
    state: "free",
    msg: "包含多种 Steam 工具",
  },
  {
    name: "HelloWindows",
    download: "https://www.hellowindows.cn/",
    system: ["Web"],
    mark: "",
    icon: "win",
    state: "free",
    msg: "win 操作系统和 office 办公",
  },
  {
    name: "我告诉你",
    download: "https://msdn.itellyou.cn/",
    system: ["Web"],
    icon: "win",
    state: "free",
    msg: "知名系统下载站",
  },
  {
    name: "Android File Transfer",
    download: "https://www.android.com/filetransfer/",
    system: ["Mac"],
    icon: "about",
    state: "free",
    msg: "Mac电脑和Android设备间浏览和传输文件。",
  },
  {
    name: "麦客",
    system: ["Web"],
    download: "https://www.mikecrm.com/",
    icon: "png",
    msg: "信息收集与市场营销",
  },

  {
    name: "测速网",
    system: ["Web"],
    download: "https://www.speedtest.cn/",
    icon: "png",
  },
  {
    name: "ONFIX",
    system: ["Web"],
    download: "https://onfix.cn/rom",
    icon: "png",
    msg: "各大手机系统的刷机包",
  },
  {
    name: "天地图",
    system: ["Web"],
    download: "https://www.tianditu.gov.cn/",
    icon: "ico",
    msg: "国家地理信息公共服务平台",
  },
  {
    name: "EasyBCD",
    system: ["Win"],
    download: "https://neosmart.net/EasyBCD/",
    icon: "png",
    msg: "开机引导菜单编辑",
    describe: "https://www.52pojie.cn/thread-1476773-1-1.html",
  },
  {
    name: "360 宽带测速器",
    system: ["Win"],
    download: "https://www.52pojie.cn/thread-1283651-1-1.html",
    icon: "about",
    msg: "测网速工具",
  },
  {
    name: "Rectangle",
    msg: "使用键盘快捷键或对齐区域移动窗口并调整其大小",
    system: ["Mac"],
    icon: "png",
    download: "https://rectangleapp.com/",
  },
  {
    name: "TaskCanvas",
    msg: "跟踪您的一天和您使用的程序",
    system: ["Win"],
    icon: "png",
    download: "https://www.digitalvolcano.co.uk/textcrawler.html",
  },
];

const newArr = { type: "其他", path: "about", data: data };

export default newArr;
