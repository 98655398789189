//工具箱
const data = [
  {
    name: "uTools",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.u-tools.cn/",
    icon: "svg",
    msg: "自由组合插件应用，打造专属你的趁手工具集",
  },
  {
    name: "彩虹工具箱",
    system: ["Win", "Mac"],
    download: "https://rainbowbyte.com/app/rainbowtoolbox.html",
    icon: "png",
  },
  {
    name: "Clipy",
    system: ["Mac"],
    download: "https://clipy-app.com/",
    icon: "png",
    state: "gnu",
    msg: "剪切板工具",
  },
  {
    name: "KeyClu",
    system: ["Mac"],
    download: "https://sergii.tatarenkov.name/keyclu/support/",
    icon: "png",
    state: "gnu",
    msg: "快捷键提示",
  },
  {
    name: "KeyCue",
    system: ["Mac"],
    download: "https://www.ergonis.com/keycue/switching/cheatsheet",
    icon: "svg",
    msg: "快捷键提示",
  },
  {
    name: "Dash",
    system: ["Mac"],
    download: "https://kapeli.com/dash",
    icon: "png",
    msg: " API 浏览器",
  },
  {
    name: "Gifox",
    system: ["Mac"],
    download: "https://gifox.app/",
    icon: "svg",
    msg: " Gif 录制",
  },
  {
    name: "PasteNow",
    system: ["Mac"],
    download: "https://pastenow.app/",
    icon: "svg",
    msg: "简化剪贴板管理",
  },
  {
    name: "Freemacsoft",
    system: ["Mac"],
    download: "https://freemacsoft.net/appcleaner/",
    icon: "png",
    state: "free",
    msg: "彻底卸载软件",
  },
  {
    name: "FlyKey",
    system: ["Mac"],
    download: "https://www.better365.cn/FlyKey.html",
    icon: "png",
    msg: "展示快捷键",
  },
  {
    name: "iCopy",
    system: ["Mac"],
    download: "https://www.better365.cn/icopy.html",
    icon: "png",
    state: "free",
    msg: "剪切板、快捷回复强强组合",
  },
  {
    name: "iBar",
    system: ["Mac"],
    download: "https://www.better365.cn/ibar.html",
    icon: "png",
    state: "free",
    msg: "菜单栏图标管理",
  },
  {
    name: "Speedtest",
    system: ["Mac"],
    download: "https://zh.okaapps.com/product/1455463454",
    icon: "webp",
    msg: "WiFi 测网速",
  },
  {
    name: "BEJSON",
    system: ["Web"],
    download: "https://www.bejson.com/",
    icon: "png",
    mark: "#222",
    msg: "多功能在线 工具箱",
  },
  {
    name: "菜鸟工具",
    download: "https://c.runoob.com/",
    system: ["Web"],
    icon: "ico",
    state: "free",
    msg: "在线工具集合",
  },
  {
    name: "在线工具",
    download: "https://tool.lu/",
    system: ["Web"],
    icon: "svg",
    state: "",
    msg: "在线工具集合",
  },
  {
    name: "He3",
    download: "https://he3app.com/zh/",
    system: ["Win", "Mac", "Linux"],
    icon: "png",
    msg: "开发者工具箱",
  },
  {
    name: "MikuTools",
    download: "https://miku.tools/",
    system: ["Web"],
    icon: "webp",
    msg: "蛮多实用小工具",
  },
  {
    name: "Streer",
    download: "https://miku.tools/",
    system: ["Web"],
    icon: "svg",
    msg: "B 站封面下载等工具",
  },
  {
    name: "搜收录网",
    download: "http://www.soshoulu.com/",
    system: ["Web"],
    icon: "gif",
    msg: "各种数据处理工具",
  },
  {
    name: "PortableApps",
    system: ["Win"],
    msg: "免安装软件工具合集",
    icon: "png",
    download: "https://portableapps.com/zh-cn",
  },
];

const newArr = { type: "工具箱", path: "box", data: data };

export default newArr;
