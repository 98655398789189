//游戏
const data = [
  {
    name: "电子木鱼",
    download: "http://fish.leixf.cn/",

    icon: "png",
    state: "free",
    msg: "敲电子木鱼，积赛博功德",
  },
  {
    name: "人类基准",
    download: "https://humanbenchmark.com/",

    icon: "png",

    state: "free",
    msg: "用脑力游戏和认知测试来衡量你的能力",
  },
  {
    name: "Gallerix",
    download: "https://gallerix.asia/pazly/",

    icon: "png",

    state: "free",
    msg: "17万张艺术拼图",
  },
  {
    name: "ZTYPE",
    download: "https://zty.pe/",

    icon: "png",

    state: "free",
    msg: "射击类打字游戏",
  },
  {
    name: "FalseKnees",
    download: "https://falseknees.com",

    icon: "webp",
    state: "free",
    msg: "哲理四格漫画",
  },
  {
    name: "Qwerty Learner",
    download: "https://qwerty.kaiyi.cool/",

    icon: "svg",
    state: "gnu",
    msg: "打字闯关游戏",
  },
  {
    name: "BitSweeper",
    download: "https://lokeshdhakar.com/projects/bitsweeper/",

    icon: "png",
    state: "free",
    msg: "像素风扫雷游戏",
  },
  {
    name: "Silk",
    download: "http://weavesilk.com/",

    icon: "ico",
    state: "free",
    msg: "炫酷对称艺术纹理特效绘制 ",
  },
  {
    name: "Bezier Game",
    download: "http://bezier.method.ac/",

    icon: "about",
    msg: "贝塞尔曲线练习",
  },
  {
    name: "Autodraw",
    download: "https://autodraw.com/",

    icon: "png",
    msg: "谷歌绘画智能匹配相应图形",
    text: "科学",
  },
  {
    name: "自由钢琴",
    download: "https://www.autopiano.cn/",

    icon: "png",
    msg: "在线弹钢琴",
  },
  {
    name: "中午吃什么",
    download: "https://www.zwcsm.com/",

    icon: "png",
    msg: "解决人类的世纪难题！吃什么不用愁！",
  },
  {
    name: "小霸王",
    download: "https://www.yikm.net/",

    icon: "png",
    msg: "小霸王游戏机，童年的回忆",
  },


];

const newArr = { type: "游戏", path: "game", data: data };

export default newArr;
