//卓越 - 超过 20K star 的项目
const data = [
  {
    name: "Mastodon",
    download: "https://joinmastodon.org/",
    icon: "svg",
    state: "gnu",
    msg: "去中心化社交平台",
  },
  {
    name: "Uptime Kuma",
    download: "https://github.com/louislam/uptime-kuma",
    icon: "svg",
    state: "gnu",
    msg: "易于使用的自托管监控工具",
  },
  {
    name: "Follow",
    download: "https://follow.is/",
    icon: "svg",
    state: "gnu",
    msg: "下一代信息浏览器",
  },
];

const newArr = {
  type: "卓越",
  path: "brilliant",
  data: data,
  describe: "超过 20K star 的项目",
};

export default newArr;
