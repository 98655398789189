//图标
const data = [
  {
    name: "iconfont",
    download: "https://www.iconfont.cn/",
    
    icon: "svg",
    mark: "",
    msg: "阿里团队图标字体及图标素材下载平台",
  },
  {
    name: "Font Awesome",
    download: "https://fontawesome.com/",
    
    icon: "svg",
    msg: "知名图标库和工具包",
  },
  {
    name: "Reshot",
    download: "https://www.reshot.com/",
    
    icon: "svg",
    state: "free",
    msg: "无版权且免费插画和图标",
  },
  {
    name: "草莓图标",
    download: "http://chuangzaoshi.com/icon/",
    
    icon: "svg",
    msg: "为开发者设计的一套免费开源图标库",
  },
  {
    name: "IcoMoon",
    download: "https://icomoon.io/",
    
    icon: "png",
    msg: "矢量图打包成图标字体的技术平台",
  },
  {
    name: "IconPark",
    download: "https://iconpark.oceanengine.com",
    
    icon: "svg",
    msg: "2600+基础图标，29种图标分类，抖音旗下",
  },
  {
    name: "Heroicons",
    download: "https://heroicons.com/",
    
    icon: "svg",
    msg: "Tailwind CSS手工制作的精美SVG图标。",
  },
  {
    name: "Preloaders",
    download: "http://preloaders.net/",
    
    icon: "png",
    msg: "loading加载动画制作",
  },
  {
    name: "Loading",
    download: "https://loading.io/",
    
    icon: "png",
    msg: "制作GIF丨SVG丨CSS加载动画图标",
  },

  {
    name: "Noun Project",
    download: "https://thenounproject.com/",
    
    icon: "png",
    msg: "高质量图标下载",
  },
  {
    name: "ICONFINDER",
    download: "https://www.iconfinder.com/",
    
    icon: "svg",

    msg: "高质量付费图标下载",
  },
  {
    name: "Themify",
    download: "http://themify.me/themify-icons",
    
    icon: "svg",
    state: "free",
    msg: "一套免费特色iconfont图标支持WP插件",
  },
  {
    name: "iconmonstr",
    download: "http://iconmonstr.com/",
    
    icon: "svg",
    state: "free",
    msg: "精美免费简洁icon",
  },
  {
    name: "Logodust",
    download: "http://logodust.com/",
    
    icon: "svg",
    state: "gnu",
    msg: "特赞！提供开源免费的LOGO",
  },
  {
    name: "Iconjar",
    download: "http://geticonjar.com/",
    system: ["Mac"],
    icon: "svg",
    msg: "图标素材管理工具",
  },
  {
    name: "Flaticon",
    download: "http://www.flaticon.com/",
    
    icon: "svg",
    msg: "海量扁平化免费的图标库",
  },
  {
    name: "Iconstore",
    download: "http://iconstore.co/",
    
    icon: "svg",
    state: "free",
    msg: "免费商用的高质量图标素材站",
  },
  {
    name: "logoEPS",
    download: "http://www.logoeps.com/",
    
    icon: "png",
    state: "free",
    msg: "各大国际品牌logo矢量图",
  },

  {
    name: "Icon Ninja",
    download: "http://www.iconninja.com/",
    
    icon: "ico",
    state: "free",
    msg: "海量图标搜索可生成css sprites",
  },
  {
    name: "ICONS8",
    download: "https://igoutu.cn/",
    
    icon: "svg",
    msg: "图标、照片、矢量插图和视频音乐素材",
  },
  {
    name: "IOS Icon Gallery",
    download: "http://iosicongallery.com/",
    icon: "svg",
    state: "free",
    msg: "IOS平台APP图标收录和欣赏",
  },
  {
    name: "iconsfeed",
    download: "http://www.iconsfeed.com/",
    
    icon: "png",
    state: "free",
    msg: "iOS系统应用图标收集和分享",
  },
];

const newArr = { type: "图标", path: "icon", data: data };

export default newArr;
