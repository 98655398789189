//常用
import Input from "@/data/software/wasteland/input";
import Browser from "@/data/software/wasteland/browser";
import Chat from "@/data/software/wasteland/chat";
import Secure from "@/data/software/wasteland/secure";

import Remote from "@/data/software/wasteland/remote";
import Search from "@/data/software/wasteland/search";

import About from "@/data/software/wasteland/about";
const Wasteland = [
  Chat, // 聊天
  Browser, // 浏览器
  Search, // 搜索
  Input, // 输入法

  Secure, // 安全
  Remote, // 远程

  About, //其他
];
export default Wasteland;
