//清理文件
const data = [
  {
    name: "百度贴吧",

    download: "https://tieba.baidu.com/",
    msg: "全球领先的中文社区",
    icon: "svg",
  },
  {
    name: "虎扑",

    download: "https://bbs.hupu.com/",
    msg: "虎扑以体育赛事和男性兴趣生活为主",
    icon: "webp",
    mark: "#c60100",
  },
  {
    name: "Chiphell",

    download: "https://www.chiphell.com/",
    msg: "分享与交流用户体验",
    icon: "png",
  },
  {
    name: "NGA玩家社区",

    download: "https://ngabbs.com/",
    msg: "玩家交流平台",
    icon: "svg",
  },
  {
    name: "知乎",

    download: "https://www.zhihu.com/",
    msg: "问答平台",
    icon: "svg",
  },
  {
    name: "少数派",

    download: "https://sspai.com/",
    msg: "高品质数字消费指南",
    icon: "svg",
  },
  {
    name: "V2EX",

    download: "https://www.v2ex.com/",
    msg: "创意工作者的社区",
    icon: "png",
    text: "科学",
  },
  {
    name: "重庆购物狂",

    download: "https://go.cqmmgo.com/",
    msg: "重庆最具人气的生活消费社区",
    icon: "png",
  },
  {
    name: "钛媒体",

    download: "https://www.tmtpost.com/",
    msg: "财经科技信息服务平台",
    icon: "svg",
  },
  {
    name: "月光博客",

    download: "https://www.williamlong.info/",
    msg: "原创IT科技博客",
    icon: "png",
  },
  {
    name: "酷壳",

    download: "https://coolshell.cn/",
    msg: "享受编程和技术所带来的快乐",
    icon: "png",
  },
  {
    name: "TopBook",

    download: "https://topbook.cc/",
    msg: "高效生活视频书",
    icon: "svg",
  },
  {
    name: "稀土掘金",

    download: "https://juejin.cn/",
    msg: "中文开发者的技术内容分享与交流平台",
    icon: "svg",
  },
  {
    name: "MacRumors",
    download: "https://www.macrumors.com/",
    msg: "苹果新闻和谣言",
    icon: "png",
  },
  {
    name: "小红书",
    download: "https://www.xiaohongshu.com",
    msg: "你的生活指南",
    icon: "svg",
  },
  {
    name: "AnandTech",
    download: "https://www.anandtech.com/",
    msg: "1997年以来的硬件新闻和技术评论",
    icon: "png",
  },
];

const newArr = { type: "论坛", path: "forum", data: data };

export default newArr;
