//文档
const data = [
  {
    name: "Docker",
    download: "https://yeasy.gitbook.io/docker_practice/",
    icon: "svg",
    msg: "Docker 文档介绍",
  },
  {
    name: "Spec",
    download: "https://github.com/ecomfe/spec/blob/master/README.md",
    system: ["Web"],
    icon: "git",
    msg: "前端编码规范",
  },
  {
    name: "CSS 资源大全中文版",
    download: "https://github.com/jobbole/awesome-css-cn",
    system: ["Web"],
    icon: "git",
    msg: "关于 CSS 详细的一切",
  },
  {
    name: "CSS 常用样式",
    download: "https://github.com/QiShaoXuan/css_tricks",
    system: ["Web"],
    icon: "git",
    msg: "蛮多通用样式，方便复用",
  },
];

const newArr = {
  type: "DOC",
  path: "doc",
  data: data,
  describe: "专业文档",
};

export default newArr;
