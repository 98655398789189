<script setup lang="ts">
//底部功能区
import { inject, Ref, onMounted, onBeforeUnmount } from "vue";
import {
  switchBtn,
  isDark,
  handleScroll,
  showTopType,
  handleButtonClick,
  ColorSetting,
  IconData,
} from "@/store/tool";
import {} from "@/store/tool";
import SvgIcon from "@/components/icon/SvgIcon.vue";

//拿到当前路由名字
const RouterName = inject("RouterName") as Ref<string>;

//监听
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<template>
  <div class="flex justify-center items-center">
    <!--颜色切换-->
    <span class="cursor-pointer" @click="switchBtn">
      <SvgIcon
        class="w-10 h-10 p-2"
        :name="isDark ? IconData.sun : IconData.moon"
        :color="isDark ? ColorSetting.white : ColorSetting.default"
        title="颜色切换"
        alt="颜色切换"
      />
    </span>

    <router-link to="/record" title="历史记录">
      <SvgIcon
        class="w-10 h-10 p-2"
        :name="IconData.record"
        :color="
          RouterName === 'record' ? ColorSetting.active : ColorSetting.default
        "
        title="历史记录"
        alt="历史记录"
      />
    </router-link>
    <span class="cursor-pointer" @click="handleButtonClick">
      <SvgIcon
        class="w-10 h-10 p-2 hover:opacity-50"
        :class="showTopType ? 'animate-bounce' : ''"
        :name="IconData.top"
        :color="ColorSetting.default"
        title="返回顶部"
        alt="返回顶部"
      />
    </span>
  </div>
</template>
