//站点导航
import Frontend from "@/page/site/frontend.vue"; //前端
import Life from "@/page/site/life.vue"; //生活
import Picture from "@/page/site/picture.vue"; //图片
import Git from "@/page/site/git.vue";
import Amuse from "@/page/site/amuse.vue";
const pageData = [
  { title: "生活", path: "life", describe: "生活服务相关", component: Life },
  {
    title: "前端",
    path: "frontend",
    describe: "前端开发相关",
    component: Frontend,
  },
  {
    title: "图片",
    path: "picture",
    describe: "图片服务相关",
    component: Picture,
  },
  { title: "Git", path: "git", describe: "开源服务相关", component: Git },
  { title: "多媒体", path: "amuse", describe: "多媒体相关", component: Amuse },
];

export default pageData;
