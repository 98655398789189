//多媒体

import Music from "@/data/software/amuse/music";
import Video from "@/data/software/amuse/video";
import Game from "@/data/software/amuse/game";

import About from "@/data/software/amuse/about";

const Data = [
  Music, //音乐
  Video, //视频
  Game, //游戏

  About, //其他
];
export default Data;
