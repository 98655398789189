import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
//SVG图标
import "virtual:svg-icons-register";
//导入路由
import router from "@/router/index.ts";
import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });
const app = createApp(App);
//路由
app.use(router);

app.mount("#app");
