//游戏
const data = [
  {
    name: "WeGame",
    download: "https://www.wegame.com.cn/home/",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "国内游戏平台（英雄联盟）",
  },
  {
    name: "Steam",
    download: "https://steamcommunity.com",
    system: ["Win", "Mac", "Linux"],
    icon: "svg",
    msg: "知名正版游戏折扣平台",
  },
  {
    name: "暴雪战网",
    download: "https://shop.battlenet.com.cn/zh-cn",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "知名游戏平台（魔兽世界）",
  },
  {
    name: "Ubisoft",
    download: "https://zh-cn.ubisoft.com/",
    system: ["Win"],
    icon: "svg",
    msg: "育碧游戏平台（刺客信条系列）",
  },
  {
    name: "Origin",
    download: "https://www.ea.com/zh-tw/ea-app#downloads",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "橘子（EA）游戏平台（战地系列）",
  },

  {
    name: "Epic",
    download: "https://store.epicgames.com/zh-CN/",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "Epic游戏平台（堡垒之夜）",
  },

  {
    name: "GOG",
    download: "https://www.gog.com/galaxy",
    system: ["Win", "Mac"],
    icon: "svg",
    msg: "波兰蠢驴（赛博朋克2077）",
  },
  {
    name: "RockstarGames",
    download: "https://socialclub.rockstargames.com/rockstar-games-launcher",
    system: ["Win"],
    icon: "png",
    msg: "R星（GTA5）",
  },
  {
    name: "CDPR",
    download: "https://www.cdprojektred.com/en",
    system: ["Web"],
    icon: "svg",
    msg: "《巫师》系列游戏开发商",
  },
  {
    name: "RiotGame",
    download: "https://www.riotgames.com/zh-cn",
    system: ["Web"],
    icon: "svg",
    msg: "《英雄联盟》游戏开发商",
  },
  {
    name: "游戏科学",
    download: "https://www.gamesci.com.cn",
    system: ["Web"],
    icon: "png",
    msg: "《黑神话：悟空》游戏开发商",
  },
  {
    name: "该买啦游戏",
    msg: "发现隐藏的宝藏和流行的独立游戏",
    download: "https://gameragames.com/zh-hans/",
    system: ["Web"],
    icon: "png",
  },
  {
    name: "TapTap",
    download: "https://www.taptap.cn/",
    icon: "svg",
    msg: "发现好游戏",
  },
  {
    name: "IGN中国",
    msg: "游戏资讯",
    download: "https://www.ign.com.cn/",
    system: ["Web"],
    icon: "svg",
  },
  {
    name: "SteamDB",
    download: "https://steamdb.info/calculator/",

    icon: "svg",
    msg: "Steam数据，账号价值计数器",
  },
  {
    name: "SteamID",
    download: "https://steamid.io/",
    icon: "svg",
    msg: "Steam 的 ID 查询",
  },
];

const newArr = { type: "游戏", path: "game", data: data };

export default newArr;
