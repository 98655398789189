//多媒体
import { SonType } from "@/store/interface";

import Character from "@/data/page/site/amuse/character";
import Book from "@/data/page/site/amuse/book";
import Forum from "@/data/page/site/amuse/forum";
import Game from "@/data/page/site/amuse/game";
import Blog from "@/data/page/site/amuse/blog";
import NewMedia from "@/data/page/site/amuse/newMedia";
const Data = [
  Book, //书籍
  Forum, //论坛
  Blog, //博客
  Game, //论坛
  Character, //人物
  NewMedia, //新媒体
];
export default Data as SonType[];
