//输入法
const data = [
  {
    name: "搜狗输入法",
    system: ["Win", "Mac", "Linux"],
    download: "https://shurufa.sogou.com/",
    icon: "svg",
  },
  {
    name: "百度输入法",
    system: ["Win", "Mac"],
    download: "https://shurufa.baidu.com/default",
    icon: "png",
  },
  {
    name: "讯飞输入法",
    system: ["Win", "Mac", "Linux"],
    download: "https://srf.xunfei.cn/#/",
    icon: "png",
  },
  {
    name: "微信输入法",
    system: ["Win", "Mac"],
    download: "https://z.weixin.qq.com/",
    icon: "png",
  },
  {
    name: "手心输入法",
    system: ["Win", "Mac"],
    download: "https://www.xinshuru.com/index.html",
    icon: "png",
    msg: "无广告，无更新",
  },
  {
    name: "QQ输入法",
    system: ["Win"],
    download: "https://qq.pinyin.cn/wubi/",
    icon: "png",
    msg: "长时间未更新",
  },
];

const newArr = { type: "输入法", path: "input", data: data };

export default newArr;
