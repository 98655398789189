//工具箱
const data = [
  {
    name: "Oschina",
    download: "https://tool.oschina.net/",
    icon: "svg",
    msg: "在线的编程工具软件",
  },
  {
    name: "零代码工具箱",
    download: "https://www.lingdaima.com/",
    icon: "about",
    msg: "专为前端打造",
    state: "free",
  },
  {
    name: "宝塔",
    download: "https://www.bt.cn/tools/index.html",
    icon: "svg",
    state: "free",
    msg: "在线转换工具聚合",
  },
  {
    name: "JSON在线",
    download: "https://www.sojson.com/",
    icon: "webp",
    state: "free",
    msg: "丰富的前端工具",
  },
  {
    name: "在线工具大全",
    download: "https://www.lddgo.net/",
    icon: "png",
    msg: "蛮多开发用小工具",
  },
  {
    name: "聚合工具",
    download: "https://tool.juhe.cn/",
    icon: "svg",
    mark: "#2c2c2c",
    state: "free",
    msg: "前端常用转换工具",
  },
  {
    name: "油条工具箱",
    download: "https://utils.fun/",
    icon: "png",
  },
  {
    name: "全栈工具",
    download: "https://tools.qzxdp.cn/",
    icon: "png",
    state: "free",
    msg: "开发工具箱",
  },
  {
    name: "LZL在线工具",
    download: "https://lzltool.cn/",
    icon: "ico",
    state: "free",
    msg: "为开发设计人员提供在线工具",
  },
  {
    name: "锤子在线工具网",
    download: "https://www.toolhelper.cn/",
    icon: "png",
    state: "free",
    msg: "在线工具集合",
  },
  {
    name: "微工具",
    download: "https://www.wetools.com/",
    icon: "ico",
    state: "free",
    msg: "方便、易用、便捷的在线工具",
  },
  {
    name: "DataConverter",
    download: "https://converter.work/",
    icon: "png",
    state: "free",
    msg: "JSON格式转换工具",
  },
  {
    name: "Lddgo",
    download: "https://www.lddgo.net/index",
    icon: "png",
    state: "free",
    msg: "在线工具大全",
  },
  {
    name: "蛙蛙工具",
    download: "https://www.iamwawa.cn/",
    icon: "png",
    state: "free",
    msg: "便捷的在线工具站",
  },
];

const newArr = { type: "工具箱", path: "toolbox", data: data };

export default newArr;
