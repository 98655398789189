<script setup lang="ts">
//展示图标
import SvgIcon from "@/components/icon/SvgIcon.vue";
type DataIcon = {
  mark?: string;
  icon: string;
  name: string;
  username?: string; //博主图标
};
const props = defineProps<{ data: DataIcon }>();

const getIconStyle = () => {
  if (props.data.mark) {
    return {
      backgroundColor: props.data.mark, //背景颜色
      padding: ".4rem", //边距
      maxWidth: "120px",
    };
  }
};
//特例
const specialData = ["git", "win", "about", "blog", "greasy"];
</script>
<template>
  <!--判断有没有img，有的话，则是用户，使用用户图标库-->
  <template v-if="data.username">
    <img
      class="rounded-lg mx-auto my-0"
      :src="`/user_logo/${data.username}.${data.icon}`"
      :alt="data.name"
      :title="data.name"
      :style="getIconStyle()"
    />
  </template>
  <template v-else-if="data.icon === 'svg'">
    <SvgIcon
      class="rounded-lg mx-auto my-0"
      :name="data.name"
      :color="data.mark"
      :style="getIconStyle()"
    />
  </template>
  <template v-else-if="specialData.includes(data.icon)">
    <SvgIcon
      class="rounded-lg mx-auto my-0"
      :name="data.icon"
      :color="data.mark"
      :style="getIconStyle()"
    />
  </template>
  <template v-else>
    <img
      class="rounded-lg mx-auto my-0"
      :src="`/brand_logo/${data.name}.${data.icon}`"
      :alt="data.name"
      :style="getIconStyle()"
    />
  </template>
</template>
