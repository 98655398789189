//图片
import Convert from "@/data/page/site/picture/convert";
import Source from "@/data/page/site/picture/source";
import QR from "@/data/page/site/picture/qr";
import Occupy from "@/data/page/site/picture/occupy";
import Shoot from "@/data/page/site/picture/shoot";
import Compress from "@/data/page/site/picture/compress";
import { SonType } from "@/store/interface";
import Edit from "@/data/page/site/picture/edit";
const Data = [
  Convert, //转换
  QR, //二维码
  Occupy, //占位图
  Source, //素材
  Shoot, //摄影
  Compress,//压缩
  Edit, //其他
];
export default Data as SonType[];
