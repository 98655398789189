//工具

import Video from "@/data/software/tool/video";
import Hardware from "@/data/software/tool/hardware";
import Develop from "@/data/software/tool/develop";
import Box from "@/data/software/tool/box";
import About from "@/data/software/tool/about";
import Ai from "@/data/software/tool/ai";
import Drive from "@/data/software/tool/drive";
const Tool = [
  Video, //视频工具
  Hardware, //硬件信息
  Develop, //开发信息
  Box, //工具箱
  Ai, //Ai 相关工具
  Drive, //驱动 相关工具
  About, //其他
];
export default Tool;
