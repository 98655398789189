//压缩
const data = [
  {
    name: "Imagine",
    system: ["Win", "Mac", "Linux"],
    download: "https://github.com/meowtec/Imagine/releases",
    icon: "png",
    state: "gnu",
    msg: "压缩图片",
  },
  
  
  

 
];

const newArr = { type: "压缩", path: "compress", data: data };

export default newArr;
