/**
 * 汇总数据
 */
import War from "@/data/page/see/war/index"; //军事
import Beauty from "@/data/page/see/beauty/index"; //靓女
//搜索用
export const SeeTitleArray = ["军事", "靓女"];
export const SeeRouter = [
  {
    title: "军事",
    name: "see",
    path: "war",
    data: War,
  },
  {
    title: "靓女",
    name: "see",
    path: "beauty",
    data: Beauty,
  },
];

//站点统计用
export const SeeData = [...War, ...Beauty];
