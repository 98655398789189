//解压缩
const data = [
  {
    name: "WinRAR",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.win-rar.com/download.html?&L=7",
    icon: "png",
    msg: "解压缩",
  },
  {
    name: "MacZip",
    system: ["Mac"],
    download: "https://maczip.cn/?locale=zh-CN",
    icon: "png",
    msg: "专为 macOS 而设计",
  },
  {
    name: "7-Zip",
    system: ["Win"],
    download: "https://7-zip.org/download.html",
    icon: "png",
    state: "gnu",
    msg: "开源解压软件",
  },
  {
    name: "Bandizip",
    system: ["Win", "Mac"],
    download: "https://www.bandisoft.com/bandizip/",
    icon: "png",
    msg: "带广告的解压软件",
  },
  {
    name: "8 Zip",
    system: ["Win"],
    download: "https://apps.microsoft.com/detail/9WZDNCRFJB33?hl=zh-cn&gl=CN",
    icon: "png",
    msg: "好看的解压软件",
  },
  {
    name: "360 Zip",
    system: ["Win"],
    download: "https://www.360totalsecurity.com/zh-cn/360zip/",
    icon: "png",
    msg: "360压缩国际版",
  },
  {
    name: "全能解压",
    system: ["Win", "Mac"],
    download: "https://cleanerone.trendmicro.com/zh-cn/unzip-one/",
    icon: "png",
    msg: "",
  },
  {
    name: "Peazip",
    system: ["Win", "Mac", "Linux"],
    download: "https://peazip.github.io/index.html",
    icon: "png",
    state: "gnu",
    msg: "免费开源的英文解压缩软件",
  },
  {
    name: "FastZip",
    system: ["Mac"],
    download: "https://www.better365.cn/fastzip.html",
    icon: "png",
    msg: "压缩解压工具",
  },
];

const newArr = { type: "解压", path: "unzip", data: data };

export default newArr;
