// 素材
const data = [
  {
    name: "LifeOfPix",
    
    download: "https://www.lifeofpix.com/",
    icon: "svg",
    state: "free",
    msg: "免费高分辨率摄影",
  },
  {
    name: "Pinterest",
    download: "https://www.pinterest.com/",
    
    icon: "svg",
    msg: "全球美图收藏采集站",
  },

  {
    name: "FreeImages",
    
    download: "https://www.freeimages.com/cn",
    icon: "png",
    state: "free",
    msg: "免费无版权图片、模板、模型和图形资源",
  },
  {
    name: "Pixiv",
    
    download: "https://www.pixiv.net/",
    icon: "svg",
    msg: "知名动漫图片分享站点",
  },
  {
    name: "Vilipix",
    
    download: "https://vilipix.com/",
    icon: "png",
    state: "free",
    msg: "Pixiv国内代理01",
  },
  {
    name: "Pixivic",
    
    download: "https://pixivic.com/",
    icon: "png",
    state: "free",
    msg: "Pixiv国内代理02",
  },
  {
    name: "Pictures",
    
    download: "https://anime-pictures.net/",
    icon: "ico",
    state: "free",
    msg: "现代亚洲动漫和壁纸艺术收藏",
  },
  {
    name: "Flickr",
    
    download: "https://www.flickr.com/",
    icon: "svg",
    msg: "数百亿张图片",
  },
  {
    name: "iLOGO",
    
    download: "https://ilogo.fun/",
    mark: "#585858",
    icon: "svg",
    msg: "各大品牌的LOGO",
    state: "free",
  },

  {
    name: "wallhaven",
    
    download: "https://wallhaven.cc/",
    mark: "#ffffff",
    icon: "ico",
    msg: "网上最好的壁纸",
    text: "科学",
  },

  {
    name: "触站",
    
    download: "https://www.huashi6.com/",
    icon: "svg",
    msg: "画师分享平台",
  },
];

const newArr = { type: "素材", path: "source", data: data };

export default newArr;
